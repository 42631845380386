<template>
  <v-btn
    :href="answerFormUrl"
    :color="color"
    x-large
    block
    target="_blank"
    @click.stop
  >
    {{ $t('button.to_google_form') }}
  </v-btn>
</template>
<script>
export default {
  props: {
    answerFormUrl: { type: String, default: '' },
    color: { type: String, default: null },
  },
}
</script>
