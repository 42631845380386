<template>
  <v-row>
    <v-col cols="12" md="12">
      <validation-provider
        v-slot="{ errors }"
        :name="$$columnName('venue.title')"
        rules="required|max:100"
      >
        <v-text-field
          v-model="internalValue.title"
          :label="$$columnName('venue.title')"
          :error-messages="errors"
          outlined
        />
      </validation-provider>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    value: { type: Object, required: true },
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
