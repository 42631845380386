var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.programs,"disable-sort":"","hide-default-footer":"","items-per-page":-1},on:{"click:row":function($event){return _vm.$emit('click:row', $event)}},scopedSlots:_vm._u([{key:"item.st_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("momentFormat")(item.st_at,'lll'))+" ")]}},{key:"item.ed_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("momentFormat")(item.ed_at,'lll'))+" ")]}},{key:"item.archive_st_at",fn:function(ref){
var item = ref.item;
return [(item.archive_st_at)?_c('span',[_vm._v(" "+_vm._s(_vm._f("momentFormat")(item.archive_st_at,'lll'))),_c('br'),_vm._v(" ~ "+_vm._s(_vm._f("momentFormat")(item.archive_ed_at,'lll'))+" ")]):_c('v-btn',{attrs:{"color":"error","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.clickButton(item.id)}}},[_vm._v(" "+_vm._s(_vm.$t('button.not_set'))+" ")])]}},{key:"item.zoom_vimeo",fn:function(ref){
var item = ref.item;
return [(item.zoom_url)?_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.clickButton(item.id)}}},[_vm._v(" "+_vm._s(_vm.$t('button.zoom_set'))+" ")]):(item.vimeo_html_tag)?_c('v-btn',{attrs:{"color":"light-blue lighten-3","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.clickButton(item.id)}}},[_vm._v(" "+_vm._s(_vm.$t('button.vimeo_set'))+" ")]):_c('v-btn',{attrs:{"color":"error","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.clickButton(item.id)}}},[_vm._v(" "+_vm._s(_vm.$t('button.not_set'))+" ")])]}},{key:"item.answer_form_url",fn:function(ref){
var item = ref.item;
return [(item.answer_form_url)?_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.clickButton(item.id)}}},[_vm._v(" "+_vm._s(_vm.$t('button.answer_form_url_set'))+" ")]):_c('v-btn',{attrs:{"color":"error","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.clickButton(item.id)}}},[_vm._v(" "+_vm._s(_vm.$t('button.not_set'))+" ")])]}},{key:"footer",fn:function(ref){
var props = ref.props;
return [_c('v-divider'),_c('v-sheet',{staticClass:"py-4 mr-8",attrs:{"align":"right"}},[_vm._v(" "+_vm._s(props.pagination.itemsLength)+"件 ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }