<template>
  <v-card>
    <v-card-title class="card-title">{{ program.title }}</v-card-title>
    <v-card-text>
      <v-sheet>
        {{ program.st_at | momentFormat('ll') }}
        {{ program.st_at | momentFormat('LT') }}
        〜
        {{ program.ed_at | momentFormat('LT') }}
      </v-sheet>
      <v-sheet>{{ program.venue.title }}</v-sheet>
    </v-card-text>
    <v-card-text>
      <v-sheet>
        <v-btn
          color="primary"
          x-large
          block
          @click="
            $router.push({
              name: 'user-programs-show',
              params: { programId: program.id },
            })
          "
        >
          {{ $t('button.to_show') }}
        </v-btn>
      </v-sheet>
      <v-sheet align="right" class="mt-6 mb-n2 mr-10">
        <v-btn class="mr-auto" icon @click="expandProgram = !expandProgram">
          {{ $t('button.to_detail') }}
          <v-icon x-large class="ml-2">
            {{ expandProgram ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
          </v-icon>
        </v-btn>
      </v-sheet>
    </v-card-text>
    <v-expand-transition>
      <div v-show="expandProgram">
        <v-divider></v-divider>
        <v-card-text class="word-breaker">
          {{ program.desc }}
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>
<script>
export default {
  props: {
    program: { type: Object, required: true },
  },
  data: () => ({
    expandProgram: false,
  }),
}
</script>
<style scoped lang="scss">
.card-title {
  font-size: 1.1rem !important;
  line-height: 1.5rem !important;
}
</style>
