<template>
  <div>
    <page-title
      class="mb-4"
      @click:new="$router.push({ name: 'admin-users-new' })"
    />
    <index-search
      class="mb-4"
      v-model="search"
      is-admin
      :loading-loms="loadingLoms"
      :loms="loms"
      @search="getUsers"
    />
    <index-table
      class="mb-4"
      v-model="selectedUserIds"
      is-admin
      :users="users"
      :loading="loading"
      :total-count="totalCount"
      :table-options="tableOptions"
      @click:row="clickRow"
      @update:options="updateOptions"
    />
    <index-multi-update
      is-admin
      :loading="loading"
      :selected-user-ids="selectedUserIds"
      @click:ban="accountBan"
      @click:active="accountActive"
    />
  </div>
</template>
<script>
import AdminApi from '@/api/admins'
import PageTitle from '@/components/areas/common/PageTitleWithNewButton'
import IndexSearch from '@/components/areas/users/Index/Search'
import IndexTable from '@/components/areas/users/Index/Table'
import IndexMultiUpdate from '@/components/areas/users/Index/MultiUpdate'
export default {
  mixins: [AdminApi],
  components: {
    PageTitle,
    IndexSearch,
    IndexTable,
    IndexMultiUpdate,
  },
  data: () => ({
    loading: false,
    loadingLoms: false,
    search: {
      name_or_hira: '',
      organization_or_hira: '',
      email: '',
      phone: '',
      user_type: '',
      join_type: '',
      account_type: '',
      lom_id: '',
      is_visit_venue: '',
    },
    tableOptions: {
      page: 1,
      per: 10,
    },
    users: [],
    totalCount: 0,
    selectedUserIds: [],
    loms: [],
  }),
  created() {
    this.getUsers()
    this.getLoms()
  },
  methods: {
    getUsers() {
      this.loading = true
      const params = { ...this.search, ...this.tableOptions }
      this.axios
        .get(this.API.users.index, { params })
        .then((res) => {
          this.users = res.data.users
          this.totalCount = res.data.total_count
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
    getLoms() {
      this.loadingLoms = true
      this.axios
        .get(this.API.loms.index)
        .then((res) => {
          this.loms = res.data.loms
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loadingLoms = false
        })
    },
    accountBan() {
      this.loading = true
      const params = { user_ids: this.selectedUserIds }
      this.axios
        .post(this.API.users.ban, params)
        .then(() => {
          this.getUsers()
          this.$toasted.success(this.$t('success.create'))
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
    accountActive() {
      this.loading = true
      const params = { user_ids: this.selectedUserIds }
      this.axios
        .post(this.API.users.active, params)
        .then(() => {
          this.getUsers()
          this.$toasted.success(this.$t('success.create'))
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
    clickRow(item) {
      this.$router.push({
        name: 'admin-users-show',
        params: { userId: item.id },
      })
    },
    updateOptions({ page, itemsPerPage }) {
      const isChangedPage = this.tableOptions.page !== page
      const isChangedPer = this.tableOptions.per !== itemsPerPage
      if (!isChangedPage && !isChangedPer) {
        return
      }
      this.tableOptions.page = page
      this.tableOptions.per = itemsPerPage
      this.getUsers()
    },
  },
  metaInfo() {
    return this.$t(`meta_tags.${this.$route.name}`)
  },
}
</script>
