var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$$columnName('user.email'),"rules":"required|max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$$columnName('user.email'),"error-messages":errors,"outlined":""},model:{value:(_vm.internalValue.email),callback:function ($$v) {_vm.$set(_vm.internalValue, "email", $$v)},expression:"internalValue.email"}})]}}])})],1),(_vm.isNewRecord)?[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"vid":"password","name":_vm.$$columnName('user.password'),"rules":"required|min:8|max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-field-password',{attrs:{"text-field-options":{
            label: _vm.$$columnName('user.password'),
            errorMessages: errors,
            outlined: true,
          }},model:{value:(_vm.internalValue.password),callback:function ($$v) {_vm.$set(_vm.internalValue, "password", $$v)},expression:"internalValue.password"}})]}}],null,false,2730180795)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$$columnName('user.password_confirmation'),"rules":"required|min:8|max:256|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('text-field-password',{attrs:{"text-field-options":{
            label: _vm.$$columnName('user.password_confirmation'),
            errorMessages: errors,
            outlined: true,
          }},model:{value:(_vm.internalValue.password_confirmation),callback:function ($$v) {_vm.$set(_vm.internalValue, "password_confirmation", $$v)},expression:"internalValue.password_confirmation"}})]}}],null,false,514358459)})],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }