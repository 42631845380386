<template>
  <v-card>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="teachers"
      disable-sort
      hide-default-footer
      :items-per-page="-1"
      @click:row="$emit('click:row', $event)"
    >
      <template #[`item.desc`]="{ item }">
        {{ item.desc | tranc }}
      </template>
      <template #[`item.image`]="{ item }">
        <v-avatar v-if="item.image" size="36">
          <v-img :src="item.image.url" />
        </v-avatar>
      </template>
      <template #[`footer`]="{ props }">
        <v-divider></v-divider>
        <v-sheet class="py-4 mr-8" align="right">
          {{ props.pagination.itemsLength }}件
        </v-sheet>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
export default {
  props: {
    loading: { type: Boolean, required: true },
    teachers: { type: Array, required: true },
  },
  data: () => ({
    headers: [],
  }),
  created() {
    this.headers = [
      { text: this.$$columnName('teacher.image'), value: 'image' },
      { text: this.$$columnName('teacher.name'), value: 'name' },
      { text: this.$$columnName('teacher.name_hira'), value: 'name_hira' },
      { text: this.$$columnName('teacher.desc'), value: 'desc' },
    ]
  },
}
</script>
