export default {
  computed: {
    API() {
      return this.APIwithId()
    },
  },
  methods: {
    APIwithId(ids = {}) {
      const programId = ids.programId
        ? ids.programId
        : this.$route.params.programId
      const conferenceId = ids.conferenceId
        ? ids.conferenceId
        : this.$store.getters.conference.id
      return {
        auth: {
          sign_in: '/api/v1/auth/sign_in',
          sign_up: '/api/v1/auth',
          sign_out: '/api/v1/auth/sign_out',
        },
        password: {
          create: '/api/v1/auth/password',
          update: '/api/v1/auth/password',
        },
        users: {
          show: '/api/v1/user',
          update: '/api/v1/user',
        },
        loms: {
          index: '/api/v1/loms',
        },
        programs: {
          index: `/api/v1/conferences/${conferenceId}/programs`,
          show: `/api/v1/conferences/${conferenceId}/programs/${programId}`,
        },
        teachers: {
          index: `/api/v1/conferences/${conferenceId}/teachers`,
        },
        venues: {
          index: `/api/v1/conferences/${conferenceId}/venues`,
        },
        conferences: {
          index: '/api/v1/conferences',
          show: `/api/v1/conferences/${conferenceId}`,
          users_conferences: `/api/v1/conferences/${conferenceId}/users_conferences`,
          visit_venue: `/api/v1/conferences/${conferenceId}/visit_venue`,
        },
        audiences_counts: {
          create: `/api/v1/conferences/${conferenceId}/programs/${programId}/audiences_counts`,
        },
        jci_member: {
          login: '/api/v1/jci_member_login',
          token: '/api/v1/jci_member_login/redirect',
        },
      }
    },
  },
}
