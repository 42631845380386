<template>
  <div>
    <selected-conference-info />
    <page-title
      class="mb-4"
      @click:index="$router.push({ name: 'manager-venues-index' })"
    />
    <v-card :loading="loading" :max-width="500">
      <v-card-title></v-card-title>
      <v-card-text>
        <form-with-validation :loading="loading" @click:save="save">
          <entry-form v-model="venue" />
        </form-with-validation>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import ManagerApi from '@/api/managers'
import SelectedConferenceInfo from '@/components/areas/managers/Card/SelectedConferenceInfo'
import PageTitle from '@/components/areas/common/PageTitleWithIndexButton'
import FormWithValidation from '@/components/areas/common/FormWithValidation'
import EntryForm from './Form/EntryForm'
export default {
  mixins: [ManagerApi],
  components: {
    SelectedConferenceInfo,
    PageTitle,
    FormWithValidation,
    EntryForm,
  },
  data: () => ({
    loading: false,
    venue: {
      name: '',
    },
  }),
  created() {
    if (this.isNewRecord) {
      return
    }
    this.getVenue()
  },
  computed: {
    isNewRecord() {
      return !this.$route.params.venueId
    },
  },
  methods: {
    getVenue() {
      this.loading = true
      this.axios
        .get(this.API.venues.show)
        .then((res) => {
          this.venue = res.data.venue
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
    save() {
      this.loading = true
      const path = this.isNewRecord
        ? this.API.venues.create
        : this.API.venues.update
      const saveAxios = this.isNewRecord ? this.axios.post : this.axios.patch
      saveAxios(path, { venue: this.venue })
        .then((res) => {
          this.$router.push({
            name: 'manager-venues-show',
            params: { venueId: res.data.venue.id },
          })
          this.$toasted.success(this.$t('success.create'))
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  metaInfo() {
    return this.$t(`meta_tags.${this.$route.name}`)
  },
}
</script>
