<template>
  <validation-observer ref="observer">
    <v-form @submit.prevent="save">
      <slot></slot>
      <v-row>
        <v-col align="right">
          <v-btn :loading="loading" color="primary" type="submit" block x-large>
            {{ buttonText }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </validation-observer>
</template>
<script>
export default {
  props: {
    loading: { type: Boolean, required: true },
    buttonText: { type: String, required: true },
  },
  methods: {
    async save() {
      if (this.loading) {
        return this.$toasted.error(this.$t('errors.processing'))
      }
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return this.$toasted.error(this.$t('errors.params'))
      }
      this.$emit('submit')
    },
  },
}
</script>
