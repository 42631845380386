var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mb-4"},[_c('v-card-text',[_c('v-row',{staticClass:"mt-n1 mb-n7"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('label.import.select_upload_file'),"rules":"ext:xls,xlsx|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{attrs:{"label":_vm.$t('label.import.input_upload_file'),"error-messages":errors,"accept":".xls, .xlsx","truncate-length":"50","outlined":"","dense":"","multiple":""},model:{value:(_vm.internalValue.uploadFiles),callback:function ($$v) {_vm.$set(_vm.internalValue, "uploadFiles", $$v)},expression:"internalValue.uploadFiles"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$$tableName('lom'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select-dialog-search-lom',{attrs:{"loms":_vm.loms,"select-options":{
                label: _vm.$$tableName('lom'),
                outlined: true,
                dense: true,
                clearable: true,
                errorMessages: errors,
              }},model:{value:(_vm.internalValue.lomId),callback:function ($$v) {_vm.$set(_vm.internalValue, "lomId", $$v)},expression:"internalValue.lomId"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4","align":"right"}},[_c('v-btn',{staticClass:"px-16 font-weight-bold",attrs:{"color":"primary","elevation":"2","large":""},on:{"click":function($event){return _vm.$emit('file:upload')}}},[_vm._v(" "+_vm._s(_vm.$t('button.upload'))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }