<template>
  <v-dialog v-model="dialog" max-width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" v-bind="attrs" v-on="on">
        {{ $t('button.new') }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        {{ $t('button.new') }}
      </v-card-title>
      <v-divider class="mb-4" />
      <v-card-text>
        <v-sheet class="word-breaker mb-4">{{
          $t('dialog.jci_payments.create')
        }}</v-sheet>
        <jci-payment-form
          v-model="jciPayment"
          :loading="loading"
          @click:save="save"
          @click:cancel="dialog = false"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import JciPaymentForm from '@/components/areas/managers/Form/JciPaymentForm'
export default {
  components: {
    JciPaymentForm,
  },
  props: {
    jciPayment: { type: Object, default: () => ({}) },
    loading: { type: Boolean, required: true },
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    save() {
      this.dialog = false
      this.$emit('click:save')
    },
  },
}
</script>
