var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('selected-conference-info'),_c('page-title',{staticClass:"mb-4"}),_c('v-sheet',{staticClass:"jci-background-color py-4 px-4 mb-4",attrs:{"dark":""}},[_vm._v(" "+_vm._s(_vm.$t('label.conference_qrcode'))+" ")]),_c('v-card',[_c('v-simple-table',{staticClass:"mouse-over-highlight-disabled"},[_c('tbody',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$$columnName('conference.title')))]),_c('th',{attrs:{"width":"30%"}},[_vm._v(_vm._s(_vm.$t('label.qrcode')))])]),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$store.getters.conference.title))]),_c('td',[_c('btn-dialog-qrcode',{attrs:{"title":_vm.$t('label.conference_qrcode'),"url":_vm.redirectUrl}}),_c('v-btn',{staticClass:"ml-2",on:{"click":function($event){_vm.$$downloadQrcode(
                  _vm.redirectUrl,
                  ((_vm.$t('label.conference_qrcode')) + ".png"),
                  { width: 400 }
                )}}},[_vm._v(" "+_vm._s(_vm.$t('button.download'))+" ")])],1)])])])],1),_c('v-sheet',{staticClass:"jci-background-color py-4 px-4 my-4",attrs:{"dark":""}},[_vm._v(" "+_vm._s(_vm.$t('label.signup_url_qrcode'))+" ")]),_c('v-card',[_c('v-simple-table',{staticClass:"mouse-over-highlight-disabled"},[_c('tbody',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$$columnName('user/profile.user_type')))]),_c('th',[_vm._v(_vm._s(_vm.$t('label.url')))]),_c('th',{attrs:{"width":"30%"}},[_vm._v(_vm._s(_vm.$t('label.qrcode')))])]),_vm._l((_vm.$$selectEnums('user_type_for_signup')),function(userType){return _c('tr',{key:userType.value},[_c('td',[_vm._v(_vm._s(userType.text))]),_c('td',[_c('v-text-field',{attrs:{"value":_vm.signupUrl(userType.value),"append-outer-icon":'mdi-content-copy',"readonly":""},on:{"click:append-outer":function($event){_vm.copyToClipboard(_vm.signupUrl(userType.value))}}})],1),_c('td',[_c('btn-dialog-qrcode',{attrs:{"title":userType.text,"url":_vm.signupUrl(userType.value)}}),_c('v-btn',{staticClass:"ml-2",on:{"click":function($event){_vm.$$downloadQrcode(
                  _vm.signupUrl(userType.value),
                  ((_vm.$t('label.qrcode')) + "_" + (userType.text) + ".png"),
                  { width: 400 }
                )}}},[_vm._v(" "+_vm._s(_vm.$t('button.download'))+" ")])],1)])})],2)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }