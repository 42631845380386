<template>
  <div>
    <page-title
      class="mb-4"
      @click:new="$router.push({ name: 'admin-loms-new' })"
    />
    <index-search class="mb-4" v-model="search" @search="getLoms" />
    <index-table
      class="mb-4"
      :loms="loms"
      :loading="loading"
      @click:row="clickRow"
    />
  </div>
</template>
<script>
import AdminApi from '@/api/admins'
import PageTitle from '@/components/areas/common/PageTitleWithNewButton'
import IndexSearch from './Index/Search'
import IndexTable from './Index/Table'
export default {
  mixins: [AdminApi],
  components: {
    PageTitle,
    IndexSearch,
    IndexTable,
  },
  data: () => ({
    loading: false,
    search: {
      name: '',
      lom_code: '',
      block: '',
    },
    loms: [],
  }),
  created() {
    this.getLoms()
  },
  methods: {
    getLoms() {
      this.loading = true
      this.axios
        .get(this.API.loms.index, { params: this.search })
        .then((res) => {
          this.loms = res.data.loms
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
    clickRow(item) {
      this.$router.push({ name: 'admin-loms-show', params: { lomId: item.id } })
    },
  },
  metaInfo() {
    return this.$t(`meta_tags.${this.$route.name}`)
  },
}
</script>
