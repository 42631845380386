<template>
  <div>
    <div v-html="sanitize(program.vimeo_html_tag)" />
    <button-to-answer-form
      v-if="program.participation_type === 'web'"
      class="mt-2"
      :answer-form-url="program.answer_form_url"
      color="success"
    />
  </div>
</template>
<script>
import Player from '@vimeo/player'
import ButtonToAnswerForm from '@/components/atoms/btn/ToAnswerForm'
export default {
  props: {
    program: { type: Object, required: true },
  },
  components: {
    ButtonToAnswerForm,
  },
  mounted() {
    const iframe = document.querySelector('iframe')
    const player = new Player(iframe)

    player.on('play', () => this.$emit('play:vimeo'))
  },
  methods: {
    sanitize(content) {
      return this.$sanitize(content, {
        allowedTags: [...this.$sanitize.defaults.allowedTags, 'iframe'],
        allowedAttributes: { '*': ['style', 'class'], iframe: ['*'] },
        allowedIframeDomains: ['vimeo.com'],
        allowedSchemes: [...this.$sanitize.defaults.allowedSchemes],
      })
    },
  },
}
</script>
