<template>
  <v-sheet align="center">
    <v-progress-circular
      class="mt-12"
      size="100"
      color="primary"
      indeterminate
    />
  </v-sheet>
</template>
<script>
import UserApi from '@/api/users'
export default {
  mixins: [UserApi],
  created() {
    this.registToConference()
  },
  methods: {
    registToConference() {
      this.axios
        .post(
          this.APIwithId({ conferenceId: this.$route.params.conferenceId })
            .conferences.users_conferences
        )
        .then((res) => {
          this.redirectPage()
        })
        .catch((err) => {
          if (err.response.status !== 422) return

          this.$toasted.error(err.response.data.errors.join('\n'))
        })
    },
    redirectPage() {
      this.$router.push({ name: 'user-conferences-index' })
    },
  },
  metaInfo() {
    return this.$t(`meta_tags.${this.$route.name}`)
  },
}
</script>
