import moment from 'moment'
import Qrcode from 'qrcode'
import VueCookies from 'vue-cookies'
export default {
  filters: {
    momentFormat(value, format) {
      if (!value) return ''
      moment.locale('ja') // TODO i18n等を入れたときに合わせて変更できるようにすべき
      return moment(value).format(format)
    },
    tranc(value, length = 30) {
      if (!value) {
        return value
      }
      if (value.length <= length) {
        return value
      }
      return value.slice(0, length) + '...'
    },
  },
  methods: {
    // =================================================
    // rails独自のformatに合わせたcastが必要
    // =================================================
    $$castToFormData(params) {
      function makeAppendData(form, key, val) {
        const klass = Object.prototype.toString.call(val).slice(8, -1)
        if (klass === 'Array') {
          if (val.length === 0) form.append(`${key}[]`, '')
          else val.forEach((v) => makeAppendData(form, `${key}[]`, v))
        } else if (klass === 'Object') {
          const keys = Object.keys(val)
          keys.forEach((k) => makeAppendData(form, `${key}[${k}]`, val[k]))
        } else if (val === null || val === undefined) {
          // do nothing
        } else {
          form.append(key, val)
        }
      }
      const formData = new FormData()
      const keys = Object.keys(params)
      keys.forEach((key) => makeAppendData(formData, key, params[key]))
      return formData
    },
    // =================================================
    // csvのダウンロード
    // =================================================
    $$downloadCsv(csvText, fileName) {
      const bom = new Uint8Array([0xef, 0xbb, 0xbf])
      const blob = new Blob([bom, csvText], { type: 'text/csv' })
      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveOrOpenBlob(blob, fileName)
      } else {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = fileName
        link.click()
        URL.revokeObjectURL(link.href)
      }
    },
    // =================================================
    // QRcodeのダウンロード
    // =================================================
    $$downloadQrcode(url, fileName, options = {}) {
      Qrcode.toDataURL(url, options, (err, dataUrl) => {
        const link = document.createElement('a')
        link.href = dataUrl
        link.download = fileName
        link.click()
        URL.revokeObjectURL(link.href)
      })
    },
    // =================================================
    // 要素の有無チェックはよく使うので定義
    // =================================================
    $$exist(val) {
      if (!val) {
        return false
      }
      const cls = Object.prototype.toString.call(val).slice(8, -1)
      if (cls === 'Array') {
        return val.length > 0
      }
      if (cls === 'Object') {
        return Object.keys(val).length > 0
      }
      return val.length > 0
    },
    $$blank(val) {
      return !this.$$exist(val)
    },
    // =================================================
    // localeファイルからselectを構成
    // =================================================
    $$selectEnums(val) {
      const enums = []
      const locales = this.$t(`enum.${val}`)
      Object.keys(locales).forEach((key) => {
        enums.push({ text: locales[key], value: key })
      })
      return enums
    },
    $$getEnumText(key, val) {
      if (!val) {
        return null
      }
      return this.$t(`enum.${key}.${val}`)
    },
    // =================================================
    // localeファイルからselectを構成（はい/いいえ）
    // =================================================
    $$selectFlgs() {
      return [
        { text: this.$t('select.flg.no'), value: 0 },
        { text: this.$t('select.flg.yes'), value: 1 },
      ]
    },
    $$getFlgText(val) {
      switch (val) {
        case 0:
          return this.$t('select.flg.no')
        case 1:
          return this.$t('select.flg.yes')
        default:
          return ''
      }
    },
    // =================================================
    // localeファイルからmodelの翻訳を取得
    // $$columnNameは'tableName.columnName'で受け取ること
    // =================================================
    $$tableName(tableName) {
      if (!tableName) {
        return null
      }
      return this.$t(`activerecord.models.${tableName}`)
    },
    $$columnName(tableNameDotColumnName) {
      if (!tableNameDotColumnName) {
        return null
      }
      return this.$t(`activerecord.attributes.${tableNameDotColumnName}`)
    },
    // =================================================
    // Objectのvalueが空のkeyを除外
    // =================================================
    $$removeBlankValue(object) {
      const params = {}
      for (const [key, value] of Object.entries(object)) {
        if (value != null && value !== '') params[key] = value
      }
      return params
    },
    // =================================================
    // Objectのcloneを作成
    // =================================================
    $$clone(object) {
      return JSON.parse(JSON.stringify(object))
    },
    // =================================================
    // デフォルトページのpathNameを取得
    // =================================================
    $$adminDefaultPagePathName() {
      return 'admin-admins-index'
    },
    $$managerDefaultPagePathName(conferenceId) {
      return conferenceId
        ? 'manager-conferences-show'
        : 'manager-conferences-index'
    },
    $$userDefaultPagePathName() {
      return 'user-conferences-index'
    },
    // =================================================
    // userのlogin後のpush先を取得
    // =================================================
    $$userPushAfterLogin() {
      if (VueCookies.get('visitVenueFlg'))
        return { name: 'user-qrcodes-welcome' }
      if (VueCookies.get('visitProgramId'))
        return {
          name: 'user-program-qrcodes-redirect',
          params: { programId: VueCookies.get('visitProgramId') },
        }

      return { name: this.$$userDefaultPagePathName() }
    },
  },
}
