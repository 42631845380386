<template>
  <div>
    <page-title
      class="mb-4"
      @click:back="
        $router.push({
          name: $$managerDefaultPagePathName($store.getters.conference.id),
        })
      "
    />
    <v-card :max-width="500" :loading="loading">
      <v-card-title></v-card-title>
      <v-card-text>
        <form-with-validation :loading="loading" @click:save="save">
          <entry-form v-model="password" />
        </form-with-validation>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import ManagerApi from '@/api/managers'
import PageTitle from '@/components/areas/common/PageTitleWithBackButton'
import FormWithValidation from '@/components/areas/common/FormWithValidation'
import EntryForm from '@/components/areas/Password/EntryForm'
export default {
  mixins: [ManagerApi],
  components: {
    PageTitle,
    FormWithValidation,
    EntryForm,
  },
  data: () => ({
    loading: false,
    password: {
      current_password: '',
      password: '',
      password_confirmation: '',
    },
  }),
  methods: {
    async save() {
      this.loading = true
      this.axios
        .patch(this.API.password.update, this.password)
        .then(() => {
          this.$router.push({
            name: this.$$managerDefaultPagePathName(
              this.$store.getters.conference.id
            ),
          })
          this.$toasted.success(this.$t('success.update'))
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.full_messages.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  metaInfo() {
    return this.$t(`meta_tags.${this.$route.name}`)
  },
}
</script>
