<template>
  <v-row justify="center">
    <v-col cols="12" md="6">
      <v-card class="py-4 mb-4">
        <v-card-title class="mb-8">
          <v-row>
            <v-col align="center">
              <h2>{{ $t(`meta_tags.${this.$route.name}.title`) }}</h2>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" align="center">
              <div class="word-breaker">{{ message }}</div>
            </v-col>
            <v-col
              v-if="!isConfirmStatus.WAIT"
              class="mt-6"
              cols="12"
              align="center"
            >
              <v-btn color="primary" block x-large :to="{ name: 'user-login' }">
                {{ $t('button.to_login') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data: () => ({
    confirmStatus: 'wait',
  }),
  computed: {
    message() {
      let msg = ''
      switch (this.confirmStatus) {
        case 'wait':
          msg = this.$t('description.confirmation.wait')
          break
        case 'success':
          msg = this.$t('description.confirmation.success')
          break
        case 'faile':
          msg = this.$t('description.confirmation.faile')
          break
      }
      return msg
    },
    isConfirmStatus() {
      return {
        WAIT: this.confirmStatus === 'wait',
        SUCCESS: this.confirmStatus === 'success',
        FAILE: this.confirmStatus === 'faile',
      }
    },
  },
  created() {
    this.authConfirm()
  },
  methods: {
    authConfirm() {
      const token = this.$route.query.confirmation_token
      this.axios
        .get('/api/v1/auth/confirmation', {
          params: { confirmation_token: token },
        })
        .then(() => {
          this.confirmStatus = 'success'
        })
        .catch(() => {
          this.confirmStatus = 'faile'
        })
    },
  },
  metaInfo() {
    return this.$t(`meta_tags.${this.$route.name}`)
  },
}
</script>
