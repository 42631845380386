<template>
  <div>
    <page-title class="mb-4" />
    <h4 class="mb-1">{{ $t('credit.technology') }}</h4>
    <h5>{{ $t('credit.skygrid') }}</h5>
    <br />
    <h4 class="mb-1">{{ $t('credit.front_end') }}</h4>
    <h5>{{ '永井 優希 - ' + $t('credit.skygrid') }}</h5>
    <h5>{{ '松岡 弘晃 - ' + $t('credit.skygrid') }}</h5>
    <h5>{{ '榊原 健 　- ' + $t('credit.skygrid') }}</h5>
    <br />
    <h4 class="mb-1">{{ $t('credit.back_end') }}</h4>
    <h5>{{ '松岡 弘晃 - ' + $t('credit.skygrid') }}</h5>
    <h5>{{ '田中 裕一 - ' + $t('credit.skygrid') }}</h5>
    <h5>{{ '榊原 健 　- ' + $t('credit.skygrid') }}</h5>
    <br />
    <h4 class="mb-1">{{ $t('credit.infrastracture') }}</h4>
    <h5>{{ '田中 裕一 - ' + $t('credit.skygrid') }}</h5>
    <br />
    <h4 class="mb-1">{{ $t('credit.project_management') }}</h4>
    <h5>{{ '山本 洋佑 - ' + $t('credit.skygrid') }}</h5>
    <h5>{{ '永井 優希 - ' + $t('credit.skygrid') }}</h5>
    <h5>{{ '松岡 弘晃 - ' + $t('credit.skygrid') }}</h5>
    <br />
    <h6 class="mt-12">
      ©2021 by 公益社団法人日本青年会議所 All Rights Reserved.
    </h6>
  </div>
</template>
<script>
import PageTitle from '@/components/areas/common/PageTitle'
export default {
  components: {
    PageTitle,
  },
  metaInfo() {
    return this.$t(`meta_tags.${this.$route.name}`)
  },
}
</script>
<style scoped>
h5 {
  font-weight: normal;
  color: gray;
}
</style>
