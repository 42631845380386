<template>
  <v-row justify="center">
    <v-col cols="12" md="6">
      <v-card class="py-4 mb-4">
        <v-card-title class="mb-8">
          <v-row>
            <v-col align="center">
              <h2>{{ $t(`meta_tags.${this.$route.name}.title`) }}</h2>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <reset-desc class="mb-8" />
          <form-with-validation-block
            :loading="loading"
            :button-text="$t('button.send')"
            @submit="send"
          >
            <entry-form v-model="account" />
          </form-with-validation-block>
        </v-card-text>
      </v-card>
      <back-to-login @back="$router.push({ name: 'manager-login' })" />
    </v-col>
  </v-row>
</template>
<script>
import ManagerApi from '@/api/managers'
import FormWithValidationBlock from '@/components/areas/common/FormWithValidationBlock'
import EntryForm from '@/components/areas/SendMailPasswordReset/EntryForm'
import ResetDesc from '@/components/areas/SendMailPasswordReset/ResetDesc'
import BackToLogin from '@/components/areas/SendMailPasswordReset/BackToLogin'
export default {
  mixins: [ManagerApi],
  components: {
    FormWithValidationBlock,
    EntryForm,
    ResetDesc,
    BackToLogin,
  },
  data: () => ({
    loading: false,
    account: {
      email: '',
    },
  }),
  methods: {
    send() {
      this.loading = true
      this.axios
        .post(this.API.password.create, this.account)
        .then(() => {
          this.$router.push({ name: 'manager-login' })
          this.$toasted.success(this.$t('success.email'))
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  metaInfo() {
    return this.$t(`meta_tags.${this.$route.name}`)
  },
}
</script>
