<template>
  <v-row>
    <v-col cols="12" md="12">
      <validation-provider
        v-slot="{ errors }"
        :name="$$columnName('program.title')"
        rules="required|max:100"
      >
        <v-text-field
          v-model="internalValue.title"
          :label="$$columnName('program.title')"
          :error-messages="errors"
          outlined
        />
      </validation-provider>
    </v-col>
    <v-col cols="12" md="12">
      <validation-provider
        v-slot="{ errors }"
        :name="$$columnName('program.desc')"
        rules="max:1000"
      >
        <v-textarea
          v-model="internalValue.desc"
          :label="$$columnName('program.desc')"
          :error-messages="errors"
          outlined
          auto-grow
          :rows="3"
          counter="1000"
        />
      </validation-provider>
    </v-col>
    <v-col cols="12" md="12">
      <validation-provider
        v-slot="{ errors }"
        :name="$$tableName('teacher')"
        rules=""
      >
        <v-select
          v-model="internalValue.teacher_ids"
          :label="$$tableName('teacher')"
          :error-messages="errors"
          :items="selectTeachers"
          outlined
          :loading="loadingTeachers"
          multiple
          chips
        />
      </validation-provider>
    </v-col>
    <v-col cols="12" md="6">
      <validation-provider
        v-slot="{ errors }"
        :name="$$columnName('program.committie')"
        rules="max:30"
      >
        <v-text-field
          v-model="internalValue.committie"
          :label="$$columnName('program.committie')"
          :error-messages="errors"
          outlined
        />
      </validation-provider>
    </v-col>
    <v-col cols="12" md="6">
      <validation-provider
        v-slot="{ errors }"
        :name="$$columnName('program.venue_id')"
        rules="required"
      >
        <v-select
          v-model="internalValue.venue_id"
          :label="$$columnName('program.venue_id')"
          :error-messages="errors"
          :items="selectVenues"
          outlined
          :loading="loadingVenues"
        />
      </validation-provider>
    </v-col>
    <v-col cols="12" md="12">
      <div class="word-breaker red--text">
        {{ $t('program.only_zoom_url_or_vimeo_html_tag') }}
      </div>
    </v-col>
    <v-col cols="12" md="12">
      <validation-provider
        v-slot="{ errors }"
        :name="$$columnName('program.zoom_url')"
        rules="max:2048|url"
      >
        <v-text-field
          v-model="internalValue.zoom_url"
          :label="$$columnName('program.zoom_url')"
          :error-messages="errors"
          outlined
        />
      </validation-provider>
    </v-col>
    <v-col cols="12" md="12">
      <validation-provider
        v-slot="{ errors }"
        :name="$$columnName('program.vimeo_html_tag')"
        rules="max:3000|vimeo_html_tag"
      >
        <v-text-field
          v-model="internalValue.vimeo_html_tag"
          :label="$$columnName('program.vimeo_html_tag')"
          :error-messages="errors"
          outlined
        />
      </validation-provider>
    </v-col>
    <v-col cols="12" md="12">
      <validation-provider
        v-slot="{ errors }"
        :name="$$columnName('program.answer_form_url')"
        rules="max:2048|url"
      >
        <v-text-field
          v-model="internalValue.answer_form_url"
          :label="$$columnName('program.answer_form_url')"
          :error-messages="errors"
          outlined
        />
      </validation-provider>
    </v-col>
    <v-col cols="12" md="6">
      <validation-provider
        v-slot="{ errors }"
        :name="$$columnName('program.st_at')"
        rules="required"
      >
        <datetime
          v-model="internalValue.st_at"
          :label="$$columnName('program.st_at')"
          :error-messages="errors"
          :text-field-options="{
            outlined: true,
            clearable: true,
            appendIcon: 'mdi-calendar',
          }"
        />
      </validation-provider>
    </v-col>
    <v-col cols="12" md="6">
      <validation-provider
        v-slot="{ errors }"
        :name="$$columnName('program.ed_at')"
        rules="required"
      >
        <datetime
          v-model="internalValue.ed_at"
          :label="$$columnName('program.ed_at')"
          :error-messages="errors"
          :text-field-options="{
            outlined: true,
            clearable: true,
            appendIcon: 'mdi-calendar',
          }"
        />
      </validation-provider>
    </v-col>
    <v-col cols="12" md="6">
      <validation-provider
        vid="archive_st_at"
        v-slot="{ errors }"
        :name="$$columnName('program.archive_st_at')"
        rules="archivePeriod:@archive_ed_at"
      >
        <datetime
          v-model="internalValue.archive_st_at"
          :label="$$columnName('program.archive_st_at')"
          :error-messages="errors"
          :text-field-options="{
            outlined: true,
            clearable: true,
            appendIcon: 'mdi-calendar',
          }"
        />
      </validation-provider>
    </v-col>
    <v-col cols="12" md="6">
      <validation-provider
        vid="archive_ed_at"
        v-slot="{ errors }"
        :name="$$columnName('program.archive_ed_at')"
        rules="archivePeriod:@archive_st_at"
      >
        <datetime
          v-model="internalValue.archive_ed_at"
          :label="$$columnName('program.archive_ed_at')"
          :error-messages="errors"
          :text-field-options="{
            outlined: true,
            clearable: true,
            appendIcon: 'mdi-calendar',
          }"
        />
      </validation-provider>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    value: { type: Object, required: true },
    loadingTeachers: { type: Boolean, required: true },
    loadingVenues: { type: Boolean, required: true },
    selectTeachers: { type: Array, required: true },
    selectVenues: { type: Array, required: true },
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
