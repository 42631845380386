<template>
  <v-btn small outlined @click="logout"> logout </v-btn>
</template>
<script>
import UserApi from '@/api/users'
export default {
  mixins: [UserApi],
  methods: {
    logout() {
      this.axios
        .delete(this.API.auth.sign_out)
        .then(() => {
          this.$store.dispatch('logout')
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.$router.push({ name: 'top' })
        })
    },
  },
}
</script>
