<template>
  <div class="pa-6" align="center">
    <v-btn
      class="mt-8"
      color="primary"
      height="80"
      x-large
      @click="$emit('open:zoom')"
    >
      <v-icon class="mx-12" size="50"> mdi-video-box </v-icon>
    </v-btn>
    <div class="my-8">{{ $t('button.to_zoom') }}</div>
    <button-to-answer-form
      v-if="program.participation_type === 'web'"
      class="mt-2"
      :answer-form-url="program.answer_form_url"
      color="success"
    />
  </div>
</template>
<script>
import ButtonToAnswerForm from '@/components/atoms/btn/ToAnswerForm'
export default {
  props: {
    program: { type: Object, required: true },
  },
  components: {
    ButtonToAnswerForm,
  },
}
</script>
