<template>
  <div>
    <page-title
      class="mb-4"
      @click:index="$router.push({ name: 'admin-admins-index' })"
    />
    <v-card :loading="loading" :max-width="500">
      <v-card-title></v-card-title>
      <v-card-text>
        <form-with-validation :loading="loading" @click:save="save">
          <entry-form v-model="admin" :is-new-record="isNewRecord" />
        </form-with-validation>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import AdminApi from '@/api/admins'
import PageTitle from '@/components/areas/common/PageTitleWithIndexButton'
import FormWithValidation from '@/components/areas/common/FormWithValidation'
import EntryForm from './Form/EntryForm'
export default {
  mixins: [AdminApi],
  components: {
    PageTitle,
    FormWithValidation,
    EntryForm,
  },
  data: () => ({
    loading: false,
    admin: {
      email: '',
      password: '',
    },
  }),
  created() {
    if (this.isNewRecord) {
      return
    }
    this.getAdmin()
  },
  computed: {
    isNewRecord() {
      return !this.$route.params.adminId
    },
  },
  methods: {
    getAdmin() {
      this.loading = true
      this.axios
        .get(this.API.admins.show)
        .then((res) => {
          this.admin = res.data.admin
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
    save() {
      this.loading = true
      const path = this.isNewRecord
        ? this.API.admins.create
        : this.API.admins.update
      const saveAxios = this.isNewRecord ? this.axios.post : this.axios.patch
      saveAxios(path, { admin: this.admin })
        .then((res) => {
          this.$router.push({
            name: 'admin-admins-show',
            params: { adminId: res.data.admin.id },
          })
          this.$toasted.success(this.$t('success.create'))
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  metaInfo() {
    return this.$t(`meta_tags.${this.$route.name}`)
  },
}
</script>
