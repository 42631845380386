<template>
  <div>
    <selected-conference-info />
    <page-title class="mb-4">
      <btn-dialog-jci-payments-form
        v-if="!$store.getters.manager.is_lom_representative"
        :jci-payment="jciPayment"
        :loading="loading"
        @click:save="save"
      />
      <btn-dialog-jci-payments-destroy
        v-if="!$store.getters.manager.is_lom_representative"
        :loading="loading"
        @click:destroy="selectDestroy"
      />
    </page-title>
    <index-search
      v-if="!$store.getters.manager.is_lom_representative"
      v-model="search"
      :loading-loms="loadingLoms"
      :loms="loms"
      class="mb-4"
      @search="pushQueryForSearch"
    />
    <validation-observer ref="observer">
      <form-file-upload
        v-model="uploadParams"
        :loms="loms"
        @file:upload="importUploadFiles"
      />
    </validation-observer>
    <index-table
      v-if="!$store.getters.manager.is_lom_representative"
      class="mb-4"
      v-model="selectedJciPaymentIds"
      :jci-payments="jciPayments"
      :loading="loading"
      :total-count="totalCount"
      :table-options="tableOptions"
      @update:options="updateOptions"
    />
  </div>
</template>
<script>
import ManagerApi from '@/api/managers'
import SelectedConferenceInfo from '@/components/areas/managers/Card/SelectedConferenceInfo'
import PageTitle from '@/components/areas/common/PageTitle'
import FormFileUpload from '@/components/areas/common/FormFileUpload'
import BtnDialogJciPaymentsForm from '@/components/areas/managers/Btn/Dialog/JciPaymentsForm'
import BtnDialogJciPaymentsDestroy from '@/components/areas/managers/Btn/Dialog/JciPaymentsDestroy'
import IndexTable from './Index/Table'
import IndexSearch from './Index/Search'
export default {
  mixins: [ManagerApi],
  components: {
    SelectedConferenceInfo,
    PageTitle,
    FormFileUpload,
    IndexTable,
    IndexSearch,
    BtnDialogJciPaymentsForm,
    BtnDialogJciPaymentsDestroy,
  },
  data: () => ({
    loading: false,
    loadingLoms: false,
    jciPayments: [],
    selectedJciPaymentIds: [],
    totalCount: 0,
    jciPayment: {
      name: '',
      member_number: '',
    },
    uploadParams: { uploadFiles: [], lomId: '' },
    search: {},
    tableOptions: {
      page: 1,
      per: 10,
    },
    loms: [],
  }),
  computed: {
    queryParams() {
      return { ...this.$options.data().tableOptions, ...this.$route.query }
    },
  },
  beforeRouteUpdate(to, from, next) {
    next()
    this.initSearchAndTableOptions()
    this.getJciPayments()
  },
  created() {
    this.initSearchAndTableOptions()
    this.getJciPayments()
    this.getLoms()
  },
  methods: {
    initSearchAndTableOptions() {
      const { page, per, ...tmpSearch } = this.queryParams
      this.search = tmpSearch
      this.tableOptions = { page: parseInt(page), per: parseInt(per) }
    },
    pushQueryForSearch() {
      const query = {
        ...this.$$removeBlankValue(this.search),
        ...this.tableOptions,
        page: 1,
      }
      this.$router.push({ query }, () => {})
    },
    getJciPayments() {
      this.loading = true
      this.axios
        .get(this.API.jci_payments.index, { params: this.queryParams })
        .then((res) => {
          this.jciPayments = res.data.jci_payments
          this.totalCount = res.data.total_count
        })
        .catch((err) => {
          if (err.response.status !== 422) return
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
    getLoms() {
      this.loadingLoms = true
      this.axios
        .get(this.API.loms.index)
        .then((res) => {
          this.loms = res.data.loms
        })
        .catch((err) => {
          if (err.response.status !== 422) return
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loadingLoms = false
        })
    },
    updateOptions({ page, itemsPerPage: per }) {
      const isChangedPage = this.tableOptions.page !== page
      const isChangedPer = this.tableOptions.per !== per
      if (!isChangedPage && !isChangedPer) return

      const query = { ...this.queryParams, page, per }
      this.$router.push({ query }, () => {})
    },
    async importUploadFiles() {
      if (!this.uploadParams.uploadFiles) return
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return this.$toasted.error(this.$t('errors.params'))
      }
      this.loading = true
      await this.axios
        .post(
          this.API.jci_payments.import_upload_files,
          this.$$castToFormData({
            upload_files: this.uploadParams.uploadFiles,
            lom_id: this.uploadParams.lomId,
          }),
          { headers: { 'content-type': 'multipart/form-data' } }
        )
        .then(() => {
          this.getJciPayments()
          this.$toasted.success(this.$t('success.create'))
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'), {
            duration: null,
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    save() {
      this.loading = true
      this.axios
        .post(this.API.jci_payments.create, { jci_payment: this.jciPayment })
        .then(() => {
          this.getJciPayments()
          this.jciPayment = { name: '', member_number: '' }
          this.$toasted.success(this.$t('success.create'))
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
    selectDestroy() {
      this.loading = true
      this.axios
        .post(this.API.jci_payments.select_destroy, {
          selected_ids: this.selectedJciPaymentIds,
        })
        .then(() => {
          this.getJciPayments()
          this.$toasted.success(this.$t('success.destroy'))
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  metaInfo() {
    return this.$t(`meta_tags.${this.$route.name}`)
  },
}
</script>
