<template>
  <div>
    <v-btn
      small
      light
      class="mr-2"
      style="pointer-events: none; text-transform: none"
    >
      {{ `${$t('label.logged_in_manager')}: ${$store.getters.auth.uid}` }}
    </v-btn>
    <v-btn small outlined @click="logout"> logout </v-btn>
  </div>
</template>

<script>
import ManagerApi from '@/api/managers'
export default {
  mixins: [ManagerApi],
  methods: {
    logout() {
      this.axios
        .delete(this.API.auth.sign_out)
        .then(() => {
          this.$store.dispatch('logout')
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.$router.push({ name: 'manager-login' })
        })
    },
  },
}
</script>
