var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$$columnName('user.member_number'),"rules":"required|max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$$columnName('user.member_number'),"placeholder":_vm.$t('placeholder.member_number'),"persistent-placeholder":"","error-messages":errors,"outlined":""},model:{value:(_vm.internalValue.member_number),callback:function ($$v) {_vm.$set(_vm.internalValue, "member_number", $$v)},expression:"internalValue.member_number"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"vid":"password","name":_vm.$$columnName('user.password'),"rules":"required|min:8|max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-field-password',{attrs:{"text-field-options":{
          label: _vm.$$columnName('user.password'),
          errorMessages: errors,
          outlined: true,
        }},model:{value:(_vm.internalValue.password),callback:function ($$v) {_vm.$set(_vm.internalValue, "password", $$v)},expression:"internalValue.password"}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }