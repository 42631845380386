import 'filepond-polyfill'
import '@/assets/jci-common.scss'
import Vue from 'vue'
import App from '@/App'
import vuetify from '@/plugins/vuetify'
import router from '@/router'
import store from '@/stores'
import axios from '@/plugins/axios'
import i18n from '@/plugins/vue-i18n'
import VueAxios from 'vue-axios'
import VueCookies from 'vue-cookies'
import VueMeta from 'vue-meta'
import VueToasted from '@/plugins/vue-toasted'
import VeeValidate from '@/plugins/vee-validate'
import VueDatetime from '@/plugins/vue-datetime'
import sanitizeHTML from 'sanitize-html'

import Datetime from '@/components/plugins/Datetime'
import JciCommon from '@/plugins/jci-common'

Vue.use(VueAxios, axios)
Vue.use(VeeValidate)
Vue.use(VueCookies)
Vue.use(VueMeta)
Vue.use(VueToasted)
Vue.use(VueDatetime)
Vue.mixin(JciCommon)
Vue.$cookies.config('1y', '', '', true)
Vue.component('Datetime', Datetime)
Vue.config.productionTip = false
Vue.prototype.$sanitize = sanitizeHTML

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
