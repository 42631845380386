<template>
  <div>
    <page-title
      class="mb-4"
      @click:index="$router.push({ name: 'admin-conferences-index' })"
    />
    <v-card :loading="loading" :max-width="500">
      <v-card-title></v-card-title>
      <v-card-text>
        <form-with-validation :loading="loading" @click:save="save">
          <entry-form
            v-model="conference"
            :loading-managers="loadingManagers"
            :select-managers="selectManagers"
            is-admin
          />
        </form-with-validation>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import AdminApi from '@/api/admins'
import PageTitle from '@/components/areas/common/PageTitleWithIndexButton'
import FormWithValidation from '@/components/areas/common/FormWithValidation'
import EntryForm from '@/components/areas/conferences/Form/EntryForm'
export default {
  mixins: [AdminApi],
  components: {
    PageTitle,
    FormWithValidation,
    EntryForm,
  },
  data: () => ({
    loading: false,
    loadingManagers: false,
    conference: {
      id: '',
      title: '',
      desc: '',
      managers: [],
      manager_ids: [],
      email: '',
      schedule_st_at: '',
      schedule_ed_at: '',
      image: {},
      remove_image: false,
      welcome_image: {},
      remove_welcome_image: false,
    },
    selectManagers: [],
  }),
  watch: {
    'conference.image'(val) {
      this.conference.remove_image = Object.keys(val).length === 0
    },
    'conference.welcome_image'(val) {
      this.conference.remove_welcome_image = Object.keys(val).length === 0
    },
  },
  created() {
    this.getManagers()
    if (this.isNewRecord) return
    this.getConference()
  },
  computed: {
    isNewRecord() {
      return !this.$route.params.conferenceId
    },
  },
  methods: {
    getConference() {
      this.loading = true
      this.axios
        .get(this.API.conferences.show)
        .then((res) => {
          this.conference = res.data.conference
        })
        .catch((err) => {
          if (err.response.status !== 422) return
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
    getManagers() {
      this.loadingManagers = true
      this.axios
        .get(this.API.managers.index)
        .then((res) => {
          this.selectManagers = res.data.managers.map((manager) => {
            return { text: manager.email, value: manager.id }
          })
        })
        .catch((err) => {
          if (err.response.status !== 422) return
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loadingManagers = false
        })
    },
    save() {
      this.loading = true
      const path = this.isNewRecord
        ? this.API.conferences.create
        : this.API.conferences.update
      const saveAxios = this.isNewRecord ? this.axios.post : this.axios.patch
      saveAxios(path, this.$$castToFormData({ conference: this.conference }), {
        headers: { 'content-type': 'multipart/form-data' },
      })
        .then((res) => {
          this.$router.push({
            name: 'admin-conferences-show',
            params: { conferenceId: res.data.conference.id },
          })
          this.$toasted.success(this.$t('success.create'))
        })
        .catch((err) => {
          if (err.response.status !== 422) return
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  metaInfo() {
    return this.$t(`meta_tags.${this.$route.name}`)
  },
}
</script>
