var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$$columnName('conference.title'),"rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$$columnName('conference.title'),"error-messages":errors,"outlined":""},model:{value:(_vm.internalValue.title),callback:function ($$v) {_vm.$set(_vm.internalValue, "title", $$v)},expression:"internalValue.title"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$$columnName('conference.desc'),"rules":"required|max:1000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":_vm.$$columnName('conference.desc'),"error-messages":errors,"outlined":"","auto-grow":"","rows":3,"counter":"1000"},model:{value:(_vm.internalValue.desc),callback:function ($$v) {_vm.$set(_vm.internalValue, "desc", $$v)},expression:"internalValue.desc"}})]}}])})],1),(_vm.isAdmin)?[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$$tableName('manager'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":_vm.$$tableName('manager'),"error-messages":errors,"items":_vm.selectManagers,"outlined":"","loading":_vm.loadingManagers,"multiple":"","chips":""},model:{value:(_vm.internalValue.manager_ids),callback:function ($$v) {_vm.$set(_vm.internalValue, "manager_ids", $$v)},expression:"internalValue.manager_ids"}})]}}],null,false,1586760517)})],1)]:_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$$columnName('conference.email'),"rules":"required|max:256|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$$columnName('conference.email'),"error-messages":errors,"outlined":""},model:{value:(_vm.internalValue.email),callback:function ($$v) {_vm.$set(_vm.internalValue, "email", $$v)},expression:"internalValue.email"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$$columnName('conference.publication_at'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('datetime',{attrs:{"label":_vm.$$columnName('conference.publication_at'),"error-messages":errors,"type":"datetime","text-field-options":{
          outlined: true,
          clearable: true,
          appendIcon: 'mdi-calendar',
        }},model:{value:(_vm.internalValue.publication_at),callback:function ($$v) {_vm.$set(_vm.internalValue, "publication_at", $$v)},expression:"internalValue.publication_at"}})]}}])})],1),(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-col',{attrs:{"md":"6"}}):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$$columnName('conference.schedule_st_at'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('datetime',{attrs:{"label":_vm.$$columnName('conference.schedule_st_at'),"error-messages":errors,"type":"datetime","text-field-options":{
          outlined: true,
          clearable: true,
          appendIcon: 'mdi-calendar',
        }},model:{value:(_vm.internalValue.schedule_st_at),callback:function ($$v) {_vm.$set(_vm.internalValue, "schedule_st_at", $$v)},expression:"internalValue.schedule_st_at"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$$columnName('conference.schedule_ed_at'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('datetime',{attrs:{"label":_vm.$$columnName('conference.schedule_ed_at'),"error-messages":errors,"type":"datetime","text-field-options":{
          outlined: true,
          clearable: true,
          appendIcon: 'mdi-calendar',
        }},model:{value:(_vm.internalValue.schedule_ed_at),callback:function ($$v) {_vm.$set(_vm.internalValue, "schedule_ed_at", $$v)},expression:"internalValue.schedule_ed_at"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-sheet',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.$$columnName('conference.image')))]),_c('file-pond-for-single',{attrs:{"accepted-file-types":"image/jpg,image/jpeg,image/png,image/webp"},model:{value:(_vm.internalValue.image),callback:function ($$v) {_vm.$set(_vm.internalValue, "image", $$v)},expression:"internalValue.image"}}),_c('v-sheet',{staticClass:"mb-2 red--text"},[_vm._v(_vm._s(_vm.$t('description.conference.image')))])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-sheet',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.$$columnName('conference.welcome_image')))]),_c('file-pond-for-single',{attrs:{"accepted-file-types":"image/jpg,image/jpeg,image/png,image/webp"},model:{value:(_vm.internalValue.welcome_image),callback:function ($$v) {_vm.$set(_vm.internalValue, "welcome_image", $$v)},expression:"internalValue.welcome_image"}}),_c('v-sheet',{staticClass:"mb-2 red--text"},[_vm._v(_vm._s(_vm.$t('description.conference.welcome_image')))])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }