<template>
  <v-dialog v-model="dialog" :max-width="400">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="{ ...attrs, ...btnProps }" v-on="on">
        {{ $t('button.destroy') }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        {{ $t('dialog.destroy.title') }}
      </v-card-title>
      <v-divider class="mb-4" />
      <v-card-text>
        {{ $t('dialog.destroy.message') }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="dialog = false">
          {{ $t('button.cancel') }}
        </v-btn>
        <v-btn color="error" @click="destroy">
          {{ $t('dialog.destroy.button') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    btnProps: { type: Object, default: () => ({}) },
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    destroy() {
      this.dialog = false
      this.$emit('destroy')
    },
  },
}
</script>
