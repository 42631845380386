<template>
  <v-form @submit.prevent="$emit('search')">
    <v-row>
      <v-col cols="12" md="3">
        <v-text-field
          v-model="internalValue.name_or_hira"
          :label="$t('search.loms.name_or_hira')"
          outlined
          dense
          hide-details
          clearable
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          v-model="internalValue.lom_code"
          :label="$$columnName('lom.lom_code')"
          outlined
          dense
          hide-details
          clearable
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-select
          v-model="internalValue.block"
          :label="$$columnName('lom.block')"
          :items="select.block"
          outlined
          dense
          hide-details
          clearable
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-btn type="submit" outlined>
          {{ $t('button.search') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
export default {
  props: {
    value: { type: Object, required: true },
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    select() {
      return {
        block: this.$$selectEnums('block'),
      }
    },
  },
}
</script>
