<template>
  <v-dialog v-model="dialog" max-width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="ml-2" color="error" v-bind="attrs" v-on="on">
        {{ $t('button.select_destroy') }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        {{ $t('dialog.destroy.title') }}
      </v-card-title>
      <v-divider class="mb-4" />
      <v-card-text>
        <v-sheet class="word-breaker">{{
          $t('dialog.jci_payments.select_destroy')
        }}</v-sheet>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="dialog = false">
          {{ $t('button.cancel') }}
        </v-btn>
        <v-btn class="error" @click="destroy">
          {{ $t('button.destroy') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    loading: { type: Boolean, required: true },
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    destroy() {
      this.dialog = false
      this.$emit('click:destroy')
    },
  },
}
</script>
