<template>
  <v-dialog v-model="dialog" :max-width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-chip v-bind="attrs" v-on="on" class="ma-1" pill>
        {{ manager.email }}
      </v-chip>
    </template>
    <v-card>
      <v-card-title></v-card-title>
      <v-card-text>
        <v-row class="mb-4">
          <v-col cols="12" align="center">
            <h3 class="font-weight-black">
              {{ manager.email }}
            </h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="right">
            <v-btn @click="dialog = false">
              {{ $t('button.close') }}
            </v-btn>
            <v-btn v-if="editable" class="ml-2" color="primary" @click="edit">
              {{ $t('button.edit') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    manager: { type: Object, required: true },
    editable: { type: Boolean, default: false },
  },
  data: () => ({
    dialog: false,
  }),
  computed: {},
  methods: {
    edit() {
      this.dialog = false
      this.$emit('click:edit', this.manager.id)
    },
  },
}
</script>
