<template>
  <area-loading v-if="loading" />
  <common-selected-conference-info
    v-else
    @click:index="$router.push({ name: 'user-conferences-index' })"
  />
</template>
<script>
import UsersApi from '@/api/users'
import AreaLoading from '@/components/areas/common/Loading'
import CommonSelectedConferenceInfo from '@/components/areas/common/SelectedConferenceInfo'
export default {
  mixins: [UsersApi],
  components: {
    AreaLoading,
    CommonSelectedConferenceInfo,
  },
  data: () => ({
    loading: false,
  }),
  created() {
    if (!this.$store.getters.conference.title) {
      this.setStoreConferenceInfo()
    }
  },
  methods: {
    setStoreConferenceInfo() {
      this.loading = true
      this.axios
        .get(this.API.conferences.show)
        .then((res) => {
          this.$store.dispatch('setConferenceInfo', res.data.conference)
        })
        .catch((err) => {
          if (err.response.status !== 422) return
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
