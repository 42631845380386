<template>
  <div>
    <page-title
      class="mb-4"
      @click:new="$router.push({ name: 'admin-managers-new' })"
    />
    <index-search class="mb-4" v-model="search" @search="getManagers" />
    <index-table
      class="mb-4"
      :managers="managers"
      :loading="loading"
      @click:row="clickRow"
    />
  </div>
</template>
<script>
import AdminApi from '@/api/admins'
import PageTitle from '@/components/areas/common/PageTitleWithNewButton'
import IndexSearch from './Index/Search'
import IndexTable from './Index/Table'
export default {
  mixins: [AdminApi],
  components: {
    PageTitle,
    IndexSearch,
    IndexTable,
  },
  data: () => ({
    loading: false,
    search: {},
    managers: [],
  }),
  created() {
    this.getManagers()
  },
  methods: {
    getManagers() {
      this.loading = true
      this.axios
        .get(this.API.managers.index, { params: this.search })
        .then((res) => {
          this.managers = res.data.managers
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
    clickRow(item) {
      this.$router.push({
        name: 'admin-managers-show',
        params: { managerId: item.id },
      })
    },
  },
  metaInfo() {
    return this.$t(`meta_tags.${this.$route.name}`)
  },
}
</script>
