<template>
  <v-sheet align="center">
    <v-card class="pa-4" :max-width="500">
      <v-icon class="mb-4" size="100"> mdi-alert-circle-outline </v-icon>
      <v-sheet class="mb-8 word-breaker">{{
        $t('description.account_stop')
      }}</v-sheet>
      <v-btn block x-large color="error" @click="logout">
        {{ $t('button.logout') }}
      </v-btn>
    </v-card>
  </v-sheet>
</template>
<script>
import UserApi from '@/api/users'
export default {
  mixins: [UserApi],
  methods: {
    logout() {
      this.axios
        .delete(this.API.auth.sign_out)
        .then(() => {
          this.$store.dispatch('logout')
        })
        .finally(() => {
          this.$router.push({ name: 'top' })
        })
    },
  },
  metaInfo() {
    return this.$t(`meta_tags.${this.$route.name}`)
  },
}
</script>
