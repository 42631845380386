<template>
  <div>
    <selected-conference-info />
    <page-title
      class="mb-4"
      @click:index="$router.push({ name: 'manager-teachers-index' })"
    />
    <page-detail
      :teacher="teacher"
      :loading="loading"
      @click:edit="
        $router.push({
          name: 'manager-teachers-edit',
          params: { teacherId: teacher.id },
        })
      "
      @click:destroy="destroy"
    />
  </div>
</template>
<script>
import ManagerApi from '@/api/managers'
import SelectedConferenceInfo from '@/components/areas/managers/Card/SelectedConferenceInfo'
import PageTitle from '@/components/areas/common/PageTitleWithIndexButton'
import PageDetail from './Show/Detail'
export default {
  mixins: [ManagerApi],
  components: {
    SelectedConferenceInfo,
    PageTitle,
    PageDetail,
  },
  data: () => ({
    loading: false,
    teacher: {
      id: '',
      name: '',
      name_hira: '',
      desc: '',
      image: {},
    },
  }),
  created() {
    this.getTeacher()
  },
  methods: {
    getTeacher() {
      this.loading = true
      this.axios
        .get(this.API.teachers.show)
        .then((res) => {
          this.teacher = res.data.teacher
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
    destroy() {
      this.loading = true
      this.axios
        .delete(this.API.teachers.destroy)
        .then(() => {
          this.$router.push({ name: 'manager-teachers-index' })
          this.$toasted.success(this.$t('success.destroy'))
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  metaInfo() {
    return this.$t(`meta_tags.${this.$route.name}`)
  },
}
</script>
