<template>
  <v-card>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="managers"
      disable-sort
      @click:row="clickRow"
    >
      <template #[`item.created_at`]="{ item }">
        {{ item.created_at | momentFormat('lll') }}
      </template>
      <template #[`item.is_lom_representative`]="{ item }">
        {{ item.is_lom_representative ? item.is_lom_representative : '' }}
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
export default {
  props: {
    loading: { type: Boolean, required: true },
    managers: { type: Array, required: true },
  },
  data: () => ({
    headers: [],
  }),
  created() {
    this.headers = [
      { text: this.$$columnName('manager.email'), value: 'email' },
      { text: this.$$columnName('manager.created_at'), value: 'created_at' },
      {
        text: this.$$columnName('manager.is_lom_representative'),
        value: 'is_lom_representative',
      },
    ]
  },
  methods: {
    clickRow(item) {
      this.$emit('click:row', item)
    },
  },
}
</script>
