<template>
  <v-card>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="audiencesCounts"
      disable-sort
      :server-items-length="totalCount"
      :page="tableOptions.page"
      :items-per-page="tableOptions.per"
      @click:row="$emit('click:row', $event)"
      @update:options="$emit('update:options', $event)"
    >
      <template #[`item.user.profile.user_type`]="{ item }">
        {{ $$getEnumText('user_type', item.user.profile.user_type) }}
      </template>
      <template #[`item.created_at`]="{ item }">
        {{ item.created_at | momentFormat('lll') }}
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
export default {
  props: {
    loading: { type: Boolean, required: true },
    audiencesCounts: { type: Array, required: true },
    totalCount: { type: Number, required: true },
    tableOptions: { type: Object, required: true },
  },
  data: () => ({
    headers: [],
  }),
  created() {
    this.headers = [
      {
        text: this.$$columnName('user/profile.name'),
        value: 'user.profile.name',
      },
      {
        text: this.$$columnName('user/profile.organization'),
        value: 'user.profile.organization',
      },
      {
        text: this.$$columnName('user/profile.user_type'),
        value: 'user.profile.user_type',
      },
      { text: this.$$columnName('lom.name'), value: 'user.profile.lom.name' },
      {
        text: this.$$columnName('audiences_count.created_at'),
        value: 'created_at',
      },
    ]
  },
}
</script>
