<template>
  <v-dialog v-model="dialog" :max-width="400">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="{ ...attrs, ...btnProps }" v-on="on">
        {{ display.button }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        {{ display.dialog.title }}
      </v-card-title>
      <v-divider class="mb-4" />
      <v-card-text>
        <v-sheet class="word-breaker">{{ display.dialog.message }}</v-sheet>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="dialog = false">
          {{ $t('button.cancel') }}
        </v-btn>
        <v-btn :color="btnProps.color" @click="save">
          {{ display.dialog.button }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    dialogType: { type: String, required: true },
    btnText: { type: String, default: '' },
    btnProps: { type: Object, default: () => ({}) },
  },
  data: () => ({
    dialog: false,
  }),
  computed: {
    display() {
      const localeMessages = this.$t(`dialog.${this.dialogType}`)
      return {
        button: this.btnText || localeMessages.button,
        dialog: {
          title: localeMessages.title,
          message: localeMessages.message,
          button: localeMessages.button,
        },
      }
    },
  },
  methods: {
    save() {
      this.dialog = false
      this.$emit('click:save')
    },
  },
}
</script>
