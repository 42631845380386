<template>
  <v-card :max-width="500">
    <v-simple-table class="mouse-over-highlight-disabled">
      <tbody>
        <tr>
          <td>{{ $$columnName('teacher.name') }}</td>
          <td>{{ teacher.name }}</td>
        </tr>
        <tr>
          <td>{{ $$columnName('teacher.name_hira') }}</td>
          <td>{{ teacher.name_hira }}</td>
        </tr>
        <tr>
          <td>{{ $$columnName('teacher.desc') }}</td>
          <td>
            <div class="word-breaker py-2" v-html="linkedDesc" />
          </td>
        </tr>
        <tr>
          <td>{{ $$columnName('teacher.image') }}</td>
          <td>
            <v-card v-if="$$exist(teacher.image)" class="my-4" :max-width="300">
              <v-img :src="teacher.image.url" />
            </v-card>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-divider class="mb-4" />
    <v-card-actions>
      <v-row>
        <v-col cols="12" md="12" align="right">
          <btn-dialog-destroy
            :btn-props="{ class: 'mr-2', color: 'error' }"
            @destroy="$emit('click:destroy')"
          />
          <v-btn color="primary" @click="$emit('click:edit')">
            {{ $t('button.edit') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>
<script>
import Autolinker from 'autolinker'
import BtnDialogDestroy from '@/components/parts/Btn/Dialog/Destroy'
export default {
  components: {
    BtnDialogDestroy,
  },
  props: {
    teacher: { type: Object, required: true },
    loading: { type: Boolean, required: true },
  },
  computed: {
    linkedDesc() {
      return Autolinker.link(this.teacher.desc, {
        sanitizeHtml: true,
        stripPrefix: false,
      })
    },
  },
}
</script>
