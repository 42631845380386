import Vue from 'vue'
import axios from 'axios'
import router from '@/router'
import store from '@/stores'
import i18n from '@/plugins/vue-i18n'
import VueToasted from '@/plugins/vue-toasted'
import VueCookies from 'vue-cookies'

Vue.use(VueToasted)

const http = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
})

http.interceptors.request.use(
  (request) => {
    const authInfos = store.getters.auth

    if (authInfos) {
      request.headers[request.method] = {
        'access-token': authInfos['access-token'],
        client: authInfos['client'],
        uid: authInfos['uid'],
      }
    }
    return request
  },
  (err) => {
    return Promise.reject(err)
  }
)

http.interceptors.response.use(
  (response) => {
    setAuthInfos(response)
    return response
  },
  (err) => {
    setAuthInfos(err.response)
    switch (err.response.status) {
      case 401: {
        const currentPath = location.pathname
        const userLoginPath = router.resolve({ name: 'user-login' }).href
        const userLoginJciPath = router.resolve({ name: 'user-login-jci' }).href
        const managerLoginPath = router.resolve({ name: 'manager-login' }).href
        const adminLoginPath = router.resolve({ name: 'admin-login' }).href
        const isUserLoginPage = userLoginPath === currentPath
        const isUserLoginJciPage = userLoginJciPath === currentPath
        const isManagerLoginPage = managerLoginPath === currentPath
        const isAdminLoginPage = adminLoginPath === currentPath
        if (
          isUserLoginPage ||
          isUserLoginJciPage ||
          isManagerLoginPage ||
          isAdminLoginPage
        ) {
          Vue.toasted.error(i18n.tc('errors.invalid.login'))
        } else {
          store.dispatch('logout')
          if (store.getters.user) {
            router.push({ name: 'top' })
          } else if (store.getters.manager) {
            router.push({ name: 'manager-login' })
          } else if (store.getters.admin) {
            router.push({ name: 'admin-login' })
          } else {
            router.push({ name: 'top' })
          }
          Vue.toasted.error(i18n.tc('errors.must.login'))
        }
        break
      }
      case 403:
        if (err.response.data === 'stop') {
          router.push({ name: 'user-accounts-stop' }).catch(() => {})
        } else if (err.response.data === 'invalid') {
          router.push({ name: 'user-accounts-invalid' }).catch(() => {})
        } else if (err.response.data.publication_at) {
        } else if (store.getters.manager) {
          Vue.toasted.error(i18n.tc('errors.syntax'))
          router.push({ name: 'manager-login' })
        } else if (store.getters.admin) {
          Vue.toasted.error(i18n.tc('errors.syntax'))
          router.push({ name: 'admin-login' })
        } else {
          Vue.toasted.error(i18n.tc('errors.syntax'))
          router.push({ name: 'top' })
        }
        break
      case 404:
        Vue.toasted.error(i18n.tc('errors.notfound'))
        break
      case 422:
        break
      default:
        Vue.toasted.error(i18n.tc('errors.syntax'))
        break
    }
    return Promise.reject(err)
  }
)

function setAuthInfos(response) {
  if (response.headers['access-token']) {
    const authInfos = VueCookies.get('authInfos') || {}
    const tokens = {
      tokens: {
        'access-token': response.headers['access-token'],
        client: response.headers['client'],
        uid: response.headers['uid'],
      },
    }
    const newAuthInfos = Object.assign(authInfos, tokens)
    store.dispatch('setAuth', newAuthInfos.tokens)
    VueCookies.set('authInfos', newAuthInfos)
  }
}

export default http
