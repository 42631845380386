var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('label.multi_update')))]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.$t('unit.people', { number: _vm.usersCount }))+" "+_vm._s(_vm.$t('label.selecting'))+" ")]),_c('v-card-text',[_c('btn-dialog-simple',{attrs:{"dialog-type":_vm.account_ban_or_invalid,"btn-props":{
        class: 'mr-2',
        color: 'error',
        loading: _vm.loading,
        disabled: _vm.disableButton,
      }},on:{"click:save":_vm.ban}}),_c('btn-dialog-simple',{attrs:{"dialog-type":"account_active","btn-props":{
        color: 'primary',
        loading: _vm.loading,
        disabled: _vm.disableButton,
      }},on:{"click:save":_vm.active}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }