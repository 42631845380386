import Vue from 'vue'
import VueRouter from 'vue-router'
import VueCookies from 'vue-cookies'
import Top from '@/views/Top'
import Credit from '@/views/Credit'
import NotFound from '@/views/error/NotFound'
import Admin from '@/router/admin.js'
import Manager from '@/router/manager.js'
import User from '@/router/user.js'

Vue.use(VueRouter)

function addAccountType(accountRourts, isType) {
  accountRourts.forEach((accountRoute) => {
    if (!accountRoute.meta) {
      accountRoute.meta = {}
    }
    accountRoute.meta[isType] = true
  })
}
addAccountType(Admin, 'isAdmin')
addAccountType(Manager, 'isManager')
addAccountType(User, 'isUser')

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'top',
      component: Top,
      meta: { isPublic: true, hideToUser: true },
    },
    {
      path: '/credit',
      name: 'credit',
      component: Credit,
      meta: { isPublic: true },
    },
    ...Admin,
    ...Manager,
    ...User,
    {
      path: '*',
      name: 'not-found',
      component: NotFound,
      meta: { isPublic: true },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

router.beforeEach((to, from, next) => {
  const authInfos = VueCookies.get('authInfos')
  // account status
  const isGuest = !authInfos
  const isAdmin = authInfos && authInfos.admin
  const isManager = authInfos && authInfos.manager
  const isUser = authInfos && authInfos.user
  const isJciMember = isUser && authInfos.user.provider === 'jci_member_app'
  const isNotJciMember = isUser && authInfos.user.provider !== 'jci_member_app'
  // whose page
  const isPublicPage = to.matched.some((record) => record.meta.isPublic)
  const isPageHideToUser = to.matched.some((record) => record.meta.hideToUser)
  const isAdminPage =
    to.matched.some((record) => record.meta.isAdmin) && !isPublicPage
  const isManagerPage =
    to.matched.some((record) => record.meta.isManager) && !isPublicPage
  const isUserPage =
    to.matched.some((record) => record.meta.isUser) && !isPublicPage
  // access controll for jci_member
  const isPasswordSettingsPage = to.matched.some(
    (record) => record.name === 'user-settings-password'
  )
  const isSignupPage = to.matched.some(
    (record) => record.name === 'user-signup'
  )

  if (isGuest && !isPublicPage) {
    next({ name: 'top' })
  } else if (!isAdmin && isAdminPage) {
    next({ name: 'not-found' })
  } else if (!isManager && isManagerPage) {
    next({ name: 'not-found' })
  } else if (!isUser && isUserPage) {
    next({ name: 'not-found' })
  } else if (isJciMember && isPasswordSettingsPage) {
    next({ name: 'not-found' })
  } else if (isNotJciMember && isSignupPage) {
    next({
      name: 'user-conference-signup-redirect',
      params: { conferenceId: to.query.cid },
    })
  } else if (isUser && isPageHideToUser) {
    next({ name: 'user-conferences-index' })
  } else {
    next()
  }
})

export default router
