<template>
  <v-row justify="center">
    <v-col cols="12" md="6">
      <v-card class="py-4 mb-4">
        <v-card-title class="mb-8">
          <v-row>
            <v-col align="center">
              <h2>{{ $t(`meta_tags.${this.$route.name}.title`) }}</h2>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-sheet align="center" class="mb-12">
            <div class="font-weight-black">
              {{ $t('label.new_password.desc') }}
            </div>
          </v-sheet>
          <form-with-validation-block
            :loading="loading"
            :button-text="$t('button.update')"
            @submit="save"
          >
            <entry-form v-model="password" />
          </form-with-validation-block>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import ManagerApi from '@/api/managers'
import FormWithValidationBlock from '@/components/areas/common/FormWithValidationBlock'
import EntryForm from '@/components/areas/PasswordReset/EntryForm'
export default {
  mixins: [ManagerApi],
  components: {
    FormWithValidationBlock,
    EntryForm,
  },
  data: () => ({
    loading: false,
    password: {
      password: '',
      password_confirmation: '',
    },
  }),
  methods: {
    save() {
      this.loading = true
      const headers = {
        'access-token': this.$route.query.token,
        client: this.$route.query.client,
        uid: this.$route.query.uid,
      }
      this.axios
        .patch(this.API.password.update, this.password, { headers })
        .then(() => {
          this.$toasted.success(this.$t('success.new_password'))
          this.$router.push({ name: 'manager-login' })
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  metaInfo() {
    return this.$t(`meta_tags.${this.$route.name}`)
  },
}
</script>
