<template>
  <div>
    <page-title
      class="mb-4"
      @click:index="$router.push({ name: 'admin-admins-index' })"
    />
    <page-detail
      :admin="admin"
      :loading="loading"
      @click:edit="
        $router.push({
          name: 'admin-admins-edit',
          params: { adminId: admin.id },
        })
      "
      @click:destroy="destroy"
    />
  </div>
</template>
<script>
import AdminApi from '@/api/admins'
import PageTitle from '@/components/areas/common/PageTitleWithIndexButton'
import PageDetail from './Show/Detail'
export default {
  mixins: [AdminApi],
  components: {
    PageTitle,
    PageDetail,
  },
  data: () => ({
    loading: false,
    admin: {
      id: '',
      name: '',
      email: '',
    },
  }),
  created() {
    this.getAdmin()
  },
  methods: {
    getAdmin() {
      this.loading = true
      this.axios
        .get(this.API.admins.show)
        .then((res) => {
          this.admin = res.data.admin
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
    destroy() {
      this.loading = true
      this.axios
        .delete(this.API.admins.destroy)
        .then(() => {
          this.$router.push({ name: 'admin-admins-index' })
          this.$toasted.success(this.$t('success.destroy'))
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  metaInfo() {
    return this.$t(`meta_tags.${this.$route.name}`)
  },
}
</script>
