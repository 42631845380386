<template>
  <form-with-validation
    :loading="loading"
    cancelable
    @click:save="$emit('click:save')"
    @cancel="$emit('click:cancel')"
  >
    <validation-provider
      v-slot="{ errors }"
      :name="$$columnName('jci_payment.name')"
      rules="required|max:30"
    >
      <v-text-field
        class="mx-6"
        v-model="internalValue.name"
        :label="$$columnName('jci_payment.name')"
        :error-messages="errors"
        outlined
        dense
      />
    </validation-provider>
    <validation-provider
      v-slot="{ errors }"
      :name="$$columnName('jci_payment.member_number')"
      rules="required|integer|length:9"
    >
      <v-text-field
        class="mx-6"
        v-model="internalValue.member_number"
        :label="$$columnName('jci_payment.member_number')"
        :error-messages="errors"
        outlined
        dense
      />
    </validation-provider>
  </form-with-validation>
</template>
<script>
import FormWithValidation from '@/components/areas/common/FormWithValidation'
export default {
  components: {
    FormWithValidation,
  },
  props: {
    value: { type: Object, required: true },
    loading: { type: Boolean, required: true },
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
