<template>
  <v-list nav expand>
    <v-list-item
      link
      v-for="list in $store.getters.manager.is_lom_representative
        ? filteredDrawerListsLomRepresentative
        : filteredDrawerLists"
      :key="list.title"
      :to="list.url"
      :exact="list.exact"
    >
      <v-list-item-action>
        <v-icon>{{ list.icon }}</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>{{ list.title }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>
<script>
export default {
  data: () => ({
    drawerLists: [
      {
        title: '会議選択',
        icon: 'mdi-package-variant-closed',
        url: { name: 'manager-conferences-index' },
        exact: true,
      },
      {
        title: '選択中の会議',
        icon: 'mdi-package-variant-closed-check',
        url: { name: 'manager-conferences-show' },
        exact: true,
      },
      {
        title: '会場',
        icon: 'mdi-map-marker',
        url: { name: 'manager-venues-index' },
      },
      {
        title: '講演者',
        icon: 'mdi-account-tie',
        url: { name: 'manager-teachers-index' },
      },
      {
        title: 'プログラム',
        icon: 'mdi-calendar-month',
        url: { name: 'manager-programs-index' },
      },
      {
        title: 'ユーザー',
        icon: 'mdi-account-group',
        url: { name: 'manager-users-index' },
      },
      {
        title: 'URL/QRコード発行',
        icon: 'mdi-qrcode',
        url: { name: 'manager-qrcodes-index' },
      },
      {
        title: 'パスワード変更',
        icon: 'mdi-cog-outline',
        url: { name: 'manager-settings-password' },
      },
      {
        title: '支払い済み正会員リスト',
        icon: 'mdi-application-import',
        url: { name: 'manager-jci_payments-index' },
      },
      {
        title: 'クレジット',
        icon: 'mdi-file-code',
        url: { name: 'credit' },
      },
    ],
  }),
  computed: {
    filteredDrawerLists() {
      const filterUrlName = [
        'manager-conferences-index',
        'manager-settings-password',
        'credit',
      ]

      if (this.$store.getters.conference.id) return this.drawerLists

      return this.filterDrawerLists(filterUrlName)
    },
    filteredDrawerListsLomRepresentative() {
      const filterUrlName = ['manager-conferences-index', 'credit']
      const filterUrlNameSelectedConference = [
        'manager-conferences-index',
        'manager-jci_payments-index',
        'credit',
      ]
      if (this.$store.getters.conference.id)
        return this.filterDrawerLists(filterUrlNameSelectedConference)

      return this.filterDrawerLists(filterUrlName)
    },
  },
  methods: {
    filterDrawerLists(filterUrlName) {
      return this.drawerLists.filter((row) =>
        filterUrlName.includes(row.url.name)
      )
    },
  },
}
</script>
