<template>
  <div>
    <page-title
      class="mb-4"
      @click:new="$router.push({ name: 'admin-conferences-new' })"
    />
    <index-search
      class="mb-4"
      v-model="search"
      @click:search="getConferences"
    />
    <index-table
      class="mb-4"
      :conferences="conferences"
      :loading="loading"
      @click:row="clickRow"
    />
  </div>
</template>
<script>
import AdminApi from '@/api/admins'
import PageTitle from '@/components/areas/common/PageTitleWithNewButton'
import IndexSearch from '@/components/areas/conferences/Index/Search'
import IndexTable from '@/components/areas/conferences/Index/Table'
export default {
  mixins: [AdminApi],
  components: {
    PageTitle,
    IndexSearch,
    IndexTable,
  },
  data: () => ({
    loading: false,
    search: {
      title: '',
      email: '',
    },
    conferences: [],
  }),
  created() {
    this.getConferences()
  },
  methods: {
    getConferences() {
      this.loading = true
      const params = { ...this.search }
      this.axios
        .get(this.API.conferences.index, { params })
        .then((res) => {
          this.conferences = res.data.conferences
        })
        .catch((err) => {
          if (err.response.status !== 422) return
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
    clickRow(item) {
      this.$router.push({
        name: 'admin-conferences-show',
        params: { conferenceId: item.id },
      })
    },
  },
  metaInfo() {
    return this.$t(`meta_tags.${this.$route.name}`)
  },
}
</script>
