<template>
  <v-card>
    <v-simple-table class="mouse-over-highlight-disabled">
      <tbody>
        <tr>
          <td>{{ $$columnName('conference.title') }}</td>
          <td>{{ conference.title }}</td>
        </tr>
        <tr>
          <td>{{ $$columnName('conference.desc') }}</td>
          <td>
            <div class="word-breaker py-2" v-html="linkedDesc" />
          </td>
        </tr>
        <tr v-if="!hideManagers">
          <td>{{ $$tableName('manager') }}</td>
          <td>
            <chip-dialog-manager
              v-for="manager in conference.managers"
              :key="manager.id"
              :manager="manager"
              :editable="managerEditable"
              @click:edit="clickEdit"
            />
          </td>
        </tr>
        <tr>
          <td>{{ $$columnName('conference.email') }}</td>
          <td>{{ conference.email }}</td>
        </tr>
        <tr>
          <td>{{ $$columnName('conference.publication_at') }}</td>
          <td>{{ conference.publication_at | momentFormat('lll') }}</td>
        </tr>
        <tr>
          <td>{{ $$columnName('conference.schedule_st_at') }}</td>
          <td>{{ conference.schedule_st_at | momentFormat('lll') }}</td>
        </tr>
        <tr>
          <td>{{ $$columnName('conference.schedule_ed_at') }}</td>
          <td>{{ conference.schedule_ed_at | momentFormat('lll') }}</td>
        </tr>
        <tr>
          <td>{{ $$columnName('conference.image') }}</td>
          <td>
            <v-card
              v-if="$$exist(conference.image)"
              class="my-4"
              :max-width="200"
            >
              <v-img :src="conference.image.url" />
            </v-card>
          </td>
        </tr>
        <tr>
          <td>{{ $$columnName('conference.welcome_image') }}</td>
          <td>
            <v-card
              v-if="$$exist(conference.welcome_image)"
              class="my-4"
              :max-width="200"
            >
              <v-img :src="conference.welcome_image.url" />
            </v-card>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <template v-if="actionable">
      <v-divider class="mb-4" />
      <v-card-actions>
        <v-row>
          <v-col cols="12" md="12" align="right">
            <btn-dialog-destroy
              v-if="destroyable"
              :btn-props="{ class: 'mr-2', color: 'error' }"
              @destroy="$emit('click:destroy')"
            />
            <v-btn v-if="editable" color="primary" @click="$emit('click:edit')">
              {{ $t('button.edit') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </template>
  </v-card>
</template>
<script>
import Autolinker from 'autolinker'
import ChipDialogManager from '@/components/parts/Chip/Dialog/Manager'
import BtnDialogDestroy from '@/components/parts/Btn/Dialog/Destroy'
export default {
  components: {
    ChipDialogManager,
    BtnDialogDestroy,
  },
  props: {
    conference: { type: Object, required: true },
    loading: { type: Boolean, required: true },
    editable: { type: Boolean, default: false },
    managerEditable: { type: Boolean, default: false },
    destroyable: { type: Boolean, default: false },
    hideManagers: { type: Boolean, default: false },
  },
  computed: {
    linkedDesc() {
      return Autolinker.link(this.conference.desc, {
        sanitizeHtml: true,
        stripPrefix: false,
      })
    },
    actionable() {
      return this.editable || this.destroyable
    },
  },
  methods: {
    clickEdit(managerId) {
      this.$emit('edit:manager', managerId)
    },
  },
}
</script>
