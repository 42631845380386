var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$$columnName('manager.email'),"rules":"required|max:256|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$$columnName('manager.email'),"error-messages":errors,"outlined":""},model:{value:(_vm.internalValue.email),callback:function ($$v) {_vm.$set(_vm.internalValue, "email", $$v)},expression:"internalValue.email"}})]}}])})],1),(_vm.isNewRecord)?_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$$columnName('manager.password'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-field-password',{attrs:{"text-field-options":{
          label: _vm.$$columnName('manager.password'),
          errorMessages: errors,
          outlined: true,
        }},model:{value:(_vm.internalValue.password),callback:function ($$v) {_vm.$set(_vm.internalValue, "password", $$v)},expression:"internalValue.password"}})]}}],null,false,1586528825)})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-switch',{attrs:{"label":_vm.$$columnName('manager.is_lom_representative')},model:{value:(_vm.internalValue.is_lom_representative),callback:function ($$v) {_vm.$set(_vm.internalValue, "is_lom_representative", $$v)},expression:"internalValue.is_lom_representative"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }