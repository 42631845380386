<template>
  <v-sheet align="center">
    <v-card class="pa-4" :max-width="500">
      <v-icon class="mb-4" size="100"> mdi-alert-circle-outline </v-icon>
      <v-sheet class="word-breaker">{{
        $t('description.account_invalid')
      }}</v-sheet>
      <v-sheet class="mb-8">
        <a v-if="email" :href="email">
          {{ email }}
        </a>
      </v-sheet>
      <v-btn
        block
        x-large
        color="error"
        @click="$router.push({ name: 'user-conferences-index' })"
      >
        {{ $t('button.to_conferences') }}
      </v-btn>
    </v-card>
  </v-sheet>
</template>
<script>
import UserApi from '@/api/users'
export default {
  mixins: [UserApi],
  data: () => ({
    email: '',
  }),
  created() {
    this.getConference()
  },
  methods: {
    getConference() {
      this.axios.get(this.API.conferences.show).then((res) => {
        this.email = res.data.conference.email
      })
    },
  },
  metaInfo() {
    return this.$t(`meta_tags.${this.$route.name}`)
  },
}
</script>
