<template>
  <v-app-bar app flat dark class="jci-background-color">
    <v-app-bar-nav-icon @click.stop="internalValue = !internalValue" />
    <v-toolbar-title></v-toolbar-title>

    <v-spacer />

    <AdminHeaderMenu v-if="displayAdminHeader" />
    <ManagerHeaderMenu v-if="displayManagerHeader" />
    <UserHeaderMenu v-if="displayUserHeader" />
  </v-app-bar>
</template>

<script>
import AdminHeaderMenu from '@/components/globals/admin/HeaderMenu'
import ManagerHeaderMenu from '@/components/globals/manager/HeaderMenu'
import UserHeaderMenu from '@/components/globals/user/HeaderMenu'
export default {
  components: {
    AdminHeaderMenu,
    ManagerHeaderMenu,
    UserHeaderMenu,
  },
  props: {
    value: { required: true },
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    displayAdminHeader() {
      return this.$store.getters.admin
    },
    displayManagerHeader() {
      return this.$store.getters.manager
    },
    displayUserHeader() {
      return this.$store.getters.user
    },
  },
}
</script>
