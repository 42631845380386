<template>
  <v-list nav expand>
    <v-list-item
      link
      v-for="list in drawerLists"
      :key="list.text"
      :to="list.to"
    >
      <v-list-item-action>
        <v-icon>{{ list.icon }}</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>{{ list.text }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>
<script>
export default {
  data: () => ({
    drawerLists: [
      {
        text: 'Admin',
        icon: 'mdi-account-cowboy-hat',
        to: { name: 'admin-admins-index' },
      },
      {
        text: 'Manager',
        icon: 'mdi-account-hard-hat',
        to: { name: 'admin-managers-index' },
      },
      { text: 'User', icon: 'mdi-account', to: { name: 'admin-users-index' } },
      {
        text: 'Conference',
        icon: 'mdi-package-variant-closed',
        to: { name: 'admin-conferences-index' },
      },
      { text: 'Lom', icon: 'mdi-map-marker', to: { name: 'admin-loms-index' } },
      {
        text: 'Password',
        icon: 'mdi-lock',
        to: { name: 'admin-settings-password' },
      },
    ],
  }),
}
</script>
