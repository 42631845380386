import {
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
  localize,
  extend,
} from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import ja from 'vee-validate/dist/locale/ja.json'

const VeeValidate = {
  install(Vue) {
    Vue.component('ValidationProvider', ValidationProvider)
    Vue.component('ValidationObserver', ValidationObserver)

    setInteractionMode('eager')
    localize('ja', ja)

    Object.keys(rules).forEach((rule) => {
      extend(rule, rules[rule])
    })

    // ----------------------------------
    // 全角ひらがな
    // ----------------------------------
    extend('zenHira', {
      message: '{_field_}はひらがなで入力してください',
      validate(value) {
        return value.match(/^[ぁ-んー－\s]+$/)
      },
    })

    // ----------------------------------
    // URL
    // ----------------------------------
    extend('url', {
      message: '{_field_}を正しく入力してください',
      validate(value) {
        if (value) {
          return value.match(/^(https?)(:\/\/[-_.!~*'()\w;/?:@&=+$,%#]+)$/)
        }
      },
    })

    extend('vimeo_html_tag', {
      message:
        '{_field_}にはVimeoのイベント埋め込みコードをそのまま入力してください',
      validate(value) {
        return value.match(
          /^.*<iframe src="https?:\/\/.*vimeo.com.*><\/iframe>.*$/
        )
      },
    })

    extend('archivePeriod', {
      params: ['target'],
      validate(value, { target }) {
        return value != null && target != null
      },
      message: 'アーカイブ期間は開始・終了日時をセットで入力してください',
    })
  },
}

export default VeeValidate
