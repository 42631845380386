<template>
  <div>
    <selected-conference-info />
    <page-title
      class="mb-4"
      @click:new="$router.push({ name: 'manager-teachers-new' })"
    />
    <index-search class="mb-4" v-model="search" @search="pushQueryForSearch" />
    <index-table
      class="mb-4"
      :teachers="teachers"
      :loading="loading"
      @click:row="clickRow"
    />
  </div>
</template>
<script>
import ManagerApi from '@/api/managers'
import SelectedConferenceInfo from '@/components/areas/managers/Card/SelectedConferenceInfo'
import PageTitle from '@/components/areas/common/PageTitleWithNewButton'
import IndexSearch from './Index/Search'
import IndexTable from './Index/Table'
export default {
  mixins: [ManagerApi],
  components: {
    SelectedConferenceInfo,
    PageTitle,
    IndexSearch,
    IndexTable,
  },
  data: () => ({
    loading: false,
    search: {},
    teachers: [],
  }),
  computed: {
    queryParams() {
      return { ...this.$route.query }
    },
  },
  beforeRouteUpdate(to, from, next) {
    next()
    this.initSearch()
    this.getTeachers()
  },
  created() {
    this.initSearch()
    this.getTeachers()
  },
  methods: {
    initSearch() {
      this.search = this.queryParams
    },
    pushQueryForSearch() {
      const query = this.$$removeBlankValue(this.search)
      this.$router.push({ query }, () => {})
    },
    getTeachers() {
      this.loading = true
      this.axios
        .get(this.API.teachers.index, { params: this.queryParams })
        .then((res) => {
          this.teachers = res.data.teachers
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
    clickRow({ id }) {
      this.$router.push({
        name: 'manager-teachers-show',
        params: { teacherId: id },
      })
    },
  },
  metaInfo() {
    return this.$t(`meta_tags.${this.$route.name}`)
  },
}
</script>
