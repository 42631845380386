<template>
  <v-row>
    <v-col cols="12">
      <validation-provider
        v-slot="{ errors }"
        :name="$$columnName('user.email')"
        rules="required|max:256"
      >
        <v-text-field
          v-model="internalValue.email"
          :label="$$columnName('user.email')"
          :error-messages="errors"
          outlined
        />
      </validation-provider>
    </v-col>
    <template v-if="isNewRecord">
      <v-col cols="12">
        <validation-provider
          v-slot="{ errors }"
          vid="password"
          :name="$$columnName('user.password')"
          rules="required|min:8|max:256"
        >
          <text-field-password
            v-model="internalValue.password"
            :text-field-options="{
              label: $$columnName('user.password'),
              errorMessages: errors,
              outlined: true,
            }"
          />
        </validation-provider>
      </v-col>
      <v-col cols="12">
        <validation-provider
          v-slot="{ errors }"
          :name="$$columnName('user.password_confirmation')"
          rules="required|min:8|max:256|confirmed:password"
        >
          <text-field-password
            v-model="internalValue.password_confirmation"
            :text-field-options="{
              label: $$columnName('user.password_confirmation'),
              errorMessages: errors,
              outlined: true,
            }"
          />
        </validation-provider>
      </v-col>
    </template>
  </v-row>
</template>
<script>
import TextFieldPassword from '@/components/parts/TextField/Password'
export default {
  components: {
    TextFieldPassword,
  },
  props: {
    value: { type: Object, required: true },
    isNewRecord: { type: Boolean, required: true },
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
