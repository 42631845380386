export default {
  computed: {
    API() {
      return this.APIwithId()
    },
  },
  methods: {
    APIwithId(ids = {}) {
      const venueId = ids.venueId ? ids.venueId : this.$route.params.venueId
      const teacherId = ids.teacherId
        ? ids.teacherId
        : this.$route.params.teacherId
      const programId = ids.programId
        ? ids.programId
        : this.$route.params.programId
      const conferenceId = ids.conferenceId
        ? ids.conferenceId
        : this.$store.getters.conference.id
      const userId = ids.userId ? ids.userId : this.$route.params.userId
      return {
        auth: {
          sign_in: '/api/v1/manager/auth/sign_in',
          sign_out: '/api/v1/manager/auth/sign_out',
        },
        password: {
          create: '/api/v1/manager/auth/password',
          update: '/api/v1/manager/auth/password',
        },
        conferences: {
          index: '/api/v1/manager/conferences',
          show: `/api/v1/manager/conferences/${conferenceId}`,
          create: '/api/v1/manager/conferences',
          update: `/api/v1/manager/conferences/${conferenceId}`,
        },
        teachers: {
          index: `/api/v1/manager/conferences/${conferenceId}/teachers`,
          show: `/api/v1/manager/conferences/${conferenceId}/teachers/${teacherId}`,
          create: `/api/v1/manager/conferences/${conferenceId}/teachers`,
          update: `/api/v1/manager/conferences/${conferenceId}/teachers/${teacherId}`,
          destroy: `/api/v1/manager/conferences/${conferenceId}/teachers/${teacherId}`,
        },
        loms: {
          index: '/api/v1/manager/loms',
        },
        venues: {
          index: `/api/v1/manager/conferences/${conferenceId}/venues`,
          show: `/api/v1/manager/conferences/${conferenceId}/venues/${venueId}`,
          create: `/api/v1/manager/conferences/${conferenceId}/venues`,
          update: `/api/v1/manager/conferences/${conferenceId}/venues/${venueId}`,
          destroy: `/api/v1/manager/conferences/${conferenceId}/venues/${venueId}`,
        },
        programs: {
          index: `/api/v1/manager/conferences/${conferenceId}/programs`,
          show: `/api/v1/manager/conferences/${conferenceId}/programs/${programId}`,
          create: `/api/v1/manager/conferences/${conferenceId}/programs`,
          update: `/api/v1/manager/conferences/${conferenceId}/programs/${programId}`,
          destroy: `/api/v1/manager/conferences/${conferenceId}/programs/${programId}`,
        },
        audiencesCounts: {
          index: `/api/v1/manager/conferences/${conferenceId}/programs/${programId}/audiences_counts`,
          output_csv: `/api/v1/manager/conferences/${conferenceId}/programs/${programId}/audiences_counts/output_csv`,
        },
        users: {
          index: `/api/v1/manager/conferences/${conferenceId}/users`,
          show: `/api/v1/manager/conferences/${conferenceId}/users/${userId}`,
          active: `/api/v1/manager/conferences/${conferenceId}/users/conference_account_status`,
          ban: `/api/v1/manager/conferences/${conferenceId}/users/conference_account_status`,
          output_csv: `/api/v1/manager/conferences/${conferenceId}/users/output_csv`,
        },
        jci_payments: {
          index: `/api/v1/manager/conferences/${conferenceId}/jci_payments`,
          create: `/api/v1/manager/conferences/${conferenceId}/jci_payments`,
          select_destroy: `/api/v1/manager/conferences/${conferenceId}/jci_payments/select_destroy`,
          import_upload_files: `/api/v1/manager/conferences/${conferenceId}/jci_payments/import_upload_files`,
        },
      }
    },
  },
}
