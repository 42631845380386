<template>
  <div>
    <selected-conference-info />
    <page-title
      class="mb-4"
      @click:index="$router.push({ name: 'manager-users-index' })"
    />
    <page-detail is-manager :user="user" :loading="loading" hide-account-type />
  </div>
</template>
<script>
import ManagerApi from '@/api/managers'
import SelectedConferenceInfo from '@/components/areas/managers/Card/SelectedConferenceInfo'
import PageTitle from '@/components/areas/common/PageTitleWithIndexButton'
import PageDetail from '@/components/areas/users/Show/Detail'
export default {
  mixins: [ManagerApi],
  components: {
    SelectedConferenceInfo,
    PageTitle,
    PageDetail,
  },
  data: () => ({
    loading: false,
    user: {
      profile: {
        name: '',
        name_hira: '',
        organization: '',
        organization_hira: '',
        phone: '',
        birthday: '',
        user_type: '',
        join_type: '',
        lom: {
          name: '',
        },
      },
    },
  }),
  created() {
    this.getUser()
  },
  methods: {
    getUser() {
      this.loading = true
      this.axios
        .get(this.API.users.show)
        .then((res) => {
          this.user = res.data.user
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  metaInfo() {
    return this.$t(`meta_tags.${this.$route.name}`)
  },
}
</script>
