<template>
  <v-row justify="center">
    <v-col cols="12" md="6">
      <v-card class="py-4 px-4">
        <v-card-title class="mb-8">
          <v-row>
            <v-col align="center" class="word-breaker">
              <h3>{{ $t(`meta_tags.${this.$route.name}.title`) }}</h3>
            </v-col>
          </v-row>
        </v-card-title>
        <form-with-validation-block
          :loading="loading"
          :button-text="$t('button.login')"
          @submit="login"
        >
          <login-form class="mt-6" v-model="normalAccount" />
        </form-with-validation-block>
      </v-card>
      <forget-password
        class="mt-4"
        @click="$router.push({ name: 'user-passwords-reset-send' })"
      />
    </v-col>
  </v-row>
</template>
<script>
import UserApi from '@/api/users'
import VueCookies from 'vue-cookies'
import FormWithValidationBlock from '@/components/areas/common/FormWithValidationBlock'
import LoginForm from '@/components/areas/login/EntryForm'
import ForgetPassword from '@/components/areas/login/ForgetPassword'
export default {
  mixins: [UserApi],
  components: {
    VueCookies,
    FormWithValidationBlock,
    LoginForm,
    ForgetPassword,
  },
  data: () => ({
    loading: false,
    normalAccount: {
      email: '',
      password: '',
    },
  }),
  methods: {
    login() {
      this.loading = true
      this.axios
        .post(this.API.auth.sign_in, this.normalAccount)
        .then((res) => {
          this.$store.dispatch('loginUser', res.data.user)
          this.$router.push(this.$$userPushAfterLogin())
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          err.response.data.errors
            ? this.$toasted.error(err.response.data.errors.join('\n'))
            : this.$toasted.error(err.response.data)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  metaInfo() {
    return this.$t(`meta_tags.${this.$route.name}`)
  },
}
</script>
