<template>
  <v-list nav expand>
    <v-list-item
      link
      v-for="list in filteredDrawerLists"
      :key="list.title"
      :to="list.url"
      :exact="list.exact"
    >
      <v-list-item-action>
        <v-icon>{{ list.icon }}</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>{{ list.title }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>
<script>
export default {
  data: () => ({
    drawerLists: [
      {
        title: '会議選択',
        icon: 'mdi-package-variant-closed',
        url: { name: 'user-conferences-index' },
        exact: true,
      },
      {
        title: '選択中の会議',
        icon: 'mdi-package-variant-closed-check',
        url: { name: 'user-conferences-show' },
        exact: true,
      },
      {
        title: 'プログラム',
        icon: 'mdi-home',
        url: { name: 'user-programs-index' },
      },
      {
        title: 'アカウント設定',
        icon: 'mdi-cog-outline',
        url: { name: 'user-accounts-show' },
      },
      {
        title: 'ご来場証明書',
        icon: 'mdi-card-account-details-star-outline',
        url: { name: 'user-qrcodes-welcome' },
      },
      {
        title: 'クレジット',
        icon: 'mdi-file-code',
        url: { name: 'credit' },
      },
    ],
  }),
  computed: {
    filteredDrawerLists() {
      const filterUrlName = [
        'user-conferences-index',
        'user-accounts-show',
        'credit',
      ]
      let tmpDrawerLists = this.drawerLists
      if (!this.$store.getters.conference.visit_venue_at) {
        tmpDrawerLists = tmpDrawerLists.filter(
          (row) => row.url.name !== 'user-qrcodes-welcome'
        )
      }
      if (this.$store.getters.conference.id) return tmpDrawerLists
      return tmpDrawerLists.filter((row) =>
        filterUrlName.includes(row.url.name)
      )
    },
  },
}
</script>
