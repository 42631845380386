<template>
  <v-card class="pt-4">
    <v-card class="mx-6" v-if="$$exist(conference.image)">
      <v-img :src="conference.image.url" />
    </v-card>
    <v-card-text class="px-4 mb-n4">
      <v-sheet class="mb-2">
        <v-chip label dark color="red">
          {{ $t('label.conference_start') }}
        </v-chip>
        {{ conference.schedule_st_at | momentFormat('llll') }}
      </v-sheet>
      <v-sheet>
        <v-chip label dark color="indigo">
          {{ $t('label.conference_end') }}
        </v-chip>
        {{ conference.schedule_ed_at | momentFormat('llll') }}
      </v-sheet>
    </v-card-text>
    <v-card-title class="card-title">{{ conference.title }}</v-card-title>
    <v-card-text class="px-2">
      <v-sheet align="center">
        <v-btn
          width="45%"
          color="primary"
          class="mr-4"
          x-large
          @click="$emit('click:select')"
        >
          {{ $t('button.select') }}
        </v-btn>
        <conference-dialog
          :conference="conference"
          @click:select="$emit('click:select')"
        />
      </v-sheet>
    </v-card-text>
  </v-card>
</template>
<script>
import ConferenceDialog from './Dialog'
export default {
  components: {
    ConferenceDialog,
  },
  props: {
    conference: { type: Object, required: true },
  },
}
</script>
<style scoped lang="scss">
.card-title {
  font-size: 1.1rem !important;
  line-height: 1.5rem !important;
}
</style>
