<template>
  <v-form @submit.prevent="$emit('click:search')">
    <v-row>
      <v-col cols="12" md="3">
        <v-text-field
          v-model="internalValue.title"
          :label="$$columnName('program.title')"
          outlined
          dense
          hide-details
          clearable
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-select
          :loading="loadingVenues"
          v-model="internalValue.venue_id"
          :label="$$tableName('venue')"
          :items="venues"
          item-value="id"
          item-text="title"
          outlined
          dense
          hide-details
          clearable
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-select
          :loading="loadingTeachers"
          v-model="internalValue.teacher_id"
          :label="$$tableName('teacher')"
          :items="teachers"
          item-value="id"
          item-text="name"
          outlined
          dense
          hide-details
          clearable
        />
      </v-col>
      <v-col cols="12" md="3" :align="btnAlign">
        <v-btn v-if="cancelable" class="mr-2" @click="$emit('click:cancel')">
          {{ $t('button.cancel') }}
        </v-btn>
        <v-btn color="primary" type="submit">
          {{ $t('button.search') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
export default {
  props: {
    value: { type: Object, required: true },
    loadingVenues: { type: Boolean, required: true },
    loadingTeachers: { type: Boolean, required: true },
    venues: { type: Array, required: true },
    teachers: { type: Array, required: true },
    cancelable: { type: Boolean, default: false },
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    btnAlign() {
      return this.$vuetify.breakpoint.mobile ? 'right' : 'left'
    },
  },
}
</script>
