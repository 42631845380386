<template>
  <div>
    <selected-conference-info />
    <page-title
      class="mb-4"
      @click:back="$router.push({ name: 'manager-conferences-show' })"
    />
    <v-card :loading="loading" :max-width="500">
      <v-card-title></v-card-title>
      <v-card-text>
        <form-with-validation :loading="loading" @click:save="save">
          <entry-form v-model="conference" />
        </form-with-validation>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import ManagerApi from '@/api/managers'
import SelectedConferenceInfo from '@/components/areas/managers/Card/SelectedConferenceInfo'
import PageTitle from '@/components/areas/common/PageTitleWithBackButton'
import FormWithValidation from '@/components/areas/common/FormWithValidation'
import EntryForm from '@/components/areas/conferences/Form/EntryForm'
export default {
  mixins: [ManagerApi],
  components: {
    SelectedConferenceInfo,
    PageTitle,
    FormWithValidation,
    EntryForm,
  },
  data: () => ({
    loading: false,
    conference: {
      title: '',
      desc: '',
      email: '',
      schedule_st_at: '',
      schedule_ed_at: '',
      image: {},
      remove_image: false,
      welcome_image: {},
      remove_welcome_image: false,
    },
  }),
  watch: {
    'conference.image'(val) {
      this.conference.remove_image = Object.keys(val).length === 0
    },
    'conference.welcome_image'(val) {
      this.conference.remove_welcome_image = Object.keys(val).length === 0
    },
  },
  created() {
    this.getConference()
  },
  methods: {
    getConference() {
      this.loading = true
      this.axios
        .get(this.API.conferences.show)
        .then((res) => {
          this.conference = res.data.conference
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
    save() {
      this.loading = true
      this.axios
        .patch(
          this.API.conferences.update,
          this.$$castToFormData({ conference: this.conference }),
          { headers: { 'content-type': 'multipart/form-data' } }
        )
        .then(() => {
          this.$router.push({ name: 'manager-conferences-show' })
          this.$toasted.success(this.$t('success.create'))
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  metaInfo() {
    return this.$t(`meta_tags.${this.$route.name}`)
  },
}
</script>
