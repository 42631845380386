<template>
  <v-row>
    <v-col cols="12" md="12">
      <validation-provider
        v-slot="{ errors }"
        :name="$$columnName('teacher.name')"
        rules="required|max:30"
      >
        <v-text-field
          v-model="internalValue.name"
          :label="$$columnName('teacher.name')"
          :error-messages="errors"
          outlined
        />
      </validation-provider>
    </v-col>
    <v-col cols="12" md="12">
      <validation-provider
        v-slot="{ errors }"
        :name="$$columnName('teacher.name_hira')"
        rules="required|max:30|zenHira"
      >
        <v-text-field
          v-model="internalValue.name_hira"
          :label="$$columnName('teacher.name_hira')"
          :error-messages="errors"
          outlined
        />
      </validation-provider>
    </v-col>
    <v-col cols="12" md="12">
      <validation-provider
        v-slot="{ errors }"
        :name="$$columnName('teacher.desc')"
        rules="required|max:1000"
      >
        <v-textarea
          v-model="internalValue.desc"
          :label="$$columnName('teacher.desc')"
          :error-messages="errors"
          outlined
          auto-grow
          :rows="3"
          counter="1000"
        />
      </validation-provider>
    </v-col>
    <v-col cols="12" md="12">
      <v-sheet class="mb-2">{{ $$columnName('teacher.image') }}</v-sheet>
      <file-pond-for-single
        v-model="internalValue.image"
        accepted-file-types="image/jpg,image/jpeg,image/png,image/webp"
      />
      <v-sheet class="mb-2 red--text">{{
        $t('description.teacher.image')
      }}</v-sheet>
    </v-col>
  </v-row>
</template>
<script>
import FilePondForSingle from '@/components/plugins/FilePondForSingle'
export default {
  components: {
    FilePondForSingle,
  },
  props: {
    value: { type: Object, required: true },
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
