<template>
  <v-dialog v-model="dialog" :max-width="1000">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" width="45%" x-large>
        {{ $t('button.to_detail') }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title></v-card-title>
      <v-card-text>
        <v-row class="mb-4">
          <v-col cols="12" align="center">
            <v-card v-if="$$exist(conference.image)" :max-width="300">
              <v-img :src="conference.image.url" />
            </v-card>
          </v-col>
        </v-row>
        <v-card-title class="card-title my-4">{{
          conference.title
        }}</v-card-title>
        <v-simple-table class="mouse-over-highlight-disabled">
          <tbody>
            <tr>
              <td>{{ $$columnName('conference.title') }}</td>
              <td>{{ conference.title }}</td>
            </tr>
            <tr>
              <td>{{ $$columnName('conference.desc') }}</td>
              <td>
                <div class="word-breaker py-2" v-html="linkedDesc" />
              </td>
            </tr>
            <tr>
              <td>{{ $$columnName('conference.email') }}</td>
              <td>{{ conference.email }}</td>
            </tr>
            <tr>
              <td>{{ $$columnName('conference.schedule_st_at') }}</td>
              <td>{{ conference.schedule_st_at | momentFormat('ll') }}</td>
            </tr>
            <tr>
              <td>{{ $$columnName('conference.schedule_ed_at') }}</td>
              <td>{{ conference.schedule_ed_at | momentFormat('ll') }}</td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-row>
          <v-col align="right">
            <v-btn class="mr-4" color="primary" @click="select">
              {{ $t('button.select') }}
            </v-btn>
            <v-btn @click="dialog = false">
              {{ $t('button.close') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import Autolinker from 'autolinker'
export default {
  props: {
    conference: { type: Object, required: true },
  },
  data: () => ({
    dialog: false,
  }),
  computed: {
    linkedDesc() {
      return Autolinker.link(this.conference.desc, {
        sanitizeHtml: true,
        stripPrefix: false,
      })
    },
  },
  methods: {
    select() {
      this.dialog = false
      this.$emit('click:select')
    },
  },
}
</script>
<style scoped lang="scss">
.card-title {
  font-size: 1.1rem !important;
  line-height: 1.5rem !important;
}
</style>
