<template>
  <v-sheet align="center">
    <v-progress-circular
      class="mt-12"
      size="100"
      color="primary"
      indeterminate
    />
  </v-sheet>
</template>
<script>
export default {
  created() {
    this.$cookies.set('visitVenueFlg', true)
    this.$store.dispatch('setConferenceId', this.$route.query.cid)
    this.redirectPage()
  },
  methods: {
    redirectPage() {
      if (this.$store.getters.user) {
        this.$router.push({ name: 'user-qrcodes-welcome' })
      } else {
        this.$router.push({ name: 'top' })
      }
    },
  },
  metaInfo() {
    return this.$t(`meta_tags.${this.$route.name}`)
  },
}
</script>
