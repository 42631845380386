<template>
  <div>
    <selected-conference-info />
    <page-title
      class="mb-4"
      @click:new="$router.push({ name: 'manager-programs-new' })"
    />
    <index-search
      class="mb-4"
      v-model="search"
      :loading-venues="loadingVenues"
      :loading-teachers="loadingTeachers"
      :venues="venues"
      :teachers="teachers"
      @search="pushQueryForSearch"
    />
    <index-table
      class="mb-4"
      :programs="programs"
      :loading="loading"
      @click:row="clickRow"
    />
  </div>
</template>
<script>
import ManagerApi from '@/api/managers'
import SelectedConferenceInfo from '@/components/areas/managers/Card/SelectedConferenceInfo'
import PageTitle from '@/components/areas/common/PageTitleWithNewButton'
import IndexSearch from './Index/Search'
import IndexTable from './Index/Table'
export default {
  mixins: [ManagerApi],
  components: {
    SelectedConferenceInfo,
    PageTitle,
    IndexSearch,
    IndexTable,
  },
  data: () => ({
    loading: false,
    loadingVenues: false,
    loadingTeachers: false,
    search: {},
    programs: [],
    venues: [],
    teachers: [],
  }),
  computed: {
    queryParams() {
      return { ...this.$route.query }
    },
  },
  beforeRouteUpdate(to, from, next) {
    next()
    this.initSearch()
    this.getPrograms()
  },
  created() {
    this.initSearch()
    this.getPrograms()
    this.getVenues()
    this.getTeachers()
  },
  methods: {
    initSearch() {
      this.search = this.queryParams
    },
    pushQueryForSearch() {
      const query = this.$$removeBlankValue(this.search)
      this.$router.push({ query }, () => {})
    },
    getPrograms() {
      this.loading = true
      this.axios
        .get(this.API.programs.index, { params: this.queryParams })
        .then((res) => {
          this.programs = res.data.programs
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
    getVenues() {
      this.loadingVenues = true
      this.axios
        .get(this.API.venues.index)
        .then((res) => {
          this.venues = res.data.venues
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loadingVenues = false
        })
    },
    getTeachers() {
      this.loadingTeachers = true
      this.axios
        .get(this.API.teachers.index)
        .then((res) => {
          this.teachers = res.data.teachers
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loadingTeachers = false
        })
    },
    clickRow({ id }) {
      this.$router.push({
        name: 'manager-programs-show',
        params: { programId: id },
      })
    },
  },
  metaInfo() {
    return this.$t(`meta_tags.${this.$route.name}`)
  },
}
</script>
