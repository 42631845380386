<template>
  <div>
    <selected-conference-info />
    <page-title
      class="mb-4"
      @click:index="$router.push({ name: 'manager-programs-index' })"
    />
    <v-row>
      <v-col cols="6" md="5">
        <page-detail
          :program="program"
          :loading="loading"
          @click:edit="
            $router.push({
              name: 'manager-programs-edit',
              params: { programId: program.id },
            })
          "
          @click:destroy="destroy"
          @click:audiences-counts="
            $router.push({
              name: 'manager-programs-audiences-counts',
              params: { programId: program.id },
            })
          "
          @edit:teacher="toEditTeacher"
        />
      </v-col>
      <v-col cols="6" md="4">
        <v-card>
          <v-card-title>{{ $t('label.visit_venue_qrcode') }}</v-card-title>
          <v-divider />
          <v-card-text>
            <vue-qrcode :value="redirectUrl" :options="{ width: 200 }" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import VueQrcode from '@chenfengyuan/vue-qrcode'
import ManagerApi from '@/api/managers'
import SelectedConferenceInfo from '@/components/areas/managers/Card/SelectedConferenceInfo'
import PageTitle from '@/components/areas/common/PageTitleWithIndexButton'
import PageDetail from './Show/Detail'
export default {
  mixins: [ManagerApi],
  components: {
    VueQrcode,
    SelectedConferenceInfo,
    PageTitle,
    PageDetail,
  },
  data: () => ({
    loading: false,
    program: {
      title: '',
      desc: '',
      teacher: {},
      committie: '',
      venue: {},
      zoom_url: '',
      answer_form_url: '',
      st_at: '',
      ed_at: '',
      archive_st_at: '',
      archive_ed_at: '',
    },
  }),
  created() {
    this.getProgram()
  },
  computed: {
    redirectUrl() {
      const domain = `${window.location.protocol}//${window.location.host}`
      const path = this.$router.resolve({
        name: 'user-program-qrcodes-redirect',
      }).href
      const query = `?cid=${this.$store.getters.conference.id}`
      return `${domain}${path}${query}`
    },
  },
  methods: {
    getProgram() {
      this.loading = true
      this.axios
        .get(this.API.programs.show)
        .then((res) => {
          this.program = res.data.program
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
    destroy() {
      this.loading = true
      this.axios
        .delete(this.API.programs.destroy)
        .then(() => {
          this.$router.push({ name: 'manager-programs-index' })
          this.$toasted.success(this.$t('success.destroy'))
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
    toEditTeacher(teacherId) {
      this.$router.push({
        name: 'manager-teachers-edit',
        params: { teacherId },
      })
    },
  },
  metaInfo() {
    return this.$t(`meta_tags.${this.$route.name}`)
  },
}
</script>
