<template>
  <v-row justify="center">
    <v-col cols="12" md="6">
      <v-card class="py-4 mb-4">
        <v-card-title class="mb-8">
          <v-row>
            <v-col align="center">
              <h2>{{ $t(`meta_tags.${this.$route.name}.title`) }}</h2>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="12" md="12">
              <form-with-validation-block
                :loading="loading"
                :button-text="$t('button.signup')"
                @submit="signup"
              >
                <entry-form
                  v-model="user.profile"
                  :loading-loms="loadingLoms"
                  :loms="loms"
                />
                <registor-entry-form v-model="user" is-new-record />
              </form-with-validation-block>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <to-login class="mt-4" @click="$router.push({ name: 'user-login' })" />
    </v-col>
  </v-row>
</template>
<script>
import UserApi from '@/api/users'
import FormWithValidationBlock from '@/components/areas/common/FormWithValidationBlock'
import EntryForm from '@/components/areas/users/Form/EntryForm'
import RegistorEntryForm from '@/components/areas/users/Form/RegistorEntryForm'
import ToLogin from './Signup/ToLogin'
export default {
  mixins: [UserApi],
  components: {
    FormWithValidationBlock,
    EntryForm,
    RegistorEntryForm,
    ToLogin,
  },
  data: () => ({
    loading: false,
    loadingLoms: false,
    user: {
      email: '',
      password: '',
      password_confirmation: '',
      profile: {
        name: '',
        name_hira: '',
        organization: '',
        organization_hira: '',
        phone: '',
        user_type: '',
        join_type: 'local',
        lom_id: '',
      },
    },
    loms: [],
  }),
  created() {
    this.getUserType()
    if (this.user.profile.user_type === 'regular') this.getLoms()
  },
  methods: {
    getUserType() {
      const enumUserType = this.$$selectEnums('user_type')
      const findUserType = enumUserType.find((userType) => {
        return userType.value === this.$route.query.user_type
      })
      if (findUserType) this.user.profile.user_type = findUserType.value
    },
    getLoms() {
      this.loadingLoms = true
      this.axios
        .get(this.API.loms.index)
        .then((res) => {
          this.loms = res.data.loms
        })
        .catch((err) => {
          if (err.response.status !== 422) return
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loadingLoms = false
        })
    },
    signup() {
      this.loading = true
      this.axios
        .post(this.API.auth.sign_up, {
          user: this.user,
          conference_id: this.$route.query.cid,
        })
        .then(() => {
          this.$router.push({ name: 'user-signup-email' })
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  metaInfo() {
    return this.$t(`meta_tags.${this.$route.name}`)
  },
}
</script>
