<template>
  <v-row>
    <v-col cols="12">
      <validation-provider
        v-slot="{ errors }"
        :name="$$columnName('conference.title')"
        rules="required|max:100"
      >
        <v-text-field
          v-model="internalValue.title"
          :label="$$columnName('conference.title')"
          :error-messages="errors"
          outlined
        />
      </validation-provider>
    </v-col>
    <v-col cols="12">
      <validation-provider
        v-slot="{ errors }"
        :name="$$columnName('conference.desc')"
        rules="required|max:1000"
      >
        <v-textarea
          v-model="internalValue.desc"
          :label="$$columnName('conference.desc')"
          :error-messages="errors"
          outlined
          auto-grow
          :rows="3"
          counter="1000"
        />
      </validation-provider>
    </v-col>
    <template v-if="isAdmin">
      <v-col cols="12" md="12">
        <validation-provider
          v-slot="{ errors }"
          :name="$$tableName('manager')"
          rules=""
        >
          <v-select
            v-model="internalValue.manager_ids"
            :label="$$tableName('manager')"
            :error-messages="errors"
            :items="selectManagers"
            outlined
            :loading="loadingManagers"
            multiple
            chips
          />
        </validation-provider>
      </v-col>
    </template>
    <v-col cols="12" md="12">
      <validation-provider
        v-slot="{ errors }"
        :name="$$columnName('conference.email')"
        rules="required|max:256|email"
      >
        <v-text-field
          v-model="internalValue.email"
          :label="$$columnName('conference.email')"
          :error-messages="errors"
          outlined
        />
      </validation-provider>
    </v-col>
    <v-col cols="12" md="6">
      <validation-provider
        v-slot="{ errors }"
        :name="$$columnName('conference.publication_at')"
        rules="required"
      >
        <datetime
          v-model="internalValue.publication_at"
          :label="$$columnName('conference.publication_at')"
          :error-messages="errors"
          type="datetime"
          :text-field-options="{
            outlined: true,
            clearable: true,
            appendIcon: 'mdi-calendar',
          }"
        />
      </validation-provider>
    </v-col>
    <v-col v-if="!$vuetify.breakpoint.smAndDown" md="6"></v-col>
    <v-col cols="12" md="6">
      <validation-provider
        v-slot="{ errors }"
        :name="$$columnName('conference.schedule_st_at')"
        rules="required"
      >
        <datetime
          v-model="internalValue.schedule_st_at"
          :label="$$columnName('conference.schedule_st_at')"
          :error-messages="errors"
          type="datetime"
          :text-field-options="{
            outlined: true,
            clearable: true,
            appendIcon: 'mdi-calendar',
          }"
        />
      </validation-provider>
    </v-col>
    <v-col cols="12" md="6">
      <validation-provider
        v-slot="{ errors }"
        :name="$$columnName('conference.schedule_ed_at')"
        rules="required"
      >
        <datetime
          v-model="internalValue.schedule_ed_at"
          :label="$$columnName('conference.schedule_ed_at')"
          :error-messages="errors"
          type="datetime"
          :text-field-options="{
            outlined: true,
            clearable: true,
            appendIcon: 'mdi-calendar',
          }"
        />
      </validation-provider>
    </v-col>
    <v-col cols="12">
      <v-sheet class="mb-2">{{ $$columnName('conference.image') }}</v-sheet>
      <file-pond-for-single
        v-model="internalValue.image"
        accepted-file-types="image/jpg,image/jpeg,image/png,image/webp"
      />
      <v-sheet class="mb-2 red--text">{{
        $t('description.conference.image')
      }}</v-sheet>
    </v-col>
    <v-col cols="12">
      <v-sheet class="mb-2">{{
        $$columnName('conference.welcome_image')
      }}</v-sheet>
      <file-pond-for-single
        v-model="internalValue.welcome_image"
        accepted-file-types="image/jpg,image/jpeg,image/png,image/webp"
      />
      <v-sheet class="mb-2 red--text">{{
        $t('description.conference.welcome_image')
      }}</v-sheet>
    </v-col>
  </v-row>
</template>
<script>
import FilePondForSingle from '@/components/plugins/FilePondForSingle'
export default {
  components: {
    FilePondForSingle,
  },
  props: {
    value: { type: Object, required: true },
    loadingManagers: { type: Boolean, default: false },
    selectManagers: { type: Array, default: () => [] },
    isAdmin: { type: Boolean, default: false },
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
