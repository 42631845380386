<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :items="filteredLoms"
      v-model="selectedLom"
      show-select
      single-select
      disable-sort
      @click:row="clickRow"
    >
    </v-data-table>
  </v-card>
</template>
<script>
export default {
  props: {
    value: { type: String, required: true },
    search: { type: Object, required: true },
    loms: { type: Array, required: true },
  },
  data: () => ({
    headers: [],
    selectedLom: [],
  }),
  watch: {
    selectedLom(val) {
      // val[0] use because single-select
      const lomId = val.length === 0 ? '' : val[0].id
      this.$emit('input', lomId)
    },
  },
  computed: {
    filteredLoms() {
      const keyword = this.search.keyword || ''
      const rejectWord =
        /青年会議所|せいねんかいぎしょ|JC|jc|Jc|ＪＣ|ｊｃ|Ｊｃ/g
      const filteredKeyword = keyword.replace(rejectWord, '')
      const lomsFilterByKeyword = !filteredKeyword
        ? this.loms
        : this.loms.filter((lom) => {
            if (lom.name.includes(filteredKeyword)) return true

            if (lom.name_hira.includes(filteredKeyword)) return true

            if (lom.lom_code.includes(filteredKeyword)) return true
          })
      const lomsFilterByBlock = !this.search.block
        ? lomsFilterByKeyword
        : lomsFilterByKeyword.filter((lom) => lom.block === this.search.block)
      return lomsFilterByBlock
    },
  },
  created() {
    this.headers = [
      { text: this.$$columnName('lom.lom_code'), value: 'lom_code' },
      { text: this.$$columnName('lom.name'), value: 'name' },
    ]
  },
  methods: {
    clickRow(_, opts) {
      opts.select(!opts.isSelected)
    },
  },
}
</script>
