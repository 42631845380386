var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.users,"disable-sort":"","show-select":"","server-items-length":_vm.totalCount,"page":_vm.tableOptions.page,"items-per-page":_vm.tableOptions.per},on:{"click:row":function($event){return _vm.$emit('click:row', $event)},"update:options":function($event){return _vm.$emit('update:options', $event)}},scopedSlots:_vm._u([{key:"item.profile.user_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$$getEnumText('user_type', item.profile.user_type))+" ")]}},{key:"item.users_conference.attended_user_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$$getEnumText('user_type', item.users_conference.attended_user_type))+" ")]}},(_vm.isAdmin)?{key:"item.profile.account_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$$getEnumText('account_type', item.profile.account_type))+" ")]}}:{key:"item.users_conference.conference_account_status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$$getEnumText( 'conference_account_status', item.users_conference.conference_account_status ))+" ")]}},{key:"item.profile.jci_member_availability",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$$getEnumText( 'jci_member_availability', item.profile.jci_member_availability ))+" ")]}},{key:"item.provider",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$$getEnumText('provider', item.provider))+" ")]}}],null,true),model:{value:(_vm.selectedUsers),callback:function ($$v) {_vm.selectedUsers=$$v},expression:"selectedUsers"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }