<template>
  <div>
    <selected-conference-info />
    <page-title class="mb-4" />
    <btn-dialog-search
      v-if="$vuetify.breakpoint.mobile"
      v-model="search"
      :loading-venues="loadingVenues"
      :loading-teachers="loadingTeachers"
      :venues="storeVenues"
      :teachers="storeTeachers"
      @click:search="clickSearch"
    />
    <index-search
      v-else
      class="mb-4"
      v-model="search"
      :loading-venues="loadingVenues"
      :loading-teachers="loadingTeachers"
      :venues="storeVenues"
      :teachers="storeTeachers"
      @click:search="clickSearch"
    />
    <v-btn
      color="primary"
      fab
      bottom
      right
      fixed
      @click="$vuetify.goTo(0)"
      class="mb-16"
    >
      <v-icon large>mdi-chevron-up</v-icon>
    </v-btn>
    <area-notfound
      v-if="conferencePublicationAt"
      :message="NotPublishedMessage"
    />
    <area-loading v-else-if="loadingPrograms" class="mt-12" />
    <area-notfound
      v-else-if="isNotfound"
      :message="$t('errors.notfound_programs')"
    />
    <index-cards
      v-else
      class="mb-4"
      v-model="storeSearch"
      :programs="storePrograms"
      :loading="loadingPrograms"
    />
  </div>
</template>
<script>
import UserApi from '@/api/users'
import moment from 'moment'
import SelectedConferenceInfo from '@/components/areas/users/Card/SelectedConferenceInfo'
import PageTitle from '@/components/areas/common/PageTitle'
import AreaLoading from '@/components/areas/common/Loading'
import AreaNotfound from '@/components/areas/common/Notfound'
import IndexSearch from './Index/Search'
import BtnDialogSearch from './Index/BtnDialogSearch'
import IndexCards from './Index/Cards'
export default {
  mixins: [UserApi],
  components: {
    SelectedConferenceInfo,
    PageTitle,
    AreaLoading,
    AreaNotfound,
    IndexSearch,
    BtnDialogSearch,
    IndexCards,
  },
  data: () => ({
    loadingPrograms: false,
    loadingVenues: false,
    loadingTeachers: false,
    conferencePublicationAt: '',
    search: {},
  }),
  computed: {
    NotPublishedMessage() {
      return `${this.$t('label.not_published_conference')}\n${this.$$columnName(
        'conference.publication_at'
      )}:${moment(this.conferencePublicationAt).locale('ja').format('lll')}`
    },
    isNotfound() {
      return !this.loadingPrograms && this.storePrograms.length === 0
    },
    storePrograms: {
      get() {
        return this.$store.getters['users/programs']
      },
      set(val) {
        this.$store.dispatch('users/setPrograms', val)
      },
    },
    storeVenues: {
      get() {
        return this.$store.getters['users/venues']
      },
      set(val) {
        this.$store.dispatch('users/setVenues', val)
      },
    },
    storeTeachers: {
      get() {
        return this.$store.getters['users/teachers']
      },
      set(val) {
        this.$store.dispatch('users/setTeachers', val)
      },
    },
    storeSearch: {
      get() {
        return this.$store.getters['users/programsSearch']
      },
      set(val) {
        this.$store.dispatch('users/setProgramsSearch', val)
      },
    },
  },
  created() {
    this.search = this.$$clone(this.storeSearch)
    if (this.$$blank(this.storePrograms)) {
      this.getPrograms()
    }
    if (this.$$blank(this.storeVenues)) {
      this.getVenues()
    }
    if (this.$$blank(this.storeTeachers)) {
      this.getTeachers()
    }
  },
  methods: {
    getPrograms() {
      this.loadingPrograms = true
      this.axios
        .get(this.API.programs.index, {
          params: this.$$removeBlankValue(this.search),
        })
        .then((res) => {
          this.storePrograms = res.data.programs
        })
        .catch((err) => {
          if (err.response.data.publication_at)
            this.conferencePublicationAt = err.response.data.publication_at
          if (err.response.status !== 422) return
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loadingPrograms = false
        })
    },
    getVenues() {
      this.loadingVenues = true
      this.axios
        .get(this.API.venues.index)
        .then((res) => {
          this.storeVenues = res.data.venues
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loadingVenues = false
        })
    },
    getTeachers() {
      this.loadingTeachers = true
      this.axios
        .get(this.API.teachers.index)
        .then((res) => {
          this.storeTeachers = res.data.teachers
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loadingTeachers = false
        })
    },
    clickSearch() {
      this.search.page = 1
      this.storeSearch = this.$$clone(this.search)
      this.getPrograms()
    },
  },
  metaInfo() {
    return this.$t(`meta_tags.${this.$route.name}`)
  },
}
</script>
