<template>
  <v-row>
    <v-col cols="12">
      <validation-provider
        v-slot="{ errors }"
        :name="$$columnName('manager.email')"
        rules="required|max:256|email"
      >
        <v-text-field
          v-model="internalValue.email"
          :label="$$columnName('manager.email')"
          :error-messages="errors"
          outlined
        />
      </validation-provider>
    </v-col>
    <v-col cols="12" v-if="isNewRecord">
      <validation-provider
        v-slot="{ errors }"
        :name="$$columnName('manager.password')"
        rules="required"
      >
        <text-field-password
          v-model="internalValue.password"
          :text-field-options="{
            label: $$columnName('manager.password'),
            errorMessages: errors,
            outlined: true,
          }"
        />
      </validation-provider>
    </v-col>
    <v-col cols="12">
      <v-switch
        v-model="internalValue.is_lom_representative"
        :label="$$columnName('manager.is_lom_representative')"
      ></v-switch>
    </v-col>
  </v-row>
</template>
<script>
import TextFieldPassword from '@/components/parts/TextField/Password'
export default {
  components: {
    TextFieldPassword,
  },
  props: {
    value: { type: Object, required: true },
    isNewRecord: { type: Boolean, required: true },
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
