<template>
  <v-form @submit.prevent="$emit('search')">
    <v-row>
      <v-col cols="12" md="3">
        <v-text-field
          v-model="internalValue.name_or_hira"
          :label="$t('search.users.name_or_hira')"
          outlined
          dense
          hide-details
          clearable
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          v-model="internalValue.organization_or_hira"
          :label="$t('search.users.organization_or_hira')"
          outlined
          dense
          hide-details
          clearable
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          v-model="internalValue.email"
          :label="$$columnName('user.email')"
          outlined
          dense
          hide-details
          clearable
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-select
          v-model="internalValue.user_type"
          :label="$$columnName('user/profile.user_type')"
          :items="select.userType"
          outlined
          dense
          hide-details
          clearable
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-select
          v-model="internalValue.attended_user_type"
          :label="$$columnName('users_conference.attended_user_type')"
          :items="select.userType"
          outlined
          dense
          hide-details
          clearable
        />
      </v-col>
      <v-col cols="12" md="3">
        <validation-provider
          v-slot="{ errors }"
          :name="$t('search.audiences_counts.created_at_from')"
          rules="required"
        >
          <datetime
            v-model="internalValue.created_at_from"
            :label="$t('search.audiences_counts.created_at_from')"
            :error-messages="errors"
            :text-field-options="{
              dense: true,
              outlined: true,
              clearable: true,
              appendIcon: 'mdi-calendar',
              hideDetails: true,
            }"
          />
        </validation-provider>
      </v-col>
      <v-col cols="12" md="3">
        <validation-provider
          v-slot="{ errors }"
          :name="$t('search.audiences_counts.created_at_to')"
          rules="required"
        >
          <datetime
            v-model="internalValue.created_at_to"
            :label="$t('search.audiences_counts.created_at_to')"
            :error-messages="errors"
            :text-field-options="{
              dense: true,
              outlined: true,
              clearable: true,
              appendIcon: 'mdi-calendar',
              hideDetails: true,
            }"
          />
        </validation-provider>
      </v-col>
      <v-col cols="12" md="3">
        <select-dialog-search-lom
          v-model="internalValue.lom_id"
          :loms="loms"
          :select-options="{
            label: $$tableName('lom'),
            outlined: true,
            dense: true,
            hideDetails: true,
            clearable: true,
          }"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-select
          v-model="internalValue.participation_type"
          :label="$$columnName('audiences_count.participation_type')"
          :items="select.participationType"
          outlined
          dense
          hide-details
          clearable
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-btn type="submit" outlined>
          {{ $t('button.search') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import SelectDialogSearchLom from '@/components/parts/Select/Dialog/SearchLom'
export default {
  components: {
    SelectDialogSearchLom,
  },
  props: {
    value: { type: Object, required: true },
    loms: { type: Array, required: true },
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    select() {
      return {
        userType: this.$$selectEnums('user_type'),
        participationType: this.$$selectEnums('participation_type'),
      }
    },
  },
}
</script>
