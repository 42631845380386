<template>
  <div>
    <selected-conference-info />
    <page-title class="mb-4" />
    <v-sheet dark class="jci-background-color py-4 px-4 mb-4">
      {{ $t('label.conference_qrcode') }}
    </v-sheet>
    <v-card>
      <v-simple-table class="mouse-over-highlight-disabled">
        <tbody>
          <tr>
            <th>{{ $$columnName('conference.title') }}</th>
            <th width="30%">{{ $t('label.qrcode') }}</th>
          </tr>
          <tr>
            <td>{{ $store.getters.conference.title }}</td>
            <td>
              <btn-dialog-qrcode
                :title="$t('label.conference_qrcode')"
                :url="redirectUrl"
              />
              <v-btn
                class="ml-2"
                @click="
                  $$downloadQrcode(
                    redirectUrl,
                    `${$t('label.conference_qrcode')}.png`,
                    { width: 400 }
                  )
                "
              >
                {{ $t('button.download') }}
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card>
    <v-sheet dark class="jci-background-color py-4 px-4 my-4">
      {{ $t('label.signup_url_qrcode') }}
    </v-sheet>
    <v-card>
      <v-simple-table class="mouse-over-highlight-disabled">
        <tbody>
          <tr>
            <th>{{ $$columnName('user/profile.user_type') }}</th>
            <th>{{ $t('label.url') }}</th>
            <th width="30%">{{ $t('label.qrcode') }}</th>
          </tr>
          <tr
            v-for="userType in $$selectEnums('user_type_for_signup')"
            :key="userType.value"
          >
            <td>{{ userType.text }}</td>
            <td>
              <v-text-field
                :value="signupUrl(userType.value)"
                :append-outer-icon="'mdi-content-copy'"
                @click:append-outer="copyToClipboard(signupUrl(userType.value))"
                readonly
              ></v-text-field>
            </td>
            <td>
              <btn-dialog-qrcode
                :title="userType.text"
                :url="signupUrl(userType.value)"
              />
              <v-btn
                class="ml-2"
                @click="
                  $$downloadQrcode(
                    signupUrl(userType.value),
                    `${$t('label.qrcode')}_${userType.text}.png`,
                    { width: 400 }
                  )
                "
              >
                {{ $t('button.download') }}
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card>
  </div>
</template>
<script>
import SelectedConferenceInfo from '@/components/areas/managers/Card/SelectedConferenceInfo'
import PageTitle from '@/components/areas/common/PageTitle'
import BtnDialogQrcode from '@/components/parts/Btn/Dialog/Qrcode'
export default {
  components: {
    SelectedConferenceInfo,
    PageTitle,
    BtnDialogQrcode,
  },
  computed: {
    redirectUrl() {
      const domain = `${window.location.protocol}//${window.location.host}`
      const path = this.$router.resolve({ name: 'user-qrcodes-redirect' }).href
      const query = `?cid=${this.$store.getters.conference.id}`
      return `${domain}${path}${query}`
    },
    signupUrl() {
      return (userType) => {
        const domain = `${window.location.protocol}//${window.location.host}`
        const path = this.$router.resolve({ name: 'user-signup' }).href
        const query = `?user_type=${userType}&cid=${this.$store.getters.conference.id}`
        return `${domain}${path}${query}`
      }
    },
  },
  methods: {
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.$toasted.success(this.$t('success.clipboard_copy'))
      })
    },
  },
  metaInfo() {
    return this.$t(`meta_tags.${this.$route.name}`)
  },
}
</script>
