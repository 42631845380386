import ManagerLogin from '@/views/manager/bases/Login'
import ManagerSettingsPassword from '@/views/manager/settings/Password'
import ManagerPasswordsResetSend from '@/views/manager/passwords/ResetSend'
import ManagerPasswordsReset from '@/views/manager/passwords/Reset'
import ManagerConferencesIndex from '@/views/manager/conferences/Index'
import ManagerConferencesShow from '@/views/manager/conferences/Show'
import ManagerConferencesForm from '@/views/manager/conferences/Form'
import ManagerVenuesIndex from '@/views/manager/venues/Index'
import ManagerVenuesShow from '@/views/manager/venues/Show'
import ManagerVenuesForm from '@/views/manager/venues/Form'
import ManagerTeachersIndex from '@/views/manager/teachers/Index'
import ManagerTeachersShow from '@/views/manager/teachers/Show'
import ManagerTeachersForm from '@/views/manager/teachers/Form'
import ManagerProgramsIndex from '@/views/manager/programs/Index'
import ManagerProgramsShow from '@/views/manager/programs/Show'
import ManagerProgramsForm from '@/views/manager/programs/Form'
import ManagerProgramsAudiencesCounts from '@/views/manager/programs/AudiencesCounts'
import ManagerUsersIndex from '@/views/manager/users/Index'
import ManagerUsersShow from '@/views/manager/users/Show'
import ManagerQrcodesIndex from '@/views/manager/qrcodes/Index'
import ManagerJciPaymentsIndex from '@/views/manager/jci_payments/Index'

export default [
  {
    path: '/manager/login',
    name: 'manager-login',
    component: ManagerLogin,
    meta: { isPublic: true },
  },
  {
    path: '/manager/settings/password',
    name: 'manager-settings-password',
    component: ManagerSettingsPassword,
  },
  {
    path: '/manager/passwords/reset_send',
    name: 'manager-passwords-reset-send',
    component: ManagerPasswordsResetSend,
    meta: { isPublic: true },
  },
  {
    path: '/manager/passwords/reset',
    name: 'manager-passwords-reset',
    component: ManagerPasswordsReset,
    meta: { isPublic: true },
  },
  {
    path: '/manager/conferences',
    name: 'manager-conferences-index',
    component: ManagerConferencesIndex,
  },
  {
    path: '/manager/conferences/show',
    name: 'manager-conferences-show',
    component: ManagerConferencesShow,
  },
  {
    path: '/manager/conferences/:conferenceId/edit',
    name: 'manager-conferences-edit',
    component: ManagerConferencesForm,
  },
  {
    path: '/manager/venues',
    name: 'manager-venues-index',
    component: ManagerVenuesIndex,
  },
  {
    path: '/manager/venues/new',
    name: 'manager-venues-new',
    component: ManagerVenuesForm,
  },
  {
    path: '/manager/venues/:venueId/edit',
    name: 'manager-venues-edit',
    component: ManagerVenuesForm,
  },
  {
    path: '/manager/venues/:venueId',
    name: 'manager-venues-show',
    component: ManagerVenuesShow,
  },
  {
    path: '/manager/teachers',
    name: 'manager-teachers-index',
    component: ManagerTeachersIndex,
  },
  {
    path: '/manager/teachers/new',
    name: 'manager-teachers-new',
    component: ManagerTeachersForm,
  },
  {
    path: '/manager/teachers/:teacherId/edit',
    name: 'manager-teachers-edit',
    component: ManagerTeachersForm,
  },
  {
    path: '/manager/teachers/:teacherId',
    name: 'manager-teachers-show',
    component: ManagerTeachersShow,
  },
  {
    path: '/manager/programs',
    name: 'manager-programs-index',
    component: ManagerProgramsIndex,
  },
  {
    path: '/manager/programs/new',
    name: 'manager-programs-new',
    component: ManagerProgramsForm,
  },
  {
    path: '/manager/programs/:programId/edit',
    name: 'manager-programs-edit',
    component: ManagerProgramsForm,
  },
  {
    path: '/manager/programs/:programId/audiences_counts',
    name: 'manager-programs-audiences-counts',
    component: ManagerProgramsAudiencesCounts,
  },
  {
    path: '/manager/programs/:programId',
    name: 'manager-programs-show',
    component: ManagerProgramsShow,
  },
  {
    path: '/manager/users',
    name: 'manager-users-index',
    component: ManagerUsersIndex,
  },
  {
    path: '/manager/users/:userId',
    name: 'manager-users-show',
    component: ManagerUsersShow,
  },
  {
    path: '/manager/qrcodes',
    name: 'manager-qrcodes-index',
    component: ManagerQrcodesIndex,
  },
  {
    path: '/manager/jci_payments',
    name: 'manager-jci_payments-index',
    component: ManagerJciPaymentsIndex,
  },
]
