var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$$columnName('lom.name'),"rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$$columnName('lom.name'),"error-messages":errors,"outlined":""},model:{value:(_vm.internalValue.name),callback:function ($$v) {_vm.$set(_vm.internalValue, "name", $$v)},expression:"internalValue.name"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$$columnName('lom.name_hira'),"rules":"required|max:100|zenHira"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$$columnName('lom.name_hira'),"error-messages":errors,"outlined":""},model:{value:(_vm.internalValue.name_hira),callback:function ($$v) {_vm.$set(_vm.internalValue, "name_hira", $$v)},expression:"internalValue.name_hira"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$$columnName('lom.block'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":_vm.$$columnName('lom.block'),"error-messages":errors,"outlined":"","items":_vm.select.block},model:{value:(_vm.internalValue.block),callback:function ($$v) {_vm.$set(_vm.internalValue, "block", $$v)},expression:"internalValue.block"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$$columnName('lom.lom_code'),"rules":"required|length:4|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$$columnName('lom.lom_code'),"error-messages":errors,"outlined":""},model:{value:(_vm.internalValue.lom_code),callback:function ($$v) {_vm.$set(_vm.internalValue, "lom_code", $$v)},expression:"internalValue.lom_code"}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }