export default {
  computed: {
    API() {
      return this.APIwithId()
    },
  },
  methods: {
    APIwithId(ids = {}) {
      const adminId = ids.adminId ? ids.adminId : this.$route.params.adminId
      const managerId = ids.managerId
        ? ids.managerId
        : this.$route.params.managerId
      const userId = ids.userId ? ids.userId : this.$route.params.userId
      const conferenceId = ids.conferenceId
        ? ids.conferenceId
        : this.$route.params.conferenceId
      const lomId = ids.lomId ? ids.lomId : this.$route.params.lomId
      return {
        auth: {
          sign_in: '/api/v1/admin/auth/sign_in',
          sign_out: '/api/v1/admin/auth/sign_out',
        },
        password: {
          create: '/api/v1/admin/auth/password',
          update: '/api/v1/admin/auth/password',
        },
        admins: {
          index: '/api/v1/admin/admins',
          show: `/api/v1/admin/admins/${adminId}`,
          create: '/api/v1/admin/admins',
          update: `/api/v1/admin/admins/${adminId}`,
          destroy: `/api/v1/admin/admins/${adminId}`,
        },
        managers: {
          index: '/api/v1/admin/managers',
          show: `/api/v1/admin/managers/${managerId}`,
          create: '/api/v1/admin/managers',
          update: `/api/v1/admin/managers/${managerId}`,
          destroy: `/api/v1/admin/managers/${managerId}`,
        },
        users: {
          index: '/api/v1/admin/users',
          show: `/api/v1/admin/users/${userId}`,
          create: '/api/v1/admin/users',
          update: `/api/v1/admin/users/${userId}`,
          destroy: `/api/v1/admin/users/${userId}`,
          active: '/api/v1/admin/users/active',
          ban: '/api/v1/admin/users/ban',
        },
        conferences: {
          index: '/api/v1/admin/conferences',
          show: `/api/v1/admin/conferences/${conferenceId}`,
          create: '/api/v1/admin/conferences',
          update: `/api/v1/admin/conferences/${conferenceId}`,
          destroy: `/api/v1/admin/conferences/${conferenceId}`,
        },
        loms: {
          index: '/api/v1/admin/loms',
          show: `/api/v1/admin/loms/${lomId}`,
          create: '/api/v1/admin/loms',
          update: `/api/v1/admin/loms/${lomId}`,
          destroy: `/api/v1/admin/loms/${lomId}`,
        },
      }
    },
  },
}
