<template>
  <v-row justify="center">
    <v-col cols="12" md="6" v-if="loading" class="mt-12">
      <area-loading />
    </v-col>
    <v-col cols="12" md="6" v-if="!loading">
      <v-card v-if="$$exist(conference.welcome_image)" class="mb-4">
        <v-img :src="conference.welcome_image.url" />
      </v-card>
      <v-card class="mb-4">
        <v-card-title></v-card-title>
        <v-card-text>
          <v-sheet class="mb-4" align="center">
            <v-sheet class="word-breaker">{{
              $t('description.welcome')
            }}</v-sheet>
          </v-sheet>
        </v-card-text>
      </v-card>
      <v-btn
        color="primary"
        x-large
        block
        :to="{ name: 'user-programs-index' }"
      >
        {{ $t('button.to_programs') }}
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import UserApi from '@/api/users'
import AreaLoading from '@/components/areas/common/Loading'
export default {
  mixins: [UserApi],
  components: {
    AreaLoading,
  },
  data: () => ({
    loading: false,
    conference: {
      welcome_image: {},
    },
  }),
  created() {
    this.getConference()
    if (!this.$cookies.get('visitVenueFlg')) return

    this.updateVisitVenueAt()
    this.$cookies.remove('visitVenueFlg')
  },
  methods: {
    getConference() {
      this.loading = true
      this.axios
        .get(this.API.conferences.show)
        .then((res) => {
          this.conference = res.data.conference
        })
        .finally(() => {
          this.loading = false
        })
    },
    updateVisitVenueAt() {
      this.axios
        .post(this.API.conferences.visit_venue)
        .then((res) => {
          this.$store.dispatch('setVisitVenueAt', res.data.visit_venue_at)
        })
        .catch((err) => {
          if (err.response.status !== 422) return

          this.$toasted.error(err.response.data.errors.join('\n'))
        })
    },
  },
  metaInfo() {
    return this.$t(`meta_tags.${this.$route.name}`)
  },
}
</script>
