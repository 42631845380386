import AdminLogin from '@/views/admin/bases/Login'
import AdminSettingsPassword from '@/views/admin/settings/Password'
import AdminPasswordsResetSend from '@/views/admin/passwords/ResetSend'
import AdminPasswordsReset from '@/views/admin/passwords/Reset'
import AdminAdminsIndex from '@/views/admin/admins/Index'
import AdminAdminsForm from '@/views/admin/admins/Form'
import AdminAdminsShow from '@/views/admin/admins/Show'
import AdminManagersIndex from '@/views/admin/managers/Index'
import AdminManagersForm from '@/views/admin/managers/Form'
import AdminManagersShow from '@/views/admin/managers/Show'
import AdminUsersIndex from '@/views/admin/users/Index'
import AdminUsersForm from '@/views/admin/users/Form'
import AdminUsersShow from '@/views/admin/users/Show'
import AdminConferencesIndex from '@/views/admin/conferences/Index'
import AdminConferencesForm from '@/views/admin/conferences/Form'
import AdminConferencesShow from '@/views/admin/conferences/Show'
import AdminLomsIndex from '@/views/admin/loms/Index'
import AdminLomsForm from '@/views/admin/loms/Form'
import AdminLomsShow from '@/views/admin/loms/Show'

export default [
  {
    path: '/admin/login',
    name: 'admin-login',
    component: AdminLogin,
    meta: { isPublic: true },
  },
  {
    path: '/admin/settings/password',
    name: 'admin-settings-password',
    component: AdminSettingsPassword,
  },
  {
    path: '/admin/passwords/reset_send',
    name: 'admin-passwords-reset-send',
    component: AdminPasswordsResetSend,
    meta: { isPublic: true },
  },
  {
    path: '/admin/passwords/reset',
    name: 'admin-passwords-reset',
    component: AdminPasswordsReset,
    meta: { isPublic: true },
  },
  {
    path: '/admin/admins',
    name: 'admin-admins-index',
    component: AdminAdminsIndex,
  },
  {
    path: '/admin/admins/new',
    name: 'admin-admins-new',
    component: AdminAdminsForm,
  },
  {
    path: '/admin/admins/:adminId/edit',
    name: 'admin-admins-edit',
    component: AdminAdminsForm,
  },
  {
    path: '/admin/admins/:adminId',
    name: 'admin-admins-show',
    component: AdminAdminsShow,
  },
  {
    path: '/admin/managers',
    name: 'admin-managers-index',
    component: AdminManagersIndex,
  },
  {
    path: '/admin/managers/new',
    name: 'admin-managers-new',
    component: AdminManagersForm,
  },
  {
    path: '/admin/managers/:managerId/edit',
    name: 'admin-managers-edit',
    component: AdminManagersForm,
  },
  {
    path: '/admin/managers/:managerId',
    name: 'admin-managers-show',
    component: AdminManagersShow,
  },
  {
    path: '/admin/users',
    name: 'admin-users-index',
    component: AdminUsersIndex,
  },
  {
    path: '/admin/users/new',
    name: 'admin-users-new',
    component: AdminUsersForm,
  },
  {
    path: '/admin/users/:userId/edit',
    name: 'admin-users-edit',
    component: AdminUsersForm,
  },
  {
    path: '/admin/users/:userId',
    name: 'admin-users-show',
    component: AdminUsersShow,
  },
  {
    path: '/admin/conferences',
    name: 'admin-conferences-index',
    component: AdminConferencesIndex,
  },
  {
    path: '/admin/conferences/new',
    name: 'admin-conferences-new',
    component: AdminConferencesForm,
  },
  {
    path: '/admin/conferences/:conferenceId/edit',
    name: 'admin-conferences-edit',
    component: AdminConferencesForm,
  },
  {
    path: '/admin/conferences/:conferenceId',
    name: 'admin-conferences-show',
    component: AdminConferencesShow,
  },
  {
    path: '/admin/loms',
    name: 'admin-loms-index',
    component: AdminLomsIndex,
  },
  {
    path: '/admin/loms/new',
    name: 'admin-loms-new',
    component: AdminLomsForm,
  },
  {
    path: '/admin/loms/:lomId/edit',
    name: 'admin-loms-edit',
    component: AdminLomsForm,
  },
  {
    path: '/admin/loms/:lomId',
    name: 'admin-loms-show',
    component: AdminLomsShow,
  },
]
