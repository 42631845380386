<template>
  <v-dialog v-model="dialog" :max-width="350">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" icon x-large>
        <v-icon> mdi-qrcode-scan </v-icon>
      </v-btn>
    </template>
    <v-card align="center">
      <v-card-title v-if="title" class="justify-center">
        {{ title }}
      </v-card-title>
      <vue-qrcode :value="url" :options="{ width: 300 }" />
    </v-card>
  </v-dialog>
</template>
<script>
import VueQrcode from '@chenfengyuan/vue-qrcode'
export default {
  components: {
    VueQrcode,
  },
  props: {
    title: { type: String, default: '' },
    url: { type: String, required: true },
  },
  data: () => ({
    dialog: false,
  }),
}
</script>
