<template>
  <v-dialog v-model="dialog" :max-width="400">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="{ ...attrs, ...btnProps }"
        v-on="on"
        color="primary"
        fab
        bottom
        right
        fixed
      >
        <v-icon large>mdi-magnify</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        {{ $t('dialog.search.programs.title') }}
      </v-card-title>
      <v-divider class="mb-4" />
      <v-card-text>
        <program-search
          v-model="internalValue"
          :loading-venues="loadingVenues"
          :loading-teachers="loadingTeachers"
          :venues="venues"
          :teachers="teachers"
          cancelable
          @click:search="search"
          @click:cancel="dialog = false"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import ProgramSearch from './Search'
export default {
  components: {
    ProgramSearch,
  },
  props: {
    value: { type: Object, required: true },
    loadingVenues: { type: Boolean, required: true },
    loadingTeachers: { type: Boolean, required: true },
    venues: { type: Array, required: true },
    teachers: { type: Array, required: true },
    btnProps: { type: Object, default: () => ({}) },
  },
  data: () => ({
    dialog: false,
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    search() {
      this.dialog = false
      this.$emit('click:search')
    },
  },
}
</script>
