const defaultValue = {
  programs: [],
  venues: [],
  teachers: [],
  programsSearch: {
    title: '',
    venue_id: '',
    teacher_id: '',
    page: 1,
    perPage: 30,
  },
}

export default {
  namespaced: true,
  state: {
    programs: defaultValue.programs,
    venues: defaultValue.venues,
    teachers: defaultValue.teachers,
    programsSearch: defaultValue.programsSearch,
  },
  getters: {
    programs: (state) => state.programs,
    venues: (state) => state.venues,
    teachers: (state) => state.teachers,
    programsSearch: (state) => state.programsSearch,
  },
  mutations: {
    programs(state, value) {
      state.programs = value
    },
    venues(state, value) {
      state.venues = value
    },
    teachers(state, value) {
      state.teachers = value
    },
    programsSearch(state, value) {
      state.programsSearch = value
    },
  },
  actions: {
    setPrograms(context, payload) {
      context.commit('programs', payload)
    },
    setVenues(context, payload) {
      context.commit('venues', payload)
    },
    setTeachers(context, payload) {
      context.commit('teachers', payload)
    },
    setProgramsSearch(context, payload) {
      context.commit('programsSearch', payload)
    },
    clearUsersStore(context) {
      Object.keys(defaultValue).forEach((key) =>
        context.commit(key, defaultValue[key])
      )
    },
  },
}
