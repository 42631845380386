<template>
  <v-row>
    <v-col cols="12" md="6">
      <validation-provider
        v-slot="{ errors }"
        :name="$$columnName('lom.name')"
        rules="required|max:100"
      >
        <v-text-field
          v-model="internalValue.name"
          :label="$$columnName('lom.name')"
          :error-messages="errors"
          outlined
        />
      </validation-provider>
    </v-col>
    <v-col cols="12" md="6">
      <validation-provider
        v-slot="{ errors }"
        :name="$$columnName('lom.name_hira')"
        rules="required|max:100|zenHira"
      >
        <v-text-field
          v-model="internalValue.name_hira"
          :label="$$columnName('lom.name_hira')"
          :error-messages="errors"
          outlined
        />
      </validation-provider>
    </v-col>
    <v-col cols="12" md="6">
      <validation-provider
        v-slot="{ errors }"
        :name="$$columnName('lom.block')"
        rules="required"
      >
        <v-select
          v-model="internalValue.block"
          :label="$$columnName('lom.block')"
          :error-messages="errors"
          outlined
          :items="select.block"
        />
      </validation-provider>
    </v-col>
    <v-col cols="12" md="6">
      <validation-provider
        v-slot="{ errors }"
        :name="$$columnName('lom.lom_code')"
        rules="required|length:4|integer"
      >
        <v-text-field
          v-model="internalValue.lom_code"
          :label="$$columnName('lom.lom_code')"
          :error-messages="errors"
          outlined
        />
      </validation-provider>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    value: { type: Object, required: true },
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    select() {
      return {
        block: this.$$selectEnums('block'),
      }
    },
  },
}
</script>
