<template>
  <v-sheet align="center">
    <v-progress-circular
      class="mt-12"
      size="100"
      color="primary"
      indeterminate
    />
  </v-sheet>
</template>
<script>
import UserApi from '@/api/users'
export default {
  mixins: [UserApi],
  created() {
    this.redirectPage()
  },
  methods: {
    redirectPage() {
      this.axios
        .post(this.API.jci_member.token, { token: this.$route.query.token })
        .then((res) => {
          this.$store.dispatch('loginUser', res.data.user)
          this.$router.push({
            name: this.$$userDefaultPagePathName(),
          })
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {})
    },
  },
  metaInfo() {
    return this.$t(`meta_tags.${this.$route.name}`)
  },
}
</script>
