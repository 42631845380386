<template>
  <v-row>
    <v-col cols="12">
      <validation-provider
        v-slot="{ errors }"
        :name="$t('label.auth.email')"
        rules="required|max:256"
      >
        <v-text-field
          v-model="internalValue.email"
          :label="$t('label.auth.email')"
          :error-messages="errors"
          outlined
        />
      </validation-provider>
    </v-col>
    <v-col cols="12">
      <validation-provider
        v-slot="{ errors }"
        :name="$t('label.auth.password')"
        rules="required"
      >
        <text-field-password
          v-model="internalValue.password"
          :text-field-options="{
            label: $t('label.auth.password'),
            errorMessages: errors,
            outlined: true,
          }"
        />
      </validation-provider>
    </v-col>
  </v-row>
</template>
<script>
import TextFieldPassword from '@/components/parts/TextField/Password'
export default {
  components: {
    TextFieldPassword,
  },
  props: {
    value: { type: Object, required: true },
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
