<template>
  <v-row justify="center">
    <v-col cols="12" md="6">
      <v-card class="py-4 mb-4">
        <v-card-title class="mb-8">
          <v-row>
            <v-col align="center">
              <h2>{{ $t(`meta_tags.${this.$route.name}.title`) }}</h2>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-sheet class="text-center">
            <div class="mb-12 word-breaker">
              {{ $t('description.signup.email') }}
            </div>
            <v-btn
              outlined
              x-large
              @click="$router.push({ name: 'user-login' })"
            >
              {{ $t('button.to_login') }}
            </v-btn>
          </v-sheet>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  metaInfo() {
    return this.$t(`meta_tags.${this.$route.name}`)
  },
}
</script>
