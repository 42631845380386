<template>
  <v-list-item class="px-0">
    <v-list-item-content>
      <v-list-item-title>
        <h2>{{ displayTitle }}</h2>
      </v-list-item-title>
    </v-list-item-content>
    <slot></slot>
  </v-list-item>
</template>
<script>
export default {
  props: {
    title: { type: String, default: '' },
  },
  computed: {
    displayTitle() {
      if (this.title) {
        return this.title
      }
      return this.$t(`meta_tags.${this.$route.name}.title`)
    },
  },
}
</script>
