<template>
  <v-row>
    <v-col cols="12" align="right">
      <v-btn text @click="$router.push({ name: 'top' })">
        {{ $t('button.back_to_top') }}
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-col>
    <v-col cols="12" align="right">
      <v-btn
        v-if="isLoginJci"
        style="white-space: pre-wrap"
        text
        color="error"
        href="https://jccs.jaycee.or.jp/survey/reminder/"
        target="_blank"
      >
        {{ $t('button.to_forget_password_jci') }}
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      <v-btn v-else text @click="$emit('click')" class="mt-n4">
        {{ $t('button.to_forget_password_others') }}
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    isLoginJci: { type: Boolean, default: false },
  },
}
</script>
