<template>
  <v-navigation-drawer
    v-model="internalValue"
    app
    color="grey lighten-3"
    :width="290"
  >
    <div class="drawer-main-area">
      <v-img :src="require('@/assets/logo_header.webp')" />
      <admin-drawer-list v-if="displayAdminHeader" />
      <manager-drawer-list v-if="displayManagerHeader" />
      <user-drawer-list v-if="displayUserHeader" />
    </div>
    <drawer-footer />
  </v-navigation-drawer>
</template>
<script>
import AdminDrawerList from '@/components/globals/admin/DrawerList'
import ManagerDrawerList from '@/components/globals/manager/DrawerList'
import UserDrawerList from '@/components/globals/user/DrawerList'
import DrawerFooter from '@/components/globals/DrawerFooter'
export default {
  components: {
    AdminDrawerList,
    ManagerDrawerList,
    UserDrawerList,
    DrawerFooter,
  },
  props: {
    value: { required: true },
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    displayAdminHeader() {
      return this.$store.getters.admin
    },
    displayManagerHeader() {
      return this.$store.getters.manager
    },
    displayUserHeader() {
      return this.$store.getters.user
    },
  },
}
</script>
<style scoped lang="scss">
.drawer-main-area {
  min-height: calc(100vh - 180px);
}
</style>
