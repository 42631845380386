<template>
  <v-row justify="center">
    <v-col cols="12" md="6">
      <v-card class="py-4 mb-4">
        <v-card-title class="mb-8">
          <v-row>
            <v-col align="center">
              <h2>{{ $t(`meta_tags.${this.$route.name}.title`) }}</h2>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <form-with-validation-block
            :loading="loading"
            :button-text="$t('button.login')"
            @submit="login"
          >
            <login-form v-model="account" />
          </form-with-validation-block>
        </v-card-text>
      </v-card>
      <forget-password
        class="mt-4"
        @click="$router.push({ name: 'admin-passwords-reset-send' })"
      />
    </v-col>
  </v-row>
</template>
<script>
import AdminApi from '@/api/admins'
import FormWithValidationBlock from '@/components/areas/common/FormWithValidationBlock'
import LoginForm from '@/components/areas/login/EntryForm'
import ForgetPassword from '@/components/areas/login/ForgetPassword'
export default {
  mixins: [AdminApi],
  components: {
    FormWithValidationBlock,
    LoginForm,
    ForgetPassword,
  },
  data: () => ({
    loading: false,
    account: {
      email: '',
      password: '',
    },
  }),
  methods: {
    login() {
      this.loading = true
      this.axios
        .post(this.API.auth.sign_in, this.account)
        .then((res) => {
          this.$store.dispatch('loginAdmin', res.data.admin)
          this.$router.push({ name: this.$$adminDefaultPagePathName() })
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  metaInfo() {
    return this.$t(`meta_tags.${this.$route.name}`)
  },
}
</script>
