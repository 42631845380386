<template>
  <div>
    <v-text-field
      :value="formattedValue"
      :label="label"
      :error-messages="errorMessages"
      v-bind="textFieldOptions"
      autocomplete="off"
      @keydown.prevent
      @click="click"
      @click:clear="clear"
      @click:append="click"
    />
    <vue-datetime
      ref="VueDatetime"
      v-model="internalValue"
      input-class="d-none"
      :type="type"
      :phrases="{ ok: 'ok', cancel: 'cancel' }"
      :week-start="7"
    />
  </div>
</template>

<script>
// ----------------------------------------------------
// https://vuetifyjs.com/en/api/v-text-field/#props
// https://github.com/mariomka/vue-datetime
// ----------------------------------------------------
export default {
  props: {
    value: { required: true },
    label: { type: String, default: '' },
    errorMessages: { type: Array, default: () => [] },
    type: { type: String, default: 'datetime' },
    textFieldOptions: { type: Object, default: () => ({}) },
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    formattedValue() {
      if (!this.internalValue) {
        return
      }
      let format = 'lll'
      switch (this.type) {
        case 'datetime':
          format = 'lll'
          break
        case 'date':
          format = 'll'
          break
        case 'time':
          format = 'LT'
          break
      }
      return this.$options.filters.momentFormat(this.internalValue, format)
    },
  },
  methods: {
    click() {
      this.$refs.VueDatetime.isOpen = true
    },
    clear() {
      this.internalValue = null
    },
  },
}
</script>

<style scoped></style>
