<template>
  <v-form @submit.prevent="$emit('search')">
    <v-row>
      <v-col cols="12" md="3">
        <v-text-field
          v-model="internalValue.name_or_hira"
          :label="$t('search.users.name_or_hira')"
          outlined
          dense
          hide-details
          clearable
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          v-model="internalValue.organization_or_hira"
          :label="$t('search.users.organization_or_hira')"
          outlined
          dense
          hide-details
          clearable
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          v-model="internalValue.email"
          :label="$$columnName('user.email')"
          outlined
          dense
          hide-details
          clearable
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          v-model="internalValue.phone"
          :label="$$columnName('user/profile.phone')"
          outlined
          dense
          hide-details
          clearable
        />
      </v-col>
      <v-col cols="12" md="3">
        <select-dialog-search-lom
          v-model="internalValue.lom_id"
          :loms="loms"
          :select-options="{
            label: $$tableName('lom'),
            outlined: true,
            dense: true,
            hideDetails: true,
            clearable: true,
          }"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-select
          v-model="internalValue.join_type"
          :label="$$columnName('user/profile.join_type')"
          :items="select.joinType"
          outlined
          dense
          hide-details
          clearable
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-select
          v-model="internalValue.is_visit_venue"
          :label="$t('label.is_visit_venue')"
          :items="select.isVisitVenue"
          outlined
          dense
          hide-details
          clearable
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-select
          v-model="internalValue.provider"
          :label="$$columnName('user.provider')"
          :items="select.provider"
          outlined
          dense
          hide-details
          clearable
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-select
          v-model="internalValue.user_type"
          :label="$$columnName('user/profile.user_type')"
          :items="select.userType"
          outlined
          dense
          hide-details
          clearable
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-select
          v-model="internalValue.attended_user_type"
          :label="$$columnName('users_conference.attended_user_type')"
          :items="select.userType"
          outlined
          dense
          hide-details
          clearable
        />
      </v-col>
      <v-col v-if="isAdmin" cols="12" md="3">
        <v-select
          v-model="internalValue.account_type"
          :label="$$columnName('user/profile.account_type')"
          :items="select.accountType"
          outlined
          dense
          hide-details
          clearable
        />
      </v-col>
      <v-col v-else cols="12" md="3">
        <v-select
          v-model="internalValue.conference_account_status"
          :label="$$columnName('users_conference.conference_account_status')"
          :items="select.conferenceAccountStatus"
          outlined
          dense
          hide-details
          clearable
        />
      </v-col>
      <v-col v-if="isAdmin" cols="12" md="3">
        <v-select
          v-model="internalValue.jci_member_availability"
          :label="$$columnName('user/profile.jci_member_availability')"
          :items="select.jciMemberAvailability"
          :hint="$t('search.users/profile.jci_member_availability')"
          outlined
          persistent-hint
          dense
          clearable
        />
      </v-col>
      <v-col cols="12" md="12" align="right">
        <v-btn type="submit" outlined>
          {{ $t('button.search') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import SelectDialogSearchLom from '@/components/parts/Select/Dialog/SearchLom'
export default {
  components: {
    SelectDialogSearchLom,
  },
  props: {
    value: { type: Object, required: true },
    loms: { type: Array, required: true },
    isAdmin: { type: Boolean, default: false },
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    select() {
      return {
        userType: this.$$selectEnums('user_type'),
        joinType: this.$$selectEnums('join_type'),
        accountType: this.$$selectEnums('account_type'),
        conferenceAccountStatus: this.$$selectEnums(
          'conference_account_status'
        ),
        isVisitVenue: this.$$selectEnums('is_visit_venue'),
        provider: this.$$selectEnums('provider'),
        jciMemberAvailability: this.$$selectEnums('jci_member_availability'),
      }
    },
  },
}
</script>
