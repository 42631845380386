<template>
  <v-card :max-width="500">
    <v-simple-table class="mouse-over-highlight-disabled">
      <tbody>
        <tr>
          <td>{{ $$columnName('manager.email') }}</td>
          <td>{{ manager.email }}</td>
        </tr>
        <tr>
          <td>{{ $$columnName('manager.created_at') }}</td>
          <td>{{ manager.created_at | momentFormat('lll') }}</td>
        </tr>
        <tr>
          <td>{{ $$columnName('manager.is_lom_representative') }}</td>
          <td>{{ manager.is_lom_representative }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-divider class="mb-4" />
    <v-card-actions>
      <v-row>
        <v-col cols="12" md="12" align="right">
          <btn-dialog-destroy
            :btn-props="{ class: 'mr-2', color: 'error' }"
            @destroy="$emit('click:destroy')"
          />
          <v-btn color="primary" @click="$emit('click:edit')">
            {{ $t('button.edit') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>
<script>
import BtnDialogDestroy from '@/components/parts/Btn/Dialog/Destroy'
export default {
  components: {
    BtnDialogDestroy,
  },
  props: {
    manager: { type: Object, required: true },
    loading: { type: Boolean, required: true },
  },
}
</script>
