<template>
  <div>
    <selected-conference-info />
    <page-title
      class="mb-4"
      @click:index="$router.push({ name: 'user-programs-index' })"
    />
    <page-detail
      :program="program"
      :loading="loading"
      @play:vimeo="audiencesCount(program)"
      @open:zoom="openZoom(program)"
    />
  </div>
</template>
<script>
import UserApi from '@/api/users'
import SelectedConferenceInfo from '@/components/areas/users/Card/SelectedConferenceInfo'
import PageTitle from '@/components/areas/common/PageTitleWithIndexButton'
import PageDetail from './Show/Detail'
export default {
  mixins: [UserApi],
  components: {
    SelectedConferenceInfo,
    PageTitle,
    PageDetail,
  },
  data: () => ({
    loading: false,
    program: {
      title: '',
      desc: '',
      teachers: [],
      committie: '',
      venue: {},
      answer_form_url: '',
      st_at: '',
      ed_at: '',
      archive_st_at: '',
      archive_ed_at: '',
      participation_type: '',
    },
  }),
  created() {
    this.getProgram()
  },
  methods: {
    getProgram() {
      this.loading = true
      this.axios
        .get(this.API.programs.show)
        .then((res) => {
          this.program = res.data.program
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
    audiencesCount(program) {
      if (program.participation_type === 'local') return

      this.axios
        .post(
          this.APIwithId({ programId: program.id }).audiences_counts.create,
          { participation_type: 'web' }
        )
        .then(() => {
          this.getProgram()
        })
    },
    openZoom(program) {
      window.open(program.zoom_url, '_blank')
      this.audiencesCount(program)
    },
  },
  metaInfo() {
    return this.$t(`meta_tags.${this.$route.name}`)
  },
}
</script>
