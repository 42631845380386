<template>
  <v-card>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="conferences"
      hide-default-footer
      :items-per-page="-1"
      @click:row="clickRow"
    >
      <template #[`item.publication_at`]="{ item }">
        {{ item.publication_at | momentFormat('lll') }}
      </template>
      <template #[`item.schedule_st_at`]="{ item }">
        {{ item.schedule_st_at | momentFormat('lll') }}
      </template>
      <template #[`item.schedule_ed_at`]="{ item }">
        {{ item.schedule_ed_at | momentFormat('lll') }}
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
export default {
  props: {
    loading: { type: Boolean, required: true },
    conferences: { type: Array, required: true },
  },
  data: () => ({
    headers: [],
  }),
  created() {
    this.headers = [
      {
        text: this.$$columnName('conference.title'),
        value: 'title',
      },
      { text: this.$$columnName('conference.email'), value: 'email' },
      {
        text: this.$$columnName('conference.publication_at'),
        value: 'publication_at',
      },
      {
        text: this.$$columnName('conference.schedule_st_at'),
        value: 'schedule_st_at',
      },
      {
        text: this.$$columnName('conference.schedule_ed_at'),
        value: 'schedule_ed_at',
      },
    ]
  },
  methods: {
    clickRow(item) {
      this.$emit('click:row', item)
    },
  },
}
</script>
