<template>
  <v-card>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="loms"
      disable-sort
      @click:row="clickRow"
    >
      <template #item.block="{ item }">
        {{ $$getEnumText('block', item.block) }}
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
export default {
  props: {
    loading: { type: Boolean, required: true },
    loms: { type: Array, required: true },
  },
  data: () => ({
    headers: [],
  }),
  created() {
    this.headers = [
      { text: this.$$columnName('lom.name'), value: 'name' },
      { text: this.$$columnName('lom.name_hira'), value: 'name_hira' },
      { text: this.$$columnName('lom.block'), value: 'block' },
      { text: this.$$columnName('lom.lom_code'), value: 'lom_code' },
    ]
  },
  methods: {
    clickRow(item) {
      this.$emit('click:row', item)
    },
  },
}
</script>
