var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$$columnName('program.title'),"rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$$columnName('program.title'),"error-messages":errors,"outlined":""},model:{value:(_vm.internalValue.title),callback:function ($$v) {_vm.$set(_vm.internalValue, "title", $$v)},expression:"internalValue.title"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$$columnName('program.desc'),"rules":"max:1000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":_vm.$$columnName('program.desc'),"error-messages":errors,"outlined":"","auto-grow":"","rows":3,"counter":"1000"},model:{value:(_vm.internalValue.desc),callback:function ($$v) {_vm.$set(_vm.internalValue, "desc", $$v)},expression:"internalValue.desc"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$$tableName('teacher'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":_vm.$$tableName('teacher'),"error-messages":errors,"items":_vm.selectTeachers,"outlined":"","loading":_vm.loadingTeachers,"multiple":"","chips":""},model:{value:(_vm.internalValue.teacher_ids),callback:function ($$v) {_vm.$set(_vm.internalValue, "teacher_ids", $$v)},expression:"internalValue.teacher_ids"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$$columnName('program.committie'),"rules":"max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$$columnName('program.committie'),"error-messages":errors,"outlined":""},model:{value:(_vm.internalValue.committie),callback:function ($$v) {_vm.$set(_vm.internalValue, "committie", $$v)},expression:"internalValue.committie"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$$columnName('program.venue_id'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":_vm.$$columnName('program.venue_id'),"error-messages":errors,"items":_vm.selectVenues,"outlined":"","loading":_vm.loadingVenues},model:{value:(_vm.internalValue.venue_id),callback:function ($$v) {_vm.$set(_vm.internalValue, "venue_id", $$v)},expression:"internalValue.venue_id"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"word-breaker red--text"},[_vm._v(" "+_vm._s(_vm.$t('program.only_zoom_url_or_vimeo_html_tag'))+" ")])]),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$$columnName('program.zoom_url'),"rules":"max:2048|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$$columnName('program.zoom_url'),"error-messages":errors,"outlined":""},model:{value:(_vm.internalValue.zoom_url),callback:function ($$v) {_vm.$set(_vm.internalValue, "zoom_url", $$v)},expression:"internalValue.zoom_url"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$$columnName('program.vimeo_html_tag'),"rules":"max:3000|vimeo_html_tag"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$$columnName('program.vimeo_html_tag'),"error-messages":errors,"outlined":""},model:{value:(_vm.internalValue.vimeo_html_tag),callback:function ($$v) {_vm.$set(_vm.internalValue, "vimeo_html_tag", $$v)},expression:"internalValue.vimeo_html_tag"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$$columnName('program.answer_form_url'),"rules":"max:2048|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$$columnName('program.answer_form_url'),"error-messages":errors,"outlined":""},model:{value:(_vm.internalValue.answer_form_url),callback:function ($$v) {_vm.$set(_vm.internalValue, "answer_form_url", $$v)},expression:"internalValue.answer_form_url"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$$columnName('program.st_at'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('datetime',{attrs:{"label":_vm.$$columnName('program.st_at'),"error-messages":errors,"text-field-options":{
          outlined: true,
          clearable: true,
          appendIcon: 'mdi-calendar',
        }},model:{value:(_vm.internalValue.st_at),callback:function ($$v) {_vm.$set(_vm.internalValue, "st_at", $$v)},expression:"internalValue.st_at"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$$columnName('program.ed_at'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('datetime',{attrs:{"label":_vm.$$columnName('program.ed_at'),"error-messages":errors,"text-field-options":{
          outlined: true,
          clearable: true,
          appendIcon: 'mdi-calendar',
        }},model:{value:(_vm.internalValue.ed_at),callback:function ($$v) {_vm.$set(_vm.internalValue, "ed_at", $$v)},expression:"internalValue.ed_at"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"archive_st_at","name":_vm.$$columnName('program.archive_st_at'),"rules":"archivePeriod:@archive_ed_at"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('datetime',{attrs:{"label":_vm.$$columnName('program.archive_st_at'),"error-messages":errors,"text-field-options":{
          outlined: true,
          clearable: true,
          appendIcon: 'mdi-calendar',
        }},model:{value:(_vm.internalValue.archive_st_at),callback:function ($$v) {_vm.$set(_vm.internalValue, "archive_st_at", $$v)},expression:"internalValue.archive_st_at"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"archive_ed_at","name":_vm.$$columnName('program.archive_ed_at'),"rules":"archivePeriod:@archive_st_at"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('datetime',{attrs:{"label":_vm.$$columnName('program.archive_ed_at'),"error-messages":errors,"text-field-options":{
          outlined: true,
          clearable: true,
          appendIcon: 'mdi-calendar',
        }},model:{value:(_vm.internalValue.archive_ed_at),callback:function ($$v) {_vm.$set(_vm.internalValue, "archive_ed_at", $$v)},expression:"internalValue.archive_ed_at"}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }