<template>
  <div>
    <page-title
      class="mb-4"
      @click:index="$router.push({ name: 'admin-conferences-index' })"
    />
    <page-detail
      :conference="conference"
      :loading="loading"
      editable
      manager-editable
      destroyable
      @click:edit="$router.push({ name: 'admin-conferences-edit' })"
      @click:destroy="destroy"
      @edit:manager="toEditManager"
    />
  </div>
</template>
<script>
import AdminApi from '@/api/admins'
import PageTitle from '@/components/areas/common/PageTitleWithIndexButton'
import PageDetail from '@/components/areas/conferences/Show/Detail'
export default {
  mixins: [AdminApi],
  components: {
    PageTitle,
    PageDetail,
  },
  data: () => ({
    loading: false,
    conference: {
      title: '',
      desc: '',
      managers: [],
      email: '',
      schedule_st_at: '',
      schedule_ed_at: '',
      image: {},
      welcome_image: {},
    },
  }),
  created() {
    this.getConference()
  },
  methods: {
    getConference() {
      this.loading = true
      this.axios
        .get(this.API.conferences.show)
        .then((res) => {
          this.conference = res.data.conference
        })
        .catch((err) => {
          if (err.response.status !== 422) return
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
    destroy() {
      this.loading = true
      this.axios
        .delete(this.API.conferences.destroy)
        .then(() => {
          this.$router.push({ name: 'admin-conferences-index' })
          this.$toasted.success(this.$t('success.destroy'))
        })
        .catch((err) => {
          if (err.response.status !== 422) return
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
    toEditManager(managerId) {
      this.$router.push({
        name: 'admin-managers-edit',
        params: { managerId },
      })
    },
  },
  metaInfo() {
    return this.$t(`meta_tags.${this.$route.name}`)
  },
}
</script>
