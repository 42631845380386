<template>
  <v-dialog v-model="dialog" :max-width="800">
    <template v-slot:activator="{ on, attrs }">
      <v-sheet v-bind="attrs" v-on="on">
        <v-select
          v-model="internalValue"
          v-bind="selectOptions"
          :items="loms"
          item-text="name"
          item-value="id"
          readonly
          @change="$emit('change')"
          @blur="$emit('blur')"
        />
      </v-sheet>
      <!-- change and blue is for vee-validate -->
    </template>
    <v-card>
      <v-card-title>
        {{ $t('dialog.search.lom.title') }}
      </v-card-title>
      <v-divider class="mb-4" />
      <v-card-text>
        <index-search class="mb-4" v-model="search" />
        <index-table v-model="selectedLom" :search="search" :loms="loms" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="dialog = false">
          {{ $t('button.cancel') }}
        </v-btn>
        <v-btn color="primary" @click="decide">
          {{ $t('dialog.search.lom.button') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import IndexSearch from './SearchLom/Search'
import IndexTable from './SearchLom/Table'
export default {
  components: {
    IndexSearch,
    IndexTable,
  },
  props: {
    value: { type: String, default: null },
    loms: { type: Array, required: true },
    selectOptions: { type: Object, default: () => ({}) },
  },
  data: () => ({
    dialog: false,
    search: {
      keyword: '',
      block: '',
    },
    selectedLom: '',
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    selectBlocks() {
      return this.$$selectEnums('block')
    },
    filteredLoms() {
      return this.loms.filter((lom) => {
        return lom.block === this.search.block
      })
    },
    isRegular() {
      return this.internalValue.user_type === 'regular'
    },
  },
  methods: {
    decide() {
      this.internalValue = this.selectedLom
      this.dialog = false
    },
  },
}
</script>
