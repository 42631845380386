<template>
  <div>
    <selected-conference-info />
    <page-title
      class="mb-4"
      @click:index="$router.push({ name: 'manager-programs-index' })"
    />
    <v-card :loading="loading">
      <v-card-title></v-card-title>
      <v-card-text>
        <form-with-validation :loading="loading" @click:save="save">
          <entry-form
            v-model="program"
            :loading-teachers="loadingTeachers"
            :loading-venues="loadingVenues"
            :select-teachers="selectTeachers"
            :select-venues="selectVenues"
          />
        </form-with-validation>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import ManagerApi from '@/api/managers'
import SelectedConferenceInfo from '@/components/areas/managers/Card/SelectedConferenceInfo'
import PageTitle from '@/components/areas/common/PageTitleWithIndexButton'
import FormWithValidation from '@/components/areas/common/FormWithValidation'
import EntryForm from './Form/EntryForm'
export default {
  mixins: [ManagerApi],
  components: {
    SelectedConferenceInfo,
    PageTitle,
    FormWithValidation,
    EntryForm,
  },
  data: () => ({
    loading: false,
    loadingTeachers: false,
    loadingVenues: false,
    program: {
      title: '',
      desc: '',
      teacher_ids: [],
      committie: '',
      venue_id: '',
      zoom_url: '',
      answer_form_url: '',
      vimeo_html_tag: '',
      st_at: '',
      ed_at: '',
      archive_st_at: '',
      archive_ed_at: '',
    },
    selectTeachers: [],
    selectVenues: [],
  }),
  created() {
    this.getTeachers()
    this.getVenues()
    if (this.isNewRecord) {
      return
    }
    this.getProgram()
  },
  computed: {
    isNewRecord() {
      return !this.$route.params.programId
    },
  },
  methods: {
    getProgram() {
      this.loading = true
      this.axios
        .get(this.API.programs.show)
        .then((res) => {
          const teacherIds = res.data.program.teachers.map(
            (teacher) => teacher.id
          )
          this.program = res.data.program
          this.$set(this.program, 'teacher_ids', teacherIds)
          this.$set(this.program, 'venue_id', res.data.program.venue.id)
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
    getTeachers() {
      this.loadingTeachers = true
      this.axios
        .get(this.API.teachers.index)
        .then((res) => {
          this.selectTeachers = res.data.teachers.map((teacher) => {
            return { text: teacher.name, value: teacher.id }
          })
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loadingTeachers = false
        })
    },
    getVenues() {
      this.loadingVenues = true
      this.axios
        .get(this.API.venues.index)
        .then((res) => {
          this.selectVenues = res.data.venues.map((venue) => {
            return { text: venue.title, value: venue.id }
          })
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loadingVenues = false
        })
    },
    save() {
      this.loading = true
      const path = this.isNewRecord
        ? this.API.programs.create
        : this.API.programs.update
      const saveAxios = this.isNewRecord ? this.axios.post : this.axios.patch
      saveAxios(path, { program: this.program })
        .then((res) => {
          this.$router.push({
            name: 'manager-programs-show',
            params: { programId: res.data.program.id },
          })
          this.$toasted.success(this.$t('success.create'))
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  metaInfo() {
    return this.$t(`meta_tags.${this.$route.name}`)
  },
}
</script>
