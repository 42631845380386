<template>
  <v-card>
    <v-card-title>{{ $t('label.multi_update') }}</v-card-title>
    <v-card-subtitle>
      {{ $t('unit.people', { number: usersCount }) }}
      {{ $t('label.selecting') }}
    </v-card-subtitle>
    <v-card-text>
      <btn-dialog-simple
        :dialog-type="account_ban_or_invalid"
        :btn-props="{
          class: 'mr-2',
          color: 'error',
          loading: loading,
          disabled: disableButton,
        }"
        @click:save="ban"
      />
      <btn-dialog-simple
        dialog-type="account_active"
        :btn-props="{
          color: 'primary',
          loading: loading,
          disabled: disableButton,
        }"
        @click:save="active"
      />
    </v-card-text>
  </v-card>
</template>
<script>
import BtnDialogSimple from '@/components/parts/Btn/Dialog/Simple'
export default {
  components: {
    BtnDialogSimple,
  },
  props: {
    loading: { type: Boolean, required: true },
    selectedUserIds: { type: Array, required: true },
    isAdmin: { type: Boolean, default: false },
  },
  computed: {
    account_ban_or_invalid() {
      return this.isAdmin ? 'account_ban' : 'account_invalid'
    },
    usersCount() {
      return this.selectedUserIds.length
    },
    disableButton() {
      return this.usersCount === 0
    },
  },
  methods: {
    ban() {
      if (this.loading) {
        return
      }
      this.$emit('click:ban')
    },
    active() {
      if (this.loading) {
        return
      }
      this.$emit('click:active')
    },
  },
}
</script>
