var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('label.new_password.current_password'),"rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-field-password',{attrs:{"text-field-options":{
          label: _vm.$t('label.new_password.current_password'),
          errorMessages: errors,
          outlined: true,
        }},model:{value:(_vm.internalValue.current_password),callback:function ($$v) {_vm.$set(_vm.internalValue, "current_password", $$v)},expression:"internalValue.current_password"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"vid":"password","name":_vm.$t('label.new_password.password'),"rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('text-field-password',{attrs:{"text-field-options":{
          label: _vm.$t('label.new_password.password'),
          errorMessages: errors,
          outlined: true,
        }},model:{value:(_vm.internalValue.password),callback:function ($$v) {_vm.$set(_vm.internalValue, "password", $$v)},expression:"internalValue.password"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('label.new_password.password_confirmation'),"rules":"required|min:8|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('text-field-password',{attrs:{"text-field-options":{
          label: _vm.$t('label.new_password.password_confirmation'),
          errorMessages: errors,
          outlined: true,
        }},model:{value:(_vm.internalValue.password_confirmation),callback:function ($$v) {_vm.$set(_vm.internalValue, "password_confirmation", $$v)},expression:"internalValue.password_confirmation"}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }