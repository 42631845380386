<template>
  <v-sheet align="center">
    <v-card class="pa-4">
      <v-icon class="mb-4" size="100"> mdi-alert-circle-outline </v-icon>
      <div class="word-breaker">{{ displayMessage }}</div>
    </v-card>
  </v-sheet>
</template>
<script>
export default {
  props: {
    message: { type: String, default: '' },
  },
  computed: {
    displayMessage() {
      return this.message || this.$t('errors.notfound')
    },
  },
}
</script>
