import { render, staticRenderFns } from "./PageTitleWithIndexButton.vue?vue&type=template&id=7d5ed98c&"
import script from "./PageTitleWithIndexButton.vue?vue&type=script&lang=js&"
export * from "./PageTitleWithIndexButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VListItemAction } from 'vuetify/lib/components/VList';
installComponents(component, {VBtn,VListItemAction})
