<template>
  <div>
    <selected-conference-info />
    <page-title
      class="mb-4"
      @click:index="$router.push({ name: 'manager-teachers-index' })"
    />
    <v-card :loading="loading" :max-width="500">
      <v-card-title></v-card-title>
      <v-card-text>
        <form-with-validation :loading="loading" @click:save="save">
          <entry-form v-model="teacher" />
        </form-with-validation>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import ManagerApi from '@/api/managers'
import SelectedConferenceInfo from '@/components/areas/managers/Card/SelectedConferenceInfo'
import PageTitle from '@/components/areas/common/PageTitleWithIndexButton'
import FormWithValidation from '@/components/areas/common/FormWithValidation'
import EntryForm from './Form/EntryForm'
export default {
  mixins: [ManagerApi],
  components: {
    SelectedConferenceInfo,
    PageTitle,
    FormWithValidation,
    EntryForm,
  },
  data: () => ({
    loading: false,
    teacher: {
      name: '',
      name_hira: '',
      desc: '',
      image: {},
      remove_image: false,
    },
  }),
  created() {
    if (this.isNewRecord) {
      return
    }
    this.getTeacher()
  },
  watch: {
    'teacher.image'(val) {
      this.teacher.remove_image = Object.keys(val).length === 0
    },
  },
  computed: {
    isNewRecord() {
      return !this.$route.params.teacherId
    },
  },
  methods: {
    getTeacher() {
      this.loading = true
      this.axios
        .get(this.API.teachers.show)
        .then((res) => {
          this.teacher = res.data.teacher
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
    save() {
      this.loading = true
      const path = this.isNewRecord
        ? this.API.teachers.create
        : this.API.teachers.update
      const saveAxios = this.isNewRecord ? this.axios.post : this.axios.patch
      saveAxios(path, this.$$castToFormData({ teacher: this.teacher }), {
        headers: { 'content-type': 'multipart/form-data' },
      })
        .then((res) => {
          this.$router.push({
            name: 'manager-teachers-show',
            params: { teacherId: res.data.teacher.id },
          })
          this.$toasted.success(this.$t('success.create'))
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  metaInfo() {
    return this.$t(`meta_tags.${this.$route.name}`)
  },
}
</script>
