import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import ja from 'vuetify/es5/locale/ja'
import i18n from './vue-i18n'

Vue.use(Vuetify)

const options = {
  lang: {
    locales: { ja },
    current: i18n.locale,
  },
}
export default new Vuetify(options)
