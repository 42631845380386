<template>
  <div class="pa-10">
    <v-row>
      <v-col cols="12" align="center">
        <h1>404</h1>
      </v-col>
      <v-col cols="12" align="center">
        <h2>PAGE NOT FOUND</h2>
      </v-col>
      <v-col cols="12" align="center"> ページが見つかりませんでした </v-col>
      <v-col cols="12" align="center">
        <v-btn @click="$router.push({ name: pathName })" outlined
          >トップへ</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  computed: {
    pathName() {
      if (this.$store.getters.admin) {
        return this.$$adminDefaultPagePathName()
      } else if (this.$store.getters.manager) {
        return this.$$managerDefaultPagePathName(
          this.$store.getters.conference.id
        )
      } else if (this.$store.getters.user) {
        return this.$$userDefaultPagePathName()
      } else {
        return 'top'
      }
    },
  },
  metaInfo() {
    return this.$t(`meta_tags.${this.$route.name}`)
  },
}
</script>
