<template>
  <div>
    <page-title class="mb-4" />
    <page-detail
      :user="user"
      :loading="loading"
      editable
      is-user
      hide-account-type
      hide-conference-account-status
      @click:edit="$router.push({ name: 'user-accounts-edit' })"
    />
    <v-sheet v-if="this.user.provider !== 'jci_member_app'" :max-width="500">
      <change-password
        class="mt-4"
        @click="$router.push({ name: 'user-settings-password' })"
      />
    </v-sheet>
  </div>
</template>
<script>
import UserApi from '@/api/users'
import PageTitle from '@/components/areas/common/PageTitle'
import PageDetail from '@/components/areas/users/Show/Detail'
import ChangePassword from './Show/ChangePassword'
export default {
  mixins: [UserApi],
  components: {
    PageTitle,
    PageDetail,
    ChangePassword,
  },
  data: () => ({
    loading: false,
    user: {
      id: '',
      email: '',
      provider: '',
      profile: {
        name: '',
        name_hira: '',
        organization: '',
        organization_hira: '',
        phone: '',
        birthday: '',
        user_type: '',
        join_type: '',
        lom: {
          name: '',
        },
      },
    },
  }),
  created() {
    this.getUser()
  },
  methods: {
    getUser() {
      this.loading = true
      this.axios
        .get(this.API.users.show)
        .then((res) => {
          this.user = res.data.user
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  metaInfo() {
    return this.$t(`meta_tags.${this.$route.name}`)
  },
}
</script>
