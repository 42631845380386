<template>
  <v-card>
    <v-data-table
      v-model="selectedJciPayments"
      :loading="loading"
      :headers="headers"
      :items="jciPayments"
      disable-sort
      show-select
      :server-items-length="totalCount"
      :page="tableOptions.page"
      :items-per-page="tableOptions.per"
      @click:row="clickRow"
      @update:options="$emit('update:options', $event)"
    />
  </v-card>
</template>
<script>
export default {
  props: {
    value: { type: Array, required: true },
    loading: { type: Boolean, required: true },
    jciPayments: { type: Array, required: true },
    totalCount: { type: Number, required: true },
    tableOptions: { type: Object, required: true },
  },
  data: () => ({
    headers: [],
    selectedJciPayments: [],
  }),
  created() {
    this.headers = [
      { text: this.$$columnName('jci_payment.name'), value: 'name' },
      {
        text: this.$$columnName('jci_payment.member_number'),
        value: 'member_number',
      },
    ]
  },
  watch: {
    selectedJciPayments(value) {
      this.$emit(
        'input',
        value.map((list) => list.id)
      )
    },
  },
  methods: {
    clickRow(_, { select, isSelected }) {
      select(!isSelected)
    },
  },
}
</script>
