<template>
  <div>
    <page-title
      class="mb-4"
      @click:index="$router.push({ name: 'admin-managers-index' })"
    />
    <page-detail
      :manager="manager"
      :loading="loading"
      @click:edit="
        $router.push({
          name: 'admin-managers-edit',
          params: { managerId: manager.id },
        })
      "
      @click:destroy="destroy"
    />
  </div>
</template>
<script>
import AdminApi from '@/api/admins'
import PageTitle from '@/components/areas/common/PageTitleWithIndexButton'
import PageDetail from './Show/Detail'
export default {
  mixins: [AdminApi],
  components: {
    PageTitle,
    PageDetail,
  },
  data: () => ({
    loading: false,
    manager: {
      id: '',
      name: '',
      email: '',
    },
  }),
  created() {
    this.getManager()
  },
  methods: {
    getManager() {
      this.loading = true
      this.axios
        .get(this.API.managers.show)
        .then((res) => {
          this.manager = res.data.manager
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
    destroy() {
      this.loading = true
      this.axios
        .delete(this.API.managers.destroy)
        .then(() => {
          this.$router.push({ name: 'admin-managers-index' })
          this.$toasted.success(this.$t('success.destroy'))
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  metaInfo() {
    return this.$t(`meta_tags.${this.$route.name}`)
  },
}
</script>
