<template>
  <div>
    <v-row>
      <v-col>
        <v-img :max-width="400" :src="require('@/assets/logo_header.webp')" />
      </v-col>
    </v-row>
    <top-ready
      class="mb-4"
      @click:login="$router.push({ name: 'user-login' })"
      @click:loginJci="$router.push({ name: 'user-login-jci' })"
    />
    <global-footer />
  </div>
</template>
<script>
import TopReady from './Top/Ready'
import GlobalFooter from '@/components/globals/Footer'
export default {
  components: {
    TopReady,
    GlobalFooter,
  },
  metaInfo() {
    return this.$t(`meta_tags.${this.$route.name}`)
  },
}
</script>
