<template>
  <v-card>
    <v-data-table
      id="venues-table"
      :loading="loading"
      :headers="headers"
      :items="venues"
      disable-sort
      hide-default-footer
      :items-per-page="-1"
      @click:row="$emit('click:row', $event)"
    >
      <template #[`footer`]="{ props }">
        <v-divider></v-divider>
        <v-sheet class="py-4 mr-8" align="right">
          {{ props.pagination.itemsLength }}件
        </v-sheet>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
export default {
  props: {
    loading: { type: Boolean, required: true },
    venues: { type: Array, required: true },
  },
  data: () => ({
    headers: [],
  }),
  created() {
    this.headers = [{ text: this.$$columnName('venue.title'), value: 'title' }]
  },
}
</script>
