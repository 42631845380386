<template>
  <v-app>
    <global-header v-if="displayHeaderAndDrawer" v-model="drawer" />
    <global-drawer v-if="displayHeaderAndDrawer" v-model="drawer" />
    <v-main>
      <v-container fluid>
        <router-view class="pa-md-10 pa-2 main-area-height" />
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import GlobalHeader from '@/components/globals/Header'
import GlobalDrawer from '@/components/globals/Drawer'
export default {
  components: {
    GlobalHeader,
    GlobalDrawer,
  },
  data: () => ({
    drawer: null,
  }),
  computed: {
    displayHeaderAndDrawer() {
      const rejectPages = [
        'user-from-qrcode',
        'user-accounts-stop',
        'user-accounts-invalid',
      ]
      const isRejectPage = rejectPages.includes(this.$route.name)
      return this.$store.getters.isSignIn && !isRejectPage
    },
  },
  beforeCreate() {
    const existingAuth = this.$cookies.get('authInfos')
    if (existingAuth) {
      this.$store.dispatch('setAuth', existingAuth.tokens)
      if (existingAuth.admin) {
        this.$store.dispatch('loginAdmin', existingAuth.admin)
      }
      if (existingAuth.manager) {
        const existingConferenceId = this.$cookies.get('conferenceId')
        this.$store.dispatch('loginManager', existingAuth.manager)
        this.$store.dispatch('setConferenceId', existingConferenceId)
      }
      if (existingAuth.user) {
        const existingConferenceId = this.$cookies.get('conferenceId')
        const existingVisitVenueAt = this.$cookies.get('visitVenueAt')
        this.$store.dispatch('loginUser', existingAuth.user)
        this.$store.dispatch('setConferenceId', existingConferenceId)
        this.$store.dispatch('setVisitVenueAt', existingVisitVenueAt)
      }
    }
  },
  metaInfo() {
    return {
      titleTemplate: (titleChunk) =>
        titleChunk ? `${titleChunk} - JCI` : 'JCI',
      link: [
        { hid: 'robots', property: 'robots', content: 'noindex, nofollow' },
        { hid: 'googlebot', property: 'googlebot', content: 'noindex' },
      ],
    }
  },
}
</script>
