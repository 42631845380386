var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[(_vm.isAdmin)?[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$$columnName('user/profile.user_type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":_vm.$$columnName('user/profile.user_type'),"error-messages":errors,"items":_vm.select.userType,"outlined":""},model:{value:(_vm.internalValue.user_type),callback:function ($$v) {_vm.$set(_vm.internalValue, "user_type", $$v)},expression:"internalValue.user_type"}})]}}],null,false,3817481143)})],1)]:_vm._e(),(_vm.isUserType.REGULAR && !_vm.isJcProvider)?[_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"outlined":"","type":"warning","prominent":""}},[_c('div',{staticClass:"word-breaker"},[_vm._v(" "+_vm._s(_vm.$t('description.signup.invoice'))+" ")])])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{key:"user/profile.lom_id",attrs:{"name":_vm.$$columnName('user/profile.lom_id'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select-dialog-search-lom',{attrs:{"loms":_vm.loms,"select-options":{
            label: _vm.$$columnName('user/profile.lom_id'),
            loading: _vm.loadingLoms,
            errorMessages: errors,
            outlined: true,
          }},model:{value:(_vm.internalValue.lom_id),callback:function ($$v) {_vm.$set(_vm.internalValue, "lom_id", $$v)},expression:"internalValue.lom_id"}})]}}],null,false,2781339583)})],1)]:_vm._e(),(!_vm.isJcProvider)?[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{key:"user/profile.name",attrs:{"name":_vm.$$columnName('user/profile.name'),"rules":"required|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$$columnName('user/profile.name'),"error-messages":errors,"outlined":""},model:{value:(_vm.internalValue.name),callback:function ($$v) {_vm.$set(_vm.internalValue, "name", $$v)},expression:"internalValue.name"}})]}}],null,false,606149330)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{key:"user/profile.name_hira",attrs:{"name":_vm.$$columnName('user/profile.name_hira'),"rules":"required|max:30|zenHira"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$$columnName('user/profile.name_hira'),"error-messages":errors,"outlined":""},model:{value:(_vm.internalValue.name_hira),callback:function ($$v) {_vm.$set(_vm.internalValue, "name_hira", $$v)},expression:"internalValue.name_hira"}})]}}],null,false,4008400178)})],1)]:_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{key:"user/profile.organization",attrs:{"name":_vm.$$columnName('user/profile.organization'),"rules":_vm.validationOrganization},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$$columnName('user/profile.organization'),"error-messages":errors,"outlined":"","hint":_vm.$t('placeholder.organization'),"persistent-hint":""},model:{value:(_vm.internalValue.organization),callback:function ($$v) {_vm.$set(_vm.internalValue, "organization", $$v)},expression:"internalValue.organization"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{key:"user/profile.organization_hira",attrs:{"name":_vm.$$columnName('user/profile.organization_hira'),"rules":_vm.validationOrganizationHira},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$$columnName('user/profile.organization_hira'),"error-messages":errors,"outlined":"","hint":_vm.$t('placeholder.organization'),"persistent-hint":""},model:{value:(_vm.internalValue.organization_hira),callback:function ($$v) {_vm.$set(_vm.internalValue, "organization_hira", $$v)},expression:"internalValue.organization_hira"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{key:"user/profile.phone",attrs:{"name":_vm.$$columnName('user/profile.phone'),"rules":_vm.validationPhone},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$$columnName('user/profile.phone'),"error-messages":errors,"outlined":"","hint":_vm.$t('placeholder.phone'),"persistent-hint":""},model:{value:(_vm.internalValue.phone),callback:function ($$v) {_vm.$set(_vm.internalValue, "phone", $$v)},expression:"internalValue.phone"}})]}}])})],1),(!_vm.isUserType.STUDENT && false)?[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{key:"user/profile.join_type",attrs:{"name":_vm.$$columnName('user/profile.join_type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-select',{attrs:{"label":_vm.$$columnName('user/profile.join_type'),"error-messages":errors,"items":_vm.select.joinType,"outlined":""},model:{value:(_vm.internalValue.join_type),callback:function ($$v) {_vm.$set(_vm.internalValue, "join_type", $$v)},expression:"internalValue.join_type"}})]}}],null,false,2439754020)})],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }