<template>
  <v-card :max-width="500">
    <v-simple-table class="mouse-over-highlight-disabled">
      <tbody>
        <tr>
          <td>{{ $$columnName('program.title') }}</td>
          <td>{{ program.title }}</td>
        </tr>
        <tr>
          <td>{{ $$columnName('program.desc') }}</td>
          <td>
            <div class="word-breaker py-2" v-html="linkedDesc" />
          </td>
        </tr>
        <tr>
          <td>{{ $$tableName('teacher') }}</td>
          <td>
            <chip-dialog-teacher
              v-for="teacher in program.teachers"
              :key="teacher.id"
              :teacher="teacher"
              editable
              @click:edit="clickEdit"
            />
          </td>
        </tr>
        <tr>
          <td>{{ $$columnName('program.committie') }}</td>
          <td>{{ program.committie }}</td>
        </tr>
        <tr>
          <td>{{ $$columnName('program.venue_id') }}</td>
          <td>{{ program.venue.title }}</td>
        </tr>
        <tr>
          <td>{{ $$columnName('program.zoom_url') }}</td>
          <td>
            <a :href="program.zoom_url" target="_blank">{{
              program.zoom_url
            }}</a>
          </td>
        </tr>
        <tr>
          <td>{{ $$columnName('program.vimeo_html_tag') }}</td>
          <td v-if="program.vimeo_html_tag">
            {{ $t('program.vimeo_html_tag.set') }}
          </td>
          <td v-else>{{ $t('program.vimeo_html_tag.not_set') }}</td>
        </tr>
        <tr>
          <td>{{ $$columnName('program.answer_form_url') }}</td>
          <td>
            <a :href="program.answer_form_url" target="_blank">{{
              program.answer_form_url
            }}</a>
          </td>
        </tr>
        <tr>
          <td>{{ $$columnName('program.st_at') }}</td>
          <td>{{ program.st_at | momentFormat('lll') }}</td>
        </tr>
        <tr>
          <td>{{ $$columnName('program.ed_at') }}</td>
          <td>{{ program.ed_at | momentFormat('lll') }}</td>
        </tr>
        <tr>
          <td>{{ $$columnName('program.archive_st_at') }}</td>
          <td v-if="program.archive_st_at">
            {{ program.archive_st_at | momentFormat('lll') }}
          </td>
          <td v-else>{{ $t('program.archive_period_not_set') }}</td>
        </tr>
        <tr>
          <td>{{ $$columnName('program.archive_ed_at') }}</td>
          <td v-if="program.archive_st_at">
            {{ program.archive_ed_at | momentFormat('lll') }}
          </td>
          <td v-else>{{ $t('program.archive_period_not_set') }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-divider class="mb-4" />
    <v-card-actions>
      <v-row>
        <v-col cols="12" align="right">
          <v-btn class="mr-2" @click="$emit('click:audiences-counts')">
            {{ $t('button.to_audiences_counts') }}
          </v-btn>
          <btn-dialog-destroy
            :btn-props="{ class: 'mr-2', color: 'error' }"
            @destroy="$emit('click:destroy')"
          />
          <v-btn color="primary" @click="$emit('click:edit')">
            {{ $t('button.edit') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>
<script>
import Autolinker from 'autolinker'
import ChipDialogTeacher from '@/components/parts/Chip/Dialog/Teacher'
import BtnDialogDestroy from '@/components/parts/Btn/Dialog/Destroy'
export default {
  components: {
    ChipDialogTeacher,
    BtnDialogDestroy,
  },
  props: {
    program: { type: Object, required: true },
    loading: { type: Boolean, required: true },
  },
  computed: {
    linkedDesc() {
      return Autolinker.link(this.program.desc, {
        sanitizeHtml: true,
        stripPrefix: false,
      })
    },
  },
  methods: {
    clickEdit(teacherId) {
      this.$emit('edit:teacher', teacherId)
    },
  },
}
</script>
