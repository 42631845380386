<template>
  <page-title :title="title">
    <v-list-item-action>
      <v-btn @click="$emit('click:index')">
        {{ $t('button.index') }}
      </v-btn>
    </v-list-item-action>
  </page-title>
</template>
<script>
import PageTitle from './PageTitle'
export default {
  components: {
    PageTitle,
  },
  props: {
    title: { type: String, default: '' },
  },
}
</script>
