<template>
  <validation-observer ref="observer">
    <v-form @submit.prevent="save">
      <slot></slot>
      <v-row>
        <v-col align="right">
          <v-btn
            v-if="cancelable"
            class="mr-4"
            :loading="loading"
            @click="$emit('cancel')"
          >
            {{ $t('button.cancel') }}
          </v-btn>
          <v-btn :loading="loading" type="submit" color="primary">
            {{ $t('button.create') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </validation-observer>
</template>
<script>
export default {
  props: {
    loading: { type: Boolean, required: true },
    cancelable: { type: Boolean, default: false },
  },
  methods: {
    async save() {
      if (this.loading) {
        return this.$toasted.error(this.$t('errors.processing'))
      }
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return this.$toasted.error(this.$t('errors.params'))
      }
      this.$emit('click:save')
    },
  },
}
</script>
