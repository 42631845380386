<template>
  <div>
    <selected-conference-info />
    <page-title class="mb-4" @click:csv="outputCsv" />
    <index-search
      class="mb-4"
      v-model="search"
      :loading-loms="loadingLoms"
      :loms="loms"
      @search="pushQueryForSearch"
    />
    <index-table
      class="mb-4"
      :audiences-counts="audiencesCounts"
      :loading="loading"
      :total-count="totalCount"
      :table-options="tableOptions"
      @click:row="toUserShow"
      @update:options="updateOptions"
    />
  </div>
</template>
<script>
import ManagerApi from '@/api/managers'
import SelectedConferenceInfo from '@/components/areas/managers/Card/SelectedConferenceInfo'
import PageTitle from '@/components/areas/common/PageTitleWithCsvButton'
import IndexSearch from './AudiencesCounts/Search'
import IndexTable from './AudiencesCounts/Table'
export default {
  mixins: [ManagerApi],
  components: {
    SelectedConferenceInfo,
    PageTitle,
    IndexSearch,
    IndexTable,
  },
  data: () => ({
    loading: false,
    loadingLoms: false,
    loms: [],
    search: {},
    tableOptions: {
      page: 1,
      per: 10,
    },
    audiencesCounts: [],
    totalCount: 0,
  }),
  computed: {
    queryParams() {
      return { ...this.$options.data().tableOptions, ...this.$route.query }
    },
  },
  beforeRouteUpdate(to, from, next) {
    next()
    this.initSearchAndTableOptions()
    this.getAudiencesCounts()
  },
  created() {
    this.initSearchAndTableOptions()
    this.getAudiencesCounts()
    this.getLoms()
  },
  methods: {
    initSearchAndTableOptions() {
      const { page, per, ...tmpSearch } = this.queryParams
      this.search = tmpSearch
      this.tableOptions = { page: parseInt(page), per: parseInt(per) }
    },
    pushQueryForSearch() {
      const query = {
        ...this.$$removeBlankValue(this.search),
        ...this.tableOptions,
        page: 1,
      }
      this.$router.push({ query }, () => {})
    },
    getAudiencesCounts() {
      this.loading = true
      this.axios
        .get(this.API.audiencesCounts.index, { params: this.queryParams })
        .then((res) => {
          this.audiencesCounts = res.data.audiences_counts
          this.totalCount = res.data.total_count
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
    getLoms() {
      this.loadingLoms = true
      this.axios
        .get(this.API.loms.index)
        .then((res) => {
          this.loms = res.data.loms
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loadingLoms = false
        })
    },
    outputCsv() {
      if (this.loading) {
        return
      }
      this.loading = true
      this.axios
        .get(this.API.audiencesCounts.output_csv, { params: this.queryParams })
        .then((res) => {
          this.$$downloadCsv(res.data, 'audiences_counts.csv')
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
    toUserShow(audiencesCount) {
      const userId = audiencesCount.user.id
      this.$router.push({
        name: 'manager-users-show',
        params: { userId },
      })
    },
    updateOptions({ page, itemsPerPage: per }) {
      const isChangedPage = this.tableOptions.page !== page
      const isChangedPer = this.tableOptions.per !== per
      if (!isChangedPage && !isChangedPer) {
        return
      }
      const query = { ...this.queryParams, page, per }
      this.$router.push({ query }, () => {})
    },
  },
  metaInfo() {
    return this.$t(`meta_tags.${this.$route.name}`)
  },
}
</script>
