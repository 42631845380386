<template>
  <v-row>
    <v-col
      v-for="program in displayPrograms"
      :key="program.id"
      cols="12"
      md="4"
      sm="6"
    >
      <program-card :program="program" />
    </v-col>
    <v-col v-if="showMoreButton">
      <v-btn color="primary" x-large block text @click="addNextPage">
        {{ $t('button.more') }}
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import ProgramCard from './Card'
export default {
  components: {
    ProgramCard,
  },
  props: {
    value: { type: Object, required: true },
    programs: { type: Array, required: true },
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    displayPrograms() {
      const displayCount = this.internalValue.page * this.internalValue.perPage
      return this.programs.slice(0, displayCount)
    },
    showMoreButton() {
      return this.displayPrograms.length < this.programs.length
    },
  },
  methods: {
    addNextPage() {
      this.internalValue.page++
    },
    showCertificateButton(program) {
      return (
        program.audiences_counts &&
        program.audiences_counts.participation_type === 'local'
      )
    },
  },
}
</script>
