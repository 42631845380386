<template>
  <div>
    <v-row>
      <v-col cols="12" md="6">
        <v-card>
          <v-btn
            v-if="!isShowCertificate"
            class="mb-5"
            color="primary"
            x-large
            block
            :loading="loadingAudiencesCount"
            @click="showCertificate"
          >
            {{ $t('button.to_certificate') }}
          </v-btn>
          <program-certificate
            v-if="isShowCertificate"
            class="mb-5"
            :program="program"
          />
        </v-card>
        <v-card>
          <vimeo-screen
            v-if="program.vimeo_html_tag && program.show_period"
            :program="program"
            @play:vimeo="$emit('play:vimeo')"
          />
          <button-to-open-zoom
            v-else-if="program.zoom_url && program.show_period"
            :program="program"
            @open:zoom="$emit('open:zoom')"
          />
          <div v-else class="pa-12" align="center">
            <v-icon class="my-2" size="100"> mdi-alert-circle-outline </v-icon>
            <div v-if="afterOverdue">
              {{ $t('program.overdue') }}
            </div>
            <div v-else>
              {{ $t('vimeo.is_draft') }}
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col md="6">
        <v-card>
          <v-card-title class="card-title">{{ program.title }}</v-card-title>
          <v-card-text>
            <v-sheet class="mb-2">{{ $t('program.date_and_time') }}</v-sheet>
            <v-sheet>
              {{ program.st_at | momentFormat('lll') }}
              〜
              {{ program.ed_at | momentFormat('LT') }}
            </v-sheet>
            <v-sheet>{{ program.venue.title }}</v-sheet>
          </v-card-text>
          <template v-if="program.archive_st_at">
            <v-divider />
            <v-card-text>
              <v-sheet class="mb-2">{{
                $t('program.archive_schedule_period')
              }}</v-sheet>
              <v-sheet>
                {{ program.archive_st_at | momentFormat('lll') }}
                〜
                {{ program.archive_ed_at | momentFormat('lll') }}
              </v-sheet>
            </v-card-text>
          </template>
          <template v-if="program.teachers.length > 0">
            <v-divider />
            <v-card-text>
              <v-sheet class="mb-2">{{ $$tableName('teacher') }}</v-sheet>
              <v-sheet>
                <chip-dialog-teacher
                  v-for="teacher in program.teachers"
                  :key="teacher.id"
                  :teacher="teacher"
                />
              </v-sheet>
            </v-card-text>
          </template>
          <template v-if="program.committie">
            <v-divider />
            <v-card-text>
              <v-sheet class="mb-2">{{
                $$columnName('program.committie')
              }}</v-sheet>
              <v-sheet>{{ program.committie }}</v-sheet>
            </v-card-text>
          </template>
          <v-divider />
          <v-card-text>
            <v-sheet class="word-breaker" v-html="linkedDesc" />
          </v-card-text>
        </v-card>
        <button-to-answer-form
          v-if="program.participation_type === 'web'"
          class="mt-2 hidden-sm-and-up"
          :answer-form-url="program.answer_form_url"
          color="success"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import UserApi from '@/api/users'
import Autolinker from 'autolinker'
import ChipDialogTeacher from '@/components/parts/Chip/Dialog/Teacher'
import VimeoScreen from './Vimeo'
import ButtonToOpenZoom from './Zoom'
import ProgramCertificate from '@/views/user/qrcodes/Certificate'
import ButtonToAnswerForm from '@/components/atoms/btn/ToAnswerForm'
export default {
  mixins: [UserApi],
  components: {
    ChipDialogTeacher,
    VimeoScreen,
    ButtonToOpenZoom,
    ProgramCertificate,
    ButtonToAnswerForm,
  },
  props: {
    program: { type: Object, required: true },
    loading: { type: Boolean, required: true },
  },
  data: () => ({
    loadingAudiencesCount: false,
    isAudiencesCounted: false,
  }),
  computed: {
    isShowCertificate() {
      return (
        this.program.participation_type === 'local' || this.isAudiencesCounted
      )
    },
    linkedDesc() {
      return Autolinker.link(this.program.desc, {
        sanitizeHtml: true,
        stripPrefix: false,
      })
    },
    afterOverdue() {
      const currentTime = new Date()
      if (this.program.archive_ed_at) {
        const archiveEndTime = new Date(this.program.archive_ed_at)
        return archiveEndTime < currentTime
      }
      const endTime = new Date(this.program.ed_at)
      return endTime < currentTime
    },
  },
  methods: {
    showCertificate() {
      this.audiencesCount(this.program.id)
      this.updateVisitVenueAt()
    },
    audiencesCount(programId) {
      this.loadingAudiencesCount = true
      const params = { program_id: programId, participation_type: 'local' }
      const path = this.APIwithId({ programId }).audiences_counts.create
      this.axios
        .post(path, params)
        .then(() => (this.isAudiencesCounted = true))
        .catch((err) => {
          if (err.response.status !== 422) return

          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loadingAudiencesCount = false
        })
    },
    updateVisitVenueAt() {
      this.axios.post(this.API.conferences.visit_venue).catch((err) => {
        if (err.response.status !== 422) return

        this.$toasted.error(err.response.data.errors.join('\n'))
      })
    },
  },
}
</script>
<style scoped lang="scss">
.card-title {
  font-size: 1.1rem !important;
  line-height: 1.5rem !important;
}
</style>
