<template>
  <div>
    <page-title
      class="mb-4"
      @click:index="$router.push({ name: 'admin-users-index' })"
    />
    <page-detail
      :user="user"
      :loading="loading"
      isAdmin
      editable
      destroyable
      hide-conference-account-status
      @click:edit="
        $router.push({ name: 'admin-users-edit', params: { userId: user.id } })
      "
      @click:destroy="destroy"
    />
  </div>
</template>
<script>
import AdminApi from '@/api/admins'
import PageTitle from '@/components/areas/common/PageTitleWithIndexButton'
import PageDetail from '@/components/areas/users/Show/Detail'
export default {
  mixins: [AdminApi],
  components: {
    PageTitle,
    PageDetail,
  },
  data: () => ({
    loading: false,
    user: {
      id: '',
      email: '',
      provider: '',
      profile: {
        name: '',
        name_hira: '',
        organization: '',
        organization_hira: '',
        phone: '',
        birthday: '',
        user_type: '',
        join_type: '',
        jci_member_availability: '',
        lom: {
          name: '',
        },
      },
    },
  }),
  created() {
    this.getUser()
  },
  methods: {
    getUser() {
      this.loading = true
      this.axios
        .get(this.API.users.show)
        .then((res) => {
          this.user = res.data.user
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
    destroy() {
      this.loading = true
      this.axios
        .delete(this.API.users.destroy)
        .then(() => {
          this.$router.push({ name: 'admin-users-index' })
          this.$toasted.success(this.$t('success.destroy'))
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            return
          }
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  metaInfo() {
    return this.$t(`meta_tags.${this.$route.name}`)
  },
}
</script>
