<template>
  <v-sheet align="center">
    <v-progress-circular
      class="mt-12"
      size="100"
      color="primary"
      indeterminate
    />
  </v-sheet>
</template>
<script>
import UserApi from '@/api/users'
export default {
  mixins: [UserApi],
  created() {
    this.redirectPage()
  },
  methods: {
    async redirectPage() {
      const programId =
        this.$route.params.programId || this.$cookies.get('visitProgramId')
      const conferenceId =
        this.$route.query.cid || this.$cookies.get('conferenceId')
      this.$cookies.set('visitProgramId', programId)
      this.$store.dispatch('setConferenceId', conferenceId)
      if (this.$store.getters.user) {
        await this.audiencesCount(programId)
        this.$router.push({ name: 'user-programs-show' })
      } else {
        this.$router.push({ name: 'top' })
      }
    },
    async audiencesCount(programId) {
      const params = { program_id: programId, participation_type: 'local' }
      const path = this.APIwithId({ programId }).audiences_counts.create
      await this.axios.post(path, params).then(() => {})
      this.$cookies.remove('visitProgramId')
    },
  },
  metaInfo() {
    return this.$t(`meta_tags.${this.$route.name}`)
  },
}
</script>
