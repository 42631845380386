<template>
  <v-form @submit.prevent="$emit('search')">
    <v-row>
      <v-col cols="12" md="3">
        <v-text-field
          v-model="internalValue.name"
          :label="$t('search.jci_payments.name')"
          outlined
          dense
          hide-details
          clearable
        />
      </v-col>
      <v-col cols="12" md="3">
        <select-dialog-search-lom
          v-model="internalValue.lom_id"
          :loms="loms"
          :select-options="{
            label: $$tableName('lom'),
            outlined: true,
            dense: true,
            hideDetails: true,
            clearable: true,
          }"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          v-model="internalValue.member_number"
          :label="$t('search.jci_payments.member_number')"
          outlined
          dense
          hide-details
          clearable
        />
      </v-col>
      <v-col cols="12" md="3" align="right">
        <v-btn type="submit" outlined>
          {{ $t('button.search') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import SelectDialogSearchLom from '@/components/parts/Select/Dialog/SearchLom'
export default {
  components: {
    SelectDialogSearchLom,
  },
  props: {
    value: { type: Object, required: true },
    loms: { type: Array, required: true },
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
