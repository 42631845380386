<template>
  <div>
    <selected-conference-info />
    <page-title class="mb-4" @click:csv="outputCsv" />
    <index-search
      class="mb-4"
      v-model="search"
      :loading-loms="loadingLoms"
      :loms="loms"
      @search="pushQueryForSearch"
    />
    <index-table
      class="mb-4"
      v-model="selectedUserIds"
      :users="users"
      :loading="loading"
      :total-count="totalCount"
      :table-options="tableOptions"
      @click:row="clickRow"
      @update:options="updateOptions"
    />
    <index-multi-update
      :loading="loading"
      :selected-user-ids="selectedUserIds"
      @click:ban="accountBan"
      @click:active="accountActive"
    />
  </div>
</template>
<script>
import ManagerApi from '@/api/managers'
import SelectedConferenceInfo from '@/components/areas/managers/Card/SelectedConferenceInfo'
import PageTitle from '@/components/areas/common/PageTitleWithCsvButton'
import IndexSearch from '@/components/areas/users/Index/Search'
import IndexTable from '@/components/areas/users/Index/Table'
import IndexMultiUpdate from '@/components/areas/users/Index/MultiUpdate'
export default {
  mixins: [ManagerApi],
  components: {
    SelectedConferenceInfo,
    PageTitle,
    IndexSearch,
    IndexTable,
    IndexMultiUpdate,
  },
  data: () => ({
    loading: false,
    loadingLoms: false,
    search: {},
    tableOptions: {
      page: 1,
      per: 10,
    },
    users: [],
    totalCount: 0,
    selectedUserIds: [],
    loms: [],
  }),
  computed: {
    queryParams() {
      return { ...this.$options.data().tableOptions, ...this.$route.query }
    },
  },
  beforeRouteUpdate(to, from, next) {
    next()
    this.initSearchAndTableOptions()
    this.getUsers()
  },
  created() {
    this.initSearchAndTableOptions()
    this.getUsers()
    this.getLoms()
  },
  methods: {
    initSearchAndTableOptions() {
      const { page, per, ...tmpSearch } = this.queryParams
      this.search = tmpSearch
      this.tableOptions = { page: parseInt(page), per: parseInt(per) }
    },
    pushQueryForSearch() {
      const query = {
        ...this.$$removeBlankValue(this.search),
        ...this.tableOptions,
        page: 1,
      }
      this.$router.push({ query }, () => {})
    },
    getUsers() {
      this.loading = true
      this.axios
        .get(this.API.users.index, { params: this.queryParams })
        .then((res) => {
          this.users = res.data.users
          this.totalCount = res.data.total_count
        })
        .catch((err) => {
          if (err.response.status !== 422) return
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
    getLoms() {
      this.loadingLoms = true
      this.axios
        .get(this.API.loms.index)
        .then((res) => {
          this.loms = res.data.loms
        })
        .catch((err) => {
          if (err.response.status !== 422) return
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loadingLoms = false
        })
    },
    accountBan() {
      this.loading = true
      const data = { user_ids: this.selectedUserIds }
      this.axios
        .delete(this.API.users.ban, { data })
        .then(() => {
          this.getUsers()
          this.$toasted.success(this.$t('success.create'))
        })
        .catch((err) => {
          if (err.response.status !== 422) return
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
    accountActive() {
      this.loading = true
      const params = { user_ids: this.selectedUserIds }
      this.axios
        .post(this.API.users.active, params)
        .then(() => {
          this.getUsers()
          this.$toasted.success(this.$t('success.create'))
        })
        .catch((err) => {
          if (err.response.status !== 422) return
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
    outputCsv() {
      if (this.loading) return
      this.loading = true
      this.axios
        .get(this.API.users.output_csv, { params: this.queryParams })
        .then((res) => {
          this.$$downloadCsv(res.data, 'users.csv')
        })
        .catch((err) => {
          if (err.response.status !== 422) return
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
    clickRow({ id }) {
      this.$router.push({
        name: 'manager-users-show',
        params: { userId: id },
      })
    },
    updateOptions({ page, itemsPerPage: per }) {
      const isChangedPage = this.tableOptions.page !== page
      const isChangedPer = this.tableOptions.per !== per
      if (!isChangedPage && !isChangedPer) return

      const query = { ...this.queryParams, page, per }
      this.$router.push({ query }, () => {})
    },
  },
  metaInfo() {
    return this.$t(`meta_tags.${this.$route.name}`)
  },
}
</script>
