import UserLogin from '@/views/user/bases/Login'
import UserLoginJci from '@/views/user/bases/LoginJci'
import UserSignup from '@/views/user/bases/Signup'
import UserSignupEmail from '@/views/user/bases/SignupEmail'
import UserQrcodesRedirect from '@/views/user/qrcodes/Redirect'
import UserProgramQrcodesRedirect from '@/views/user/qrcodes/ProgramRedirect'
import UserQrcodesWelcome from '@/views/user/qrcodes/Welcome'
import UserProgramQrcodesCertificate from '@/views/user/qrcodes/Certificate'
import UserAuthConfirm from '@/views/auth/user/Confirm'
import UserAccountsShow from '@/views/user/accounts/Show'
import UserAccountsForm from '@/views/user/accounts/Form'
import UserAccountsStop from '@/views/user/accounts/Stop'
import UserAccountsInvalid from '@/views/user/accounts/Invalid'
import UserJciMemberLoginsRedirect from '@/views/user/jci_member_logins/Redirect'
import UserSettingsPassword from '@/views/user/settings/Password'
import UserPasswordsResetSend from '@/views/user/passwords/ResetSend'
import UserPasswordsReset from '@/views/user/passwords/Reset'
import UserProgramsIndex from '@/views/user/programs/Index'
import UserProgramsShow from '@/views/user/programs/Show'
import UserConferencesIndex from '@/views/user/conferences/Index'
import UserConferencesShow from '@/views/user/conferences/Show'
import UserConferenceSignupRedirect from '@/views/user/conferences/SignupRedirect'

export default [
  {
    path: '/login',
    name: 'user-login',
    component: UserLogin,
    meta: { isPublic: true, hideToUser: true },
  },
  {
    path: '/login_jci',
    name: 'user-login-jci',
    component: UserLoginJci,
    meta: { isPublic: true, hideToUser: true },
  },
  {
    path: '/signup',
    name: 'user-signup',
    component: UserSignup,
    meta: { isPublic: true, hideToUser: true },
  },
  {
    path: '/signup/email',
    name: 'user-signup-email',
    component: UserSignupEmail,
    meta: { isPublic: true, hideToUser: true },
  },
  {
    path: '/qrcodes/redirect',
    name: 'user-qrcodes-redirect',
    component: UserQrcodesRedirect,
    meta: { isPublic: true },
  },
  {
    path: '/programs/qrcodes/redirect/:programId',
    name: 'user-program-qrcodes-redirect',
    component: UserProgramQrcodesRedirect,
    meta: { isPublic: true },
  },
  {
    path: '/qrcodes/welcome',
    name: 'user-qrcodes-welcome',
    component: UserQrcodesWelcome,
  },
  {
    path: '/programs/qrcodes/certificate/:programId',
    name: 'user-program-qrcodes-certificate',
    component: UserProgramQrcodesCertificate,
  },
  {
    // このpathはdevise_token_auth依存なので触らないこと
    path: '/auth/confirm',
    name: 'user-auth-confirm',
    component: UserAuthConfirm,
    meta: { isPublic: true },
  },
  {
    path: '/account/show',
    name: 'user-accounts-show',
    component: UserAccountsShow,
  },
  {
    path: '/account/edit',
    name: 'user-accounts-edit',
    component: UserAccountsForm,
  },
  {
    path: '/account/stop',
    name: 'user-accounts-stop',
    component: UserAccountsStop,
  },
  {
    path: '/account/invalid',
    name: 'user-accounts-invalid',
    component: UserAccountsInvalid,
  },
  {
    path: '/jci_member_login/redirect',
    name: 'user-jci_member_login-redirect',
    component: UserJciMemberLoginsRedirect,
    meta: { isPublic: true },
  },
  {
    path: '/settings/password',
    name: 'user-settings-password',
    component: UserSettingsPassword,
  },
  {
    path: '/passwords/reset_send',
    name: 'user-passwords-reset-send',
    component: UserPasswordsResetSend,
    meta: { isPublic: true },
  },
  {
    path: '/passwords/reset',
    name: 'user-passwords-reset',
    component: UserPasswordsReset,
    meta: { isPublic: true },
  },
  {
    path: '/programs',
    name: 'user-programs-index',
    component: UserProgramsIndex,
  },
  {
    path: '/programs/:programId',
    name: 'user-programs-show',
    component: UserProgramsShow,
  },
  {
    path: '/conferences',
    name: 'user-conferences-index',
    component: UserConferencesIndex,
  },
  {
    path: '/conferences/show',
    name: 'user-conferences-show',
    component: UserConferencesShow,
  },
  {
    path: '/conferences/signup/redirect/:conferenceId',
    name: 'user-conference-signup-redirect',
    component: UserConferenceSignupRedirect,
  },
]
