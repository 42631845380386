<template>
  <div>
    <v-card class="mb-4">
      <v-card-text>
        <v-row class="mt-n1 mb-n7">
          <v-col cols="12" md="4">
            <validation-provider
              v-slot="{ errors }"
              :name="$t('label.import.select_upload_file')"
              rules="ext:xls,xlsx|required"
            >
              <v-file-input
                v-model="internalValue.uploadFiles"
                :label="$t('label.import.input_upload_file')"
                :error-messages="errors"
                accept=".xls, .xlsx"
                truncate-length="50"
                outlined
                dense
                multiple
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" md="4">
            <validation-provider
              v-slot="{ errors }"
              :name="$$tableName('lom')"
              rules="required"
            >
              <select-dialog-search-lom
                v-model="internalValue.lomId"
                :loms="loms"
                :select-options="{
                  label: $$tableName('lom'),
                  outlined: true,
                  dense: true,
                  clearable: true,
                  errorMessages: errors,
                }"
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" md="4" align="right">
            <v-btn
              class="px-16 font-weight-bold"
              color="primary"
              elevation="2"
              large
              @click="$emit('file:upload')"
            >
              {{ $t('button.upload') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import SelectDialogSearchLom from '@/components/parts/Select/Dialog/SearchLom'
export default {
  components: {
    SelectDialogSearchLom,
  },
  props: {
    value: { type: Object, default: null },
    loms: { type: Array, required: true },
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
