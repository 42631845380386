<template>
  <v-card>
    <v-data-table
      v-model="selectedUsers"
      :loading="loading"
      :headers="headers"
      :items="users"
      disable-sort
      show-select
      :server-items-length="totalCount"
      :page="tableOptions.page"
      :items-per-page="tableOptions.per"
      @click:row="$emit('click:row', $event)"
      @update:options="$emit('update:options', $event)"
    >
      <template #[`item.profile.user_type`]="{ item }">
        {{ $$getEnumText('user_type', item.profile.user_type) }}
      </template>
      <template #[`item.users_conference.attended_user_type`]="{ item }">
        {{
          $$getEnumText('user_type', item.users_conference.attended_user_type)
        }}
      </template>
      <template v-if="isAdmin" #[`item.profile.account_type`]="{ item }">
        {{ $$getEnumText('account_type', item.profile.account_type) }}
      </template>
      <template
        v-else
        #[`item.users_conference.conference_account_status`]="{ item }"
      >
        {{
          $$getEnumText(
            'conference_account_status',
            item.users_conference.conference_account_status
          )
        }}
      </template>
      <template #[`item.profile.jci_member_availability`]="{ item }">
        {{
          $$getEnumText(
            'jci_member_availability',
            item.profile.jci_member_availability
          )
        }}
      </template>
      <template #[`item.provider`]="{ item }">
        {{ $$getEnumText('provider', item.provider) }}
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
export default {
  props: {
    value: { type: Array, required: true },
    loading: { type: Boolean, required: true },
    users: { type: Array, required: true },
    totalCount: { type: Number, required: true },
    tableOptions: { type: Object, required: true },
    isAdmin: { type: Boolean, default: false },
  },
  data: () => ({
    selectedUsers: [],
  }),
  computed: {
    adminHeaderOrManagerHeader() {
      return this.isAdmin
        ? [
            {
              text: this.$$columnName('user/profile.account_type'),
              value: 'profile.account_type',
            },
            {
              text: this.$$columnName('user/profile.jci_member_availability'),
              value: 'profile.jci_member_availability',
            },
          ]
        : [
            {
              text: this.$$columnName('users_conference.attended_user_type'),
              value: 'users_conference.attended_user_type',
            },
            {
              text: this.$$columnName(
                'users_conference.conference_account_status'
              ),
              value: 'users_conference.conference_account_status',
            },
          ]
    },
    headers() {
      return [
        { text: this.$$columnName('user/profile.name'), value: 'profile.name' },
        {
          text: this.$$columnName('user/profile.name_hira'),
          value: 'profile.name_hira',
        },
        { text: this.$$columnName('user.email'), value: 'email' },
        {
          text: this.$$columnName('user/profile.user_type'),
          value: 'profile.user_type',
        },
        ...this.adminHeaderOrManagerHeader,
        {
          text: this.$$columnName('user.provider'),
          value: 'provider',
        },
      ]
    },
  },
  watch: {
    selectedUsers(value) {
      this.$emit(
        'input',
        value.map((user) => user.id)
      )
    },
  },
}
</script>
