<template>
  <v-form @submit.prevent="$emit('search')">
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="internalValue.email"
          :label="$$columnName('admin.email')"
          outlined
          dense
          hide-details
          clearable
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-btn type="submit" outlined>
          {{ $t('button.search') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
export default {
  props: {
    value: { type: Object, required: true },
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
