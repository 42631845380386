<template>
  <v-card>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="programs"
      disable-sort
      hide-default-footer
      :items-per-page="-1"
      @click:row="$emit('click:row', $event)"
    >
      <template #[`item.st_at`]="{ item }">
        {{ item.st_at | momentFormat('lll') }}
      </template>
      <template #[`item.ed_at`]="{ item }">
        {{ item.ed_at | momentFormat('lll') }}
      </template>
      <template #[`item.archive_st_at`]="{ item }">
        <span v-if="item.archive_st_at">
          {{ item.archive_st_at | momentFormat('lll') }}<br />
          ~ {{ item.archive_ed_at | momentFormat('lll') }}
        </span>
        <v-btn v-else color="error" small @click.stop="clickButton(item.id)">
          {{ $t('button.not_set') }}
        </v-btn>
      </template>
      <template #[`item.zoom_vimeo`]="{ item }">
        <v-btn
          v-if="item.zoom_url"
          color="primary"
          small
          @click.stop="clickButton(item.id)"
        >
          {{ $t('button.zoom_set') }}
        </v-btn>
        <v-btn
          v-else-if="item.vimeo_html_tag"
          color="light-blue lighten-3"
          small
          @click.stop="clickButton(item.id)"
        >
          {{ $t('button.vimeo_set') }}
        </v-btn>
        <v-btn v-else color="error" small @click.stop="clickButton(item.id)">
          {{ $t('button.not_set') }}
        </v-btn>
      </template>
      <template #[`item.answer_form_url`]="{ item }">
        <v-btn
          v-if="item.answer_form_url"
          color="primary"
          small
          @click.stop="clickButton(item.id)"
        >
          {{ $t('button.answer_form_url_set') }}
        </v-btn>
        <v-btn v-else color="error" small @click.stop="clickButton(item.id)">
          {{ $t('button.not_set') }}
        </v-btn>
      </template>
      <template #[`footer`]="{ props }">
        <v-divider></v-divider>
        <v-sheet class="py-4 mr-8" align="right">
          {{ props.pagination.itemsLength }}件
        </v-sheet>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
export default {
  props: {
    loading: { type: Boolean, required: true },
    programs: { type: Array, required: true },
  },
  data: () => ({
    headers: [],
  }),
  created() {
    this.headers = [
      { text: this.$$columnName('program.title'), value: 'title' },
      { text: this.$$tableName('venue'), value: 'venue.title' },
      { text: this.$$columnName('program.st_at'), value: 'st_at' },
      { text: this.$$columnName('program.ed_at'), value: 'ed_at' },
      { text: this.$t('program.archive_period'), value: 'archive_st_at' },
      { text: this.$t('program.video_setting'), value: 'zoom_vimeo' },
      {
        text: this.$$columnName('program.answer_form_url'),
        value: 'answer_form_url',
      },
    ]
  },
  methods: {
    clickButton(programId) {
      this.$router.push({
        name: 'manager-programs-edit',
        params: { programId },
      })
    },
  },
}
</script>
