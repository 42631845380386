<template>
  <div>
    <page-title class="mb-4" />
    <btn-dialog-search
      v-if="$vuetify.breakpoint.mobile"
      v-model="search"
      @click:search="getConferences"
    />
    <index-search
      v-else
      hide-email
      class="mb-4"
      v-model="search"
      @click:search="getConferences"
    />
    <area-loading v-if="loading" class="mt-12" />
    <index-cards
      v-else
      class="mb-4"
      :conferences="openConferences"
      @click:select="clickSelect"
    />
    <v-card
      v-if="openConferences.length === 0"
      class="py-16 mb-4"
      align="center"
    >
      {{ $t('label.open_conference_nothing') }}
    </v-card>
    <v-sheet dark class="jci-background-color py-4 px-4 mb-4">
      {{ $t('label.closed_conference') }}
    </v-sheet>
    <area-loading v-if="loading" class="mt-12" />
    <index-cards
      v-else
      class="mb-4"
      :conferences="closedConferences"
      @click:select="clickSelect"
    />
  </div>
</template>
<script>
import moment from 'moment'
import UserApi from '@/api/users'
import PageTitle from '@/components/areas/common/PageTitle'
import AreaLoading from '@/components/areas/common/Loading'
import IndexSearch from '@/components/areas/conferences/Index/Search'
import BtnDialogSearch from '@/components/areas/conferences/Index/BtnDialogSearch'
import IndexCards from '@/components/areas/conferences/Index/Cards'
export default {
  mixins: [UserApi],
  components: {
    PageTitle,
    AreaLoading,
    IndexSearch,
    BtnDialogSearch,
    IndexCards,
  },
  data: () => ({
    loading: false,
    search: {
      title: '',
    },
    conferences: [],
    timeNow: moment(),
  }),
  created() {
    this.getConferences()
  },
  computed: {
    openConferences() {
      return this.conferences.filter((conference) =>
        moment(conference.schedule_ed_at).isAfter(this.timeNow)
      )
    },
    closedConferences() {
      return this.conferences.filter((conference) =>
        moment(conference.schedule_ed_at).isSameOrBefore(this.timeNow)
      )
    },
  },
  methods: {
    getConferences() {
      this.loading = true
      const params = { ...this.search }
      this.axios
        .get(this.API.conferences.index, { params })
        .then((res) => {
          this.conferences = res.data.conferences
        })
        .catch((err) => {
          if (err.response.status !== 422) return
          this.$toasted.error(err.response.data.errors.join('\n'))
        })
        .finally(() => {
          this.loading = false
        })
    },
    clickSelect(conference) {
      this.$store.dispatch('users/clearUsersStore')
      this.$store.dispatch('setConferenceId', conference.id)
      this.$store.dispatch('setConferenceInfo', conference)
      this.$store.dispatch(
        'setVisitVenueAt',
        conference.users_conference.visit_venue_at
      )
      this.$router.push({
        name: 'user-programs-index',
      })
    },
  },
  metaInfo() {
    return this.$t(`meta_tags.${this.$route.name}`)
  },
}
</script>
