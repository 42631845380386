import Vue from 'vue'
import Vuex from 'vuex'
import users from '@/stores/users'
import VueCookies from 'vue-cookies'

Vue.use(Vuex)
Vue.use(VueCookies)

export default new Vuex.Store({
  modules: { users },
  state: {
    admin: null,
    manager: null,
    user: null,
    auth: {},
    conference: {
      id: '',
      title: '',
      schedule_st_at: '',
      schedule_ed_at: '',
      visit_venue_at: '',
      image: '',
    },
  },
  getters: {
    admin: (state) => state.admin,
    manager: (state) => state.manager,
    user: (state) => state.user,
    auth: (state) => state.auth,
    conference: (state) => state.conference,
    isSignIn: (state) => state.admin || state.manager || state.user,
  },
  mutations: {
    admin(state, value) {
      state.admin = value
    },
    manager(state, value) {
      state.manager = value
    },
    user(state, value) {
      state.user = value
    },
    auth(state, value) {
      state.auth = value
    },
    conferenceId(state, value) {
      state.conference.id = value
    },
    conferenceTitle(state, value) {
      state.conference.title = value
    },
    conferenceScheduleStAt(state, value) {
      state.conference.schedule_st_at = value
    },
    conferenceScheduleEdAt(state, value) {
      state.conference.schedule_ed_at = value
    },
    conferenceImage(state, value) {
      state.conference.image = value
    },
    visitVenueAt(state, value) {
      state.conference.visit_venue_at = value
    },
  },
  actions: {
    loginAdmin(context, payload) {
      context.commit('admin', payload)
      context.commit('manager', null)
      context.commit('user', null)

      context.commit('conferenceId', '')
      VueCookies.remove('conferenceId')

      const authInfos = VueCookies.get('authInfos') || {}
      authInfos.admin = payload
      delete authInfos.manager
      delete authInfos.user
      VueCookies.set('authInfos', authInfos)
    },
    loginManager(context, payload) {
      context.commit('admin', null)
      context.commit('manager', payload)
      context.commit('user', null)

      const authInfos = VueCookies.get('authInfos') || {}
      authInfos.manager = payload
      delete authInfos.admin
      delete authInfos.user
      VueCookies.set('authInfos', authInfos)
    },
    loginUser(context, payload) {
      context.commit('admin', null)
      context.commit('manager', null)
      context.commit('user', payload)

      const authInfos = VueCookies.get('authInfos') || {}
      authInfos.user = payload
      delete authInfos.admin
      delete authInfos.manager
      VueCookies.set('authInfos', authInfos)
    },
    setAuth(context, payload) {
      context.commit('auth', payload)
    },
    setConferenceId(context, payload) {
      if (payload) {
        context.commit('conferenceId', payload)
        VueCookies.set('conferenceId', payload)
      } else {
        context.commit('conferenceId', '')
        VueCookies.remove('conferenceId')
      }
    },
    setConferenceInfo(context, payload) {
      if (payload) {
        context.commit('conferenceTitle', payload.title)
        context.commit('conferenceScheduleStAt', payload.schedule_st_at)
        context.commit('conferenceScheduleEdAt', payload.schedule_ed_at)
        context.commit('conferenceImage', payload.image.url)
      } else {
        context.commit('conferenceTitle', '')
        context.commit('conferenceScheduleStAt', '')
        context.commit('conferenceScheduleEdAt', '')
        context.commit('conferenceImage', '')
      }
    },
    setVisitVenueAt(context, payload) {
      if (payload) {
        context.commit('visitVenueAt', payload)
        VueCookies.set('visitVenueAt', payload)
      } else {
        context.commit('visitVenueAt', '')
        VueCookies.remove('visitVenueAt')
      }
    },
    logout(context) {
      context.commit('admin', null)
      context.commit('manager', null)
      context.commit('user', null)
      context.commit('auth', {})
      context.dispatch('setVisitVenueAt', '')
      context.dispatch('setConferenceInfo', null)
      context.dispatch('setConferenceId', '')
      context.dispatch('users/clearUsersStore')
      VueCookies.remove('visitProgramId')
      VueCookies.remove('authInfos')
    },
  },
})
