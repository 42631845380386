<template>
  <v-card>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="admins"
      disable-sort
      @click:row="clickRow"
    >
      <template #item.created_at="{ item }">
        {{ item.created_at | momentFormat('lll') }}
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
export default {
  props: {
    loading: { type: Boolean, required: true },
    admins: { type: Array, required: true },
  },
  data: () => ({
    headers: [],
  }),
  created() {
    this.headers = [
      { text: this.$$columnName('admin.email'), value: 'email' },
      { text: this.$$columnName('admin.created_at'), value: 'created_at' },
    ]
  },
  methods: {
    clickRow(item) {
      this.$emit('click:row', item)
    },
  },
}
</script>
