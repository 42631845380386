<template>
  <v-dialog v-model="dialog" :max-width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-chip v-bind="attrs" v-on="on" class="ma-1" pill>
        <v-avatar v-if="teacher.image.url" left>
          <v-img :src="teacher.image.url" />
        </v-avatar>
        {{ teacher.name | tranc(10) }}
      </v-chip>
    </template>
    <v-card>
      <v-card-title></v-card-title>
      <v-card-text>
        <v-row class="mb-4">
          <v-col cols="12" align="center">
            <v-card v-if="$$exist(teacher.image)" :max-width="300">
              <v-img :src="teacher.image.url" />
            </v-card>
          </v-col>
          <v-col cols="12" align="center">
            <h3 class="font-weight-black">
              {{ teacher.name }}
            </h3>
            <div class="text-caption">
              {{ teacher.name_hira }}
            </div>
          </v-col>
        </v-row>
        <v-card class="pa-4 mb-4">
          <div class="word-breaker" v-html="linkedDesc" />
        </v-card>
        <v-row>
          <v-col align="right">
            <v-btn @click="dialog = false">
              {{ $t('button.close') }}
            </v-btn>
            <v-btn v-if="editable" class="ml-2" color="primary" @click="edit">
              {{ $t('button.edit') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import Autolinker from 'autolinker'
export default {
  props: {
    teacher: { type: Object, required: true },
    editable: { type: Boolean, default: false },
  },
  data: () => ({
    dialog: false,
  }),
  computed: {
    linkedDesc() {
      return Autolinker.link(this.teacher.desc, {
        sanitizeHtml: true,
        stripPrefix: false,
      })
    },
  },
  methods: {
    edit() {
      this.dialog = false
      this.$emit('click:edit', this.teacher.id)
    },
  },
}
</script>
