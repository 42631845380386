<template>
  <v-text-field
    v-model="internalValue"
    v-bind="textFieldOptions"
    :append-icon="passwordMaskControl.icon"
    :type="passwordMaskControl.type"
    autocomplete="on"
    @click:append="passwordShow = !passwordShow"
    @change="$emit('change')"
    @blur="$emit('blur')"
  />
  <!-- change and blue is for vee-validate -->
</template>
<script>
export default {
  props: {
    value: { type: String, required: true },
    textFieldOptions: { type: Object, default: () => ({}) },
  },
  data: () => ({
    passwordShow: false,
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    passwordMaskControl() {
      return {
        icon: this.passwordShow ? 'mdi-eye' : 'mdi-eye-off',
        type: this.passwordShow ? 'text' : 'password',
      }
    },
  },
}
</script>
