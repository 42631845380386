<template>
  <v-card :max-width="500">
    <v-simple-table class="mouse-over-highlight-disabled">
      <tbody>
        <tr>
          <td>{{ $$columnName('user/profile.name') }}</td>
          <td>{{ user.profile.name }}</td>
        </tr>
        <tr>
          <td>{{ $$columnName('user/profile.name_hira') }}</td>
          <td>{{ user.profile.name_hira }}</td>
        </tr>
        <tr>
          <td>{{ $$columnName('user/profile.organization') }}</td>
          <td>{{ user.profile.organization }}</td>
        </tr>
        <tr>
          <td>{{ $$columnName('user/profile.organization_hira') }}</td>
          <td>{{ user.profile.organization_hira }}</td>
        </tr>
        <tr>
          <td>{{ $$columnName('user/profile.phone') }}</td>
          <td>{{ user.profile.phone }}</td>
        </tr>
        <template v-if="user.provider === 'jci_member_app'">
          <tr>
            <td>{{ $$columnName('user/profile.birthday') }}</td>
            <td>{{ user.profile.birthday | momentFormat('ll') }}</td>
          </tr>
        </template>
        <tr>
          <td>{{ $$columnName('user/profile.user_type') }}</td>
          <td>{{ $$getEnumText('user_type', user.profile.user_type) }}</td>
        </tr>
        <template v-if="isManager">
          <tr>
            <td>{{ $$columnName('users_conference.attended_user_type') }}</td>
            <td>
              {{ $$getEnumText('user_type', user.attended_user_type) }}
            </td>
          </tr>
        </template>
        <template v-if="isUserType.REGULAR">
          <tr>
            <td>{{ $$columnName('user/profile.lom_id') }}</td>
            <td>{{ user.profile.lom.name }}</td>
          </tr>
        </template>
        <template v-if="!isUserType.STUDENT">
          <tr>
            <td>{{ $$columnName('user/profile.join_type') }}</td>
            <td>{{ $$getEnumText('join_type', user.profile.join_type) }}</td>
          </tr>
        </template>
        <tr>
          <td>{{ $$columnName('user.email') }}</td>
          <td>{{ user.email }}</td>
        </tr>
        <tr v-if="!hideAccountType">
          <td>{{ $$columnName('user/profile.account_type') }}</td>
          <td>
            {{ $$getEnumText('account_type', user.profile.account_type) }}
          </td>
        </tr>
        <tr v-if="!hideConferenceAccountStatus">
          <td>
            {{ $$columnName('users_conference.conference_account_status') }}
          </td>
          <td>
            {{
              $$getEnumText(
                'conference_account_status',
                user.conference_account_status
              )
            }}
          </td>
        </tr>
        <template v-if="!isUser">
          <tr>
            <td>{{ $$columnName('user.provider') }}</td>
            <td>
              {{ $$getEnumText('provider', user.provider) }}
            </td>
          </tr>
        </template>
        <template v-if="isAdmin && user.provider === 'jci_member_app'">
          <tr>
            <td>{{ $$columnName('user/profile.jci_member_availability') }}</td>
            <td>
              {{
                $$getEnumText(
                  'jci_member_availability',
                  user.profile.jci_member_availability
                )
              }}
            </td>
          </tr>
        </template>
        <tr>
          <td>{{ $$columnName('user.created_at') }}</td>
          <td>{{ user.created_at | momentFormat('lll') }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <template v-if="actionable">
      <v-divider class="mb-4" />
      <v-card-actions>
        <v-row>
          <v-col cols="12" md="12" align="right">
            <btn-dialog-destroy
              v-if="destroyable"
              :btn-props="{ class: 'mr-2', color: 'error' }"
              @destroy="$emit('click:destroy')"
            />
            <v-btn v-if="editable" color="primary" @click="$emit('click:edit')">
              {{ $t('button.edit') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </template>
  </v-card>
</template>
<script>
import BtnDialogDestroy from '@/components/parts/Btn/Dialog/Destroy'
export default {
  components: {
    BtnDialogDestroy,
  },
  props: {
    user: { type: Object, required: true },
    loading: { type: Boolean, required: true },
    editable: { type: Boolean, default: false },
    destroyable: { type: Boolean, default: false },
    hideAccountType: { type: Boolean, default: false },
    hideConferenceAccountStatus: { type: Boolean, default: false },
    isAdmin: { type: Boolean, default: false },
    isManager: { type: Boolean, default: false },
    isUser: { type: Boolean, default: false },
  },
  computed: {
    isUserType() {
      return {
        REGULAR:
          this.user.profile.user_type === 'regular' ||
          this.user.profile.user_type === 'graduate',
        STUDENT: this.user.profile.user_type === 'student',
      }
    },
    actionable() {
      return this.editable || this.destroyable
    },
  },
}
</script>
