<template>
  <v-row>
    <v-col
      v-for="conference in conferences"
      :key="conference.id"
      cols="12"
      md="4"
      sm="6"
    >
      <conference-card
        :conference="conference"
        @click:select="$emit('click:select', conference)"
      />
    </v-col>
  </v-row>
</template>
<script>
import ConferenceCard from './Card'
export default {
  components: {
    ConferenceCard,
  },
  props: {
    conferences: { type: Array, required: true },
  },
}
</script>
