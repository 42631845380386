<template>
  <v-row>
    <template v-if="isAdmin">
      <v-col cols="12">
        <validation-provider
          v-slot="{ errors }"
          :name="$$columnName('user/profile.user_type')"
          rules="required"
        >
          <v-select
            v-model="internalValue.user_type"
            :label="$$columnName('user/profile.user_type')"
            :error-messages="errors"
            :items="select.userType"
            outlined
          />
        </validation-provider>
      </v-col>
    </template>
    <template v-if="isUserType.REGULAR && !isJcProvider">
      <v-col cols="12">
        <v-alert outlined type="warning" prominent>
          <div class="word-breaker">
            {{ $t('description.signup.invoice') }}
          </div>
        </v-alert>
      </v-col>
      <v-col cols="12">
        <validation-provider
          v-slot="{ errors }"
          key="user/profile.lom_id"
          :name="$$columnName('user/profile.lom_id')"
          rules="required"
        >
          <select-dialog-search-lom
            v-model="internalValue.lom_id"
            :loms="loms"
            :select-options="{
              label: $$columnName('user/profile.lom_id'),
              loading: loadingLoms,
              errorMessages: errors,
              outlined: true,
            }"
          />
        </validation-provider>
      </v-col>
    </template>
    <template v-if="!isJcProvider">
      <v-col cols="12">
        <validation-provider
          v-slot="{ errors }"
          key="user/profile.name"
          :name="$$columnName('user/profile.name')"
          rules="required|max:30"
        >
          <v-text-field
            v-model="internalValue.name"
            :label="$$columnName('user/profile.name')"
            :error-messages="errors"
            outlined
          />
        </validation-provider>
      </v-col>
      <v-col cols="12">
        <validation-provider
          v-slot="{ errors }"
          key="user/profile.name_hira"
          :name="$$columnName('user/profile.name_hira')"
          rules="required|max:30|zenHira"
        >
          <v-text-field
            v-model="internalValue.name_hira"
            :label="$$columnName('user/profile.name_hira')"
            :error-messages="errors"
            outlined
          />
        </validation-provider>
      </v-col>
    </template>
    <v-col cols="12">
      <validation-provider
        v-slot="{ errors }"
        key="user/profile.organization"
        :name="$$columnName('user/profile.organization')"
        :rules="validationOrganization"
      >
        <v-text-field
          v-model="internalValue.organization"
          :label="$$columnName('user/profile.organization')"
          :error-messages="errors"
          outlined
          :hint="$t('placeholder.organization')"
          persistent-hint
        />
      </validation-provider>
    </v-col>
    <v-col cols="12">
      <validation-provider
        v-slot="{ errors }"
        key="user/profile.organization_hira"
        :name="$$columnName('user/profile.organization_hira')"
        :rules="validationOrganizationHira"
      >
        <v-text-field
          v-model="internalValue.organization_hira"
          :label="$$columnName('user/profile.organization_hira')"
          :error-messages="errors"
          outlined
          :hint="$t('placeholder.organization')"
          persistent-hint
        />
      </validation-provider>
    </v-col>
    <v-col cols="12">
      <validation-provider
        v-slot="{ errors }"
        key="user/profile.phone"
        :name="$$columnName('user/profile.phone')"
        :rules="validationPhone"
      >
        <v-text-field
          v-model="internalValue.phone"
          :label="$$columnName('user/profile.phone')"
          :error-messages="errors"
          outlined
          :hint="$t('placeholder.phone')"
          persistent-hint
        />
      </validation-provider>
    </v-col>
    <!-- 2023サマコンでは非表示 -->
    <template v-if="!isUserType.STUDENT && false">
      <v-col cols="12">
        <validation-provider
          v-slot="{ errors }"
          key="user/profile.join_type"
          :name="$$columnName('user/profile.join_type')"
          rules="required"
        >
          <v-select
            v-model="internalValue.join_type"
            :label="$$columnName('user/profile.join_type')"
            :error-messages="errors"
            :items="select.joinType"
            outlined
          />
        </validation-provider>
      </v-col>
    </template>
  </v-row>
</template>
<script>
import SelectDialogSearchLom from '@/components/parts/Select/Dialog/SearchLom'
export default {
  components: {
    SelectDialogSearchLom,
  },
  props: {
    value: { type: Object, required: true },
    loadingLoms: { type: Boolean, default: false },
    loms: { type: Array, default: () => [] },
    isAdmin: { type: Boolean, default: false },
    isJcProvider: { type: Boolean, default: false },
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    select() {
      return {
        userType: this.$$selectEnums('user_type'),
        joinType: this.$$selectEnums('join_type'),
      }
    },
    isUserType() {
      return {
        REGULAR:
          this.internalValue.user_type === 'regular' ||
          this.internalValue.user_type === 'graduate',
        STUDENT: this.internalValue.user_type === 'student',
      }
    },
    validationOrganization() {
      let rules = 'required|max:100'
      return this.isJcProvider ? rules.replace('required|', '') : rules
    },
    validationOrganizationHira() {
      let rules = 'required|max:100|zenHira'
      return this.isJcProvider ? rules.replace('required|', '') : rules
    },
    validationPhone() {
      let rules = 'required|min:10|max:15'
      return this.isJcProvider ? rules.replace('required|', '') : rules
    },
  },
}
</script>
